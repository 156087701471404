<template>
    <div>
        <input type="file" name="file" accept=".csv" id="file" style="display: none" @change="importCsv">
        <label for="file" class="import-button"><i class="icon upload"></i>
            Import
        </label>

        <div id="importResultsModal" class="ui modal">
            <i class="close icon"></i>
            <div class="header">
                Imports results
            </div>
            <div class="content">
                <div v-if="!error">
                    <div class="ui small header">Total Unique: {{ totalUnique }}</div>
                    <div class="ui small header">Total Imported: {{ totalImported }}</div>
                    <div class="ui small header">Total Doubles: {{ totalDoubles }}</div>
                </div>
                <div v-else>{{ error }}</div>
            </div>
        </div>
        <loading :active.sync="isLoading"></loading>
    </div>
</template>

<script>
    export default {
        components: {

        },

        data() {
            return {
                error: null,
                isLoading: false,
                totalDoubles: null,
                totalUnique: null,
                totalImported: null,
            };
        },

        methods: {
            importCsv(e) {
                this.isLoading = true

                let file = e.target.files[0];

                const config = {
                    headers: {'content-type': 'multipart/form-data'}
                }

                let formData = new FormData();
                formData.append('file', file)

                this.$http.post('upload/', formData, config).then(response => {
                    this.totalUnique = response.data.total_unique
                    this.totalImported = response.data.total_imported
                    this.totalDoubles = response.data.total_doubles

                    document.getElementById('file').value = ''
                    this.error = null
                }).catch((error) => {
                    this.error = error
                    console.log(error)
                }).then(response => {
                    $('#importResultsModal').modal('show')

                    this.isLoading = false
                })
            }
        }
    }
</script>
