<template>
    <sui-modal v-model="open" :closeIcon="true">

        <sui-modal-header>Add to archive</sui-modal-header>

        <sui-modal-content scrolling>
            <div class="ui form">
                <div :class="(error && description === '') ? 'field error' : 'field'">
                    <label>Resolution on results & testing</label>
                    <textarea v-model="description"></textarea>
                </div>
            </div>
        </sui-modal-content>

        <sui-modal-actions>
            <div class="ui black deny button" @click="open=false">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click="addToArchive">
                Save<i class="checkmark icon"></i>
            </div>
        </sui-modal-actions>
    </sui-modal>
</template>

<script>

    import Datepicker from 'vuejs-datepicker';

    export default {


        data() {
            return {
                open: false,

                mailListId: null,

                description: '',

                loader: null,
                error: false,
            };
        },


        methods: {
            show()
            {
                this.error = false
                this.open = true
                this.description = ''
            },

            addToArchive()
            {
                this.error = false
                if (this.description === '')
                {
                    this.error = true
                    return
                }

                let data = {
                    'is_archive': true,
                    'description': this.description
                }

                this.loader = this.$loading.show()

                this.$http.patch('mail-lists/' + this.mailListId + '/', data).then(response => {
                    this.loader.hide()
                    this.$emit('archived', this.mailListId)
                    this.open = false
                });
            },
        }
    }
</script>

<style>
    .datepicker .vdp-datepicker__calendar {
        position: absolute;
        top: -282px;
    }
</style>
