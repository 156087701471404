<template>
    <div class="ui button">
        <a class="import-button" href="/api/dictionaries/export_empty_emails"><i class="icon upload"></i>
            Export dictionary data
        </a>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loader: false,
            };
        },

        methods: {
        }
    }
</script>
<style>
  a.import-button {
    color: rgba(0,0,0,.6);
    }
</style>
