<template>
    <sui-modal v-model="open" :closeIcon="true" v-if="opportunity">

        <sui-modal-header>Call later</sui-modal-header>

        <sui-modal-content>
            <div class="ui form">
                <div :class="(opportunity.call_later_date === '' && error) ? 'field error' : 'field'">
                    <label>Call date</label>
                    <datepicker v-model="opportunity.call_later_date"
                                :disabledDates="state.disabledDates"
                                format="dd.MM.yyyy"></datepicker>
                </div>
                <div class="field">
                    <label>Comment</label>
                    <input type="text" v-model="opportunity.call_later_comment">
                </div>
            </div>
        </sui-modal-content>

        <sui-modal-actions>
            <div class="ui black deny button" @click="open=false">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click="saveData">
                Save<i class="checkmark icon"></i>
            </div>
        </sui-modal-actions>
    </sui-modal>
</template>

<script>

    //let tomorrow = new Date()
    //tomorrow.setDate(tomorrow.getDate() + 1);

    import Datepicker from 'vuejs-datepicker';

    export default {

        components: {
            Datepicker
        },

        data() {
            return {
                open: false,

                opportunity: null,

                loader: null,
                error: false,

                state: {
                    disabledDates: {
                        to: new Date(),
                    }
                }
            };
        },

        methods: {
            show() {
                this.error = false
                this.open = true
            },

            saveData() {
                if (this.opportunity.call_later_date === '') {
                    this.error = true
                    return
                }

                this.error = false

                this.loader = this.$loading.show()

                let data = {
                    'call_later': true,
                    'is_positive': false,
                    'is_dismissed': false,
                    'call_later_comment': this.opportunity.call_later_comment,
                    'call_later_date': this.opportunity.call_later_date,
                }

                this.$http.patch('opportunities/' + this.opportunity.recipient_id + '/', data).then(response => {
                    this.$emit('saved')
                    this.open = false

                }).catch((response) => {
                    console.log(response)

                }).then(response => {
                    this.loader.hide()
                })
            },
        }
    }
</script>