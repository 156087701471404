<template>
    <div id="AddArticleModal" class="ui modal">
        <i class="close icon"></i>
        <div class="header">
            Add article
        </div>
        <div class="content">
            <form class="ui form">
                <div class="field" :class="errors.link ? 'error' : ''">
                    <label>Link</label>
                    <input type="text" v-model="articleMeta.link">
                </div>
                <div class="field" :class="errors.price ? 'error' : ''">
                    <label>Price</label>
                    <input type="text" v-model="articleMeta.price">
                </div>
                <div class="field" :class="errors.campaign ? 'error' : ''">
                  <label>Campaign</label>
                  <select class="ui fluid dropdown" v-model="articleMeta.campaign_id">
                    <option 
                      v-for="campaign in campaigns"
                      :value="campaign.id"
                      :key="campaign.id"
                      selected="selected"
                      >
                      {{ campaign.name }}
                    </option>
                  </select>
                </div>
                <div class="field" :class="errors.article_type ? 'error' : ''">
                  <label>Type</label>
                  <select class="ui fluid dropdown" v-model="articleMeta.article_type">
                    <option value="article" selected="selected">Article</option>
                    <option value="link">Link placement</option>
                  </select>
                </div>
                <div class="field">
                    <label>Published date</label>
                    <div class="datepicker">
                        <datepicker
                          v-model="articleMeta.created_at"
                          :format="customFormatter"
                          :placeholder="dateNow"
                        />
                    </div>
                </div>
            </form> 
        </div>
        <div class="actions">
            <div class="ui black deny button">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click.stop="saveData()">
                Save
                <i class="checkmark icon"></i>
            </div>
        </div>
    </div>
</template>

<script>

    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';

    export default {

        components: {
            Datepicker,
        },

        data() {
            return {
               dateNow: null,
               campaigns: [],
               articleMeta: {
                 link: '',
                 price: '',
                 campaign_id: null,
                 article_type: null,
                 createdAt: '',
               },
               errors: {
                 link: false,
                 price: false,
                 article_type: false,
                 campaign_id: false,
                 created_at: false,
               }
            };
        },
        
        mounted() {
           this.getCampaigns()
        },

        updated() {
        },

        methods: {
            customFormatter(date) {
              return moment(date).format('DD.MM.YYYY H:mm:ss');
            },
            validateFields() {
                this.articleMeta.link == '' ? this.errors.link = true : this.errors.link = false
                this.articleMeta.price == '' ? this.errors.price = true : this.errors.price = false
                this.articleMeta.article_type == null ? this.errors.article_type = true : this.errors.article_type = false
                this.articleMeta.campaign_id == null ? this.errors.campaign_id = true : this.errors.campaign_id = false
                this.articleMeta.created_at == false ? this.articleMeta.created_at = this.dateNow: this.errors.created_at = false
                for (var i in this.errors) {
                  if(this.errors[i] == true){
                    return false
                  }
                }
                return true
            },
            show() {
                this.dateNow = moment().format('DD.MM.YYYY H:mm:ss');
                $('#AddArticleModal').modal('show');
            },
            saveData() {
                if(this.validateFields()){
                  this.$http.post('v2-articles-meta/', this.articleMeta).then(response => {
                      this.articleMeta.link = ''
                      this.articleMeta.price = ''
                      this.articleMeta.article_type = null
                      this.articleMeta.campaign_id = null
                      this.articleMeta.created_at = false
                      $('#AddArticleModal').modal('hide')
                  });
                }
            },
            getCampaigns() {
              var loader = this.$loading.show()
              this.$http.get('campaigns/').then(response => {
                  this.campaigns = response.data
                  loader.hide()
              });
            },
        }
    }
</script>
