<template>
    <div class="ui container">
        <h1 class="ui header">User statistic</h1>
        <div class="ui grid one column row">
            <div class="column left aligned">
                 <h3 class="ui header">Hours this week: {{ workedHoursInCurrentWeek }} &nbsp;&nbsp;&nbsp;&nbsp; Total unpaid hours: {{ totalUnpaidHours }}</h3>
            </div>
        </div>
        <div class="ui grid">
            <div class="sixteen wide column">
                <div class="ui secondary pointing menu">
                    <a :class="period === 30 ? 'item active' : 'item'" @click="period=30">
                        30 days
                    </a>
                    <a :class="period === 'all' ? 'item active' : 'item'" @click="period='all'">
                        All
                    </a>
                </div>
                <UserStatisticChart :chart-data="userStatisticData" class="graph"></UserStatisticChart>
            </div>
        </div>
    </div>
</template>

<script>
    import UserStatisticChart from '@/components/UserStatisticChart'

    export default {

        components: {
            UserStatisticChart
        },

        data() {
            return {
                loader: null,

                period: 30,

                userId: null,
                addedDomainsByPeriod: null,
                workingHoursByPeriod: null,
                workedHoursInCurrentWeek: 0,
                totalUnpaidHours: 0,
                userStatisticData: null
            };
        },

        mounted() {
            this.userId = this.$route.params.id
            this.loadStatistic()
        },

        watch: {
            addedDomainsByPeriod: function (val) {
                if (val)
                    this.fillAddedDomainsByPeriodData()

            },

            period: function (val) {
                this.loadStatistic()
            },
        },

        methods: {
            loadStatistic() {
                this.loader = this.$loading.show()

                let url = 'users/' + this.userId + '/statistic/?period=' + this.period

                this.$http.get(url).then(response => {
                    this.addedDomainsByPeriod = response.data.added_domains_calendar
                    this.workingHoursByPeriod = response.data.worked_hours_calendar
                    this.workedHoursInCurrentWeek = response.data.worked_hours_in_current_week
                    this.totalUnpaidHours = response.data.total_unpaid_hours
                    this.loader.hide()
                });
            },


            fillAddedDomainsByPeriodData() {

                this.userStatisticData = {
                    labels: Object.keys(this.addedDomainsByPeriod),

                    datasets: [

                        {
                            yAxisID: 'B',
                            type: 'line',
                            label: 'WORKED HOURS',
                            backgroundColor: 'rgba(255,255,255,0.0)',
                            borderColor: '#54c8ff',
                            data: []
                        },

                        {
                            yAxisID: 'A',
                            label: 'ADDED DOMAINS',
                            backgroundColor: '#b5cc18',
                            data: []
                        },


                    ]
                }

                for (let prop in this.addedDomainsByPeriod)
                {
                    let domainsCount = this.addedDomainsByPeriod[prop]
                    let workingHoursCount = this.workingHoursByPeriod[prop]

                    this.userStatisticData.datasets[1].data.push(domainsCount)
                    this.userStatisticData.datasets[0].data.push(workingHoursCount)
                }
            },
        }
    }
</script>

<style scoped>
    .graph
    {
        height: 600px;
    }
</style>
