<template>
    <div class="ui container">
        <div class="buttons">
            <ImportCsv class="ui button"></ImportCsv>
            <CopyToClipboard class="ui button"></CopyToClipboard>
            <ImportCsvWithMailList class="ui button"></ImportCsvWithMailList>
        </div>
    </div>
</template>

<script>
    import ImportCsv from '@/components/ImportCsv'
    import ImportCsvWithMailList from '@/components/ImportCsvWithMailList'
    import CopyToClipboard from '@/components/CopyToClipboard'

    export default {

        components: {
            ImportCsv,
            ImportCsvWithMailList,
            CopyToClipboard
        },

    }
</script>
