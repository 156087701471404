<template>
    <div class="ui form">
        <div class="inline field">
            <label>Select category</label>
            <select class="ui fluid dropdown" @change="changed" v-model="selected">
                <option value="all" selected>All categories</option>
                <option value="">Without category</option>
                <option v-for="category in categories" v-bind:value="category.id" v-bind:key="category.id">{{ category.name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                categories: [],
                selected: null
            };
        },

        mounted() {
            this.$http.get('categories/').then(response => {
                this.categories = response.data
            });

            //$('.dropdown').dropdown()
        },

        methods: {
            changed() {
                this.$emit('changed', this.selected)
            },

        }
    }
</script>
