<template>
    <sui-modal v-model="open" size="large" :closeIcon="true" v-if="campaign">

        <sui-modal-header v-if="!campaign.id">Add campaign</sui-modal-header>
        <sui-modal-header v-else>Edit campaign {{ campaign.name }}</sui-modal-header>

        <sui-modal-content scrolling>
            <div class="ui grid form">

                <div class="eight wide column">
                    <div :class="!campaign.name && error ? 'field error' : 'field'">
                        <label>Name</label>
                        <input type="text" v-model="campaign.name">
                    </div>
                </div>

                <div class="eight wide column">
                    <div :class="(campaign.target_goal === '' && error) ? 'field error' : 'field'">
                        <label>Target goal</label>
                        <input type="number" v-model="campaign.target_goal">
                    </div>
                </div>

                <div class="eight wide column">
                    <div class="field">
                        <label>Trello link</label>
                        <input type="text" v-model="campaign.trello_link">
                    </div>
                </div>

                <div class="eight wide column">
                    <div class="field">
                        <label>Brief link</label>
                        <input type="text" v-model="campaign.brief_link">
                    </div>
                </div>

                <div class="eight wide column">
                    <div class="field">
                        <label>Email</label>
                        <select class="ui fluid dropdown" v-model="campaign.email_from">
                            <option v-for="email in emails" v-bind:value="email" v-bind:key="email">
                                {{ email }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="eight wide column">
                    <div class="field">
                        <label>End at</label>
                        <div class="datepicker">
                            <datepicker v-model="campaign.end_at" format="dd.MM.yyyy"></datepicker>
                        </div>
                    </div>
                </div>

                <div class="eight wide column">
                    <div :class="(campaign.outreach_templates.length === 0 && error) ? 'field error' : 'field'">
                        <label>Select outreach templates</label>
                        <select v-if="templates" class="ui fluid dropdown multi_select" multiple v-model="campaign.outreach_templates">
                            <option v-for="template in templates.outreach"
                                    v-bind:value="template.id"
                                    v-bind:key="template.id">{{ template.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="eight wide column">
                    <div  :class="(campaign.follow_up_templates.length === 0 && error) ? 'field error' : 'field'">
                        <label>Select follow up templates</label>
                        <select v-if="templates" class="ui fluid dropdown multi_select" multiple v-model="campaign.follow_up_templates">
                            <option v-for="template in templates.follow_up"
                                    v-bind:value="template.id"
                                    v-bind:key="template.id">{{ template.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="sixteen wide column">
                    <div class="field">
                        <div class="ui toggle checkbox" @click="campaign.is_active = !campaign.is_active">
                            <input type="checkbox" tabindex="0" :checked="campaign.is_active" class="hidden">
                            <label>is Active</label>
                        </div>
                    </div>
                </div>
            </div>
        </sui-modal-content>

        <sui-modal-actions>
            <div class="ui black deny button" @click="open=false">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click="saveCampaign">
                Save<i class="checkmark icon"></i>
            </div>
        </sui-modal-actions>
    </sui-modal>
</template>

<script>

    import Datepicker from 'vuejs-datepicker';

    export default {

        components: {
            Datepicker
        },

        data() {
            return {
                open: false,

                campaign: null,

                defaultParams: {
                    name: '',
                    trello_link: '',
                    brief_link: '',
                    target_goal: 0,
                    end_at: null,
                    is_active: true,
                    email_from: 'dmitrii@the.gt',
                    outreach_templates: [],
                    follow_up_templates: []
                },

                emails: [],

                templates: null,

                loader: null,
                error: false,
            };
        },

        mounted() {
            this.campaign = this.defaultParams
            this.loadTemplates()
            this.loadEmails()
        },

        methods: {
            show()
            {
                this.campaign = Object.assign({}, this.defaultParams)
                this.error = false

                this.open = true
            },

            loadTemplates()
            {
                this.$http.get('templates/group_by_type/').then(response => {
                    this.templates = response.data
                })
            },

            loadEmails()
            {
                this.$http.get('mailboxes/emails/').then(response => {
                    this.emails = response.data
                })
            },

            saveCampaign()
            {
                if (!this.campaign.name || this.campaign.target_goal === ''
                    || this.campaign.outreach_templates.length === 0
                    || this.campaign.follow_up_templates.length === 0)
                {
                    this.error = true
                    return
                }

                this.error = false

                if (this.campaign.id)
                    this.updateCampaign()
                else
                    this.createCampaign()
            },

            createCampaign()
            {
                this.loader = this.$loading.show()

                this.$http.post('campaigns/', this.campaign).then(response => {
                    this.$emit('created', response.data)
                    this.open = false

                }).catch(error => {
                    console.log(this.errors)

                }).then(response => {
                    this.loader.hide()
                })
            },

            updateCampaign()
            {
                this.loader = this.$loading.show()

                this.$http.patch('campaigns/' + this.campaign.id + '/', this.campaign).then(response => {
                    this.$emit('updated', response.data)
                    this.open = false

                }).catch((response) => {
                    console.log(response)

                }).then(response => {
                    this.loader.hide()
                })
            },
        }
    }
</script>

<style scoped>
    .datepicker .vdp-datepicker__calendar {
        position: absolute;
        top: -282px;
    }

    .multi_select
    {
        height: 100px !important;
    }
</style>
