<template>
    <div class="ui container">

        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">Templates</h1>
            </div>
            <div class="column right aligned">
                <button class="ui button" @click="showAddModal"><i class="plus icon"></i>Add template</button>
            </div>
        </div>

        <table class="ui celled table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
                <th>Recipients</th>
                <th>Opened</th>
                <th>Replied</th>
                <th>Conversions</th>
                <th>Opp created/send/dismissed</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(template, index) in templates" :key="template.id">
                <td>{{ template.name }}</td>
                <td>{{ templateTypes[template.type] }}</td>
                <td>{{ template.description }}</td>

                <td>{{ template.recipients_count }}</td>

                <td>{{ template.opened_count }} {{ calculatePercent(template.opened_count, template.recipients_count) }}%</td>
                <td>{{ template.replied_count }} \ {{ template.recipients_count }} ({{ calculatePercent(template.replied_count, template.recipients_count) }}%)</td>

                <td>{{ template.conversions_count }} ({{ calculatePercent(template.conversions_count, template.recipients_count) }}%)</td>

                <td>{{ template.opportunities_count }}/{{ template.opportunities_send_count }}/{{ template.opportunities_dismissed }}</td>
                <td>
                    <div class="ui small basic icon buttons">
                        <button class="ui button" @click="showEditModal(index)" data-content="Edit"><i class="edit icon"></i></button>
                        <button class="ui button" @click="deleteTemplate(index)" data-content="Delete"><i class="trash icon"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>


        <TemplateModal ref="templateModal" @created="createdTemplate" @updated="updatedTemplate"></TemplateModal>
    </div>
</template>

<script>

    import TemplateModal from '@/components/TemplateModal'

    export default {

        components: {
            TemplateModal,
        },

        data() {
            return {
                loader: null,
                templates: [],
                selectedIndex: null,
                templateTypes: {
                    1: 'Outreach',
                    2: 'Follow up',
                }
            };
        },

        mounted() {

            this.loader = this.$loading.show()

            this.$http.get('templates/').then(response => {
                this.templates = response.data
                this.loader.hide()

            });
        },

        updated() {
           $('.button').popup();
        },

        methods: {

            showAddModal(index)
            {
                console.log('add modal')
                this.$refs.templateModal.show();
            },

            showEditModal(index)
            {
                this.selectedIndex = index
                this.$refs.templateModal.show();
                this.$refs.templateModal.template = Object.assign({}, this.templates[index])
            },

            createdTemplate(template)
            {
                this.templates.unshift(template)
            },

            updatedTemplate(template_)
            {
                let template = this.templates[this.selectedIndex]

                template.name = template_.name
                template.body = template_.body
                template.type = template_.type
                template.description = template_.description

                //this.$set(this.templates, this.selectedIndex, template)
                this.selectedIndex = null
            },

            deleteTemplate(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                this.$http.delete('templates/' + this.templates[index].id + '/').then(response => {
                    this.templates.splice(index, 1)
                    this.loader.hide()
                });
            },

            calculatePercent(val, total)
            {
                if (total == 0)
                    return (0).toFixed(2)

                val = parseFloat(val)
                total = parseFloat(total)

                return (val * 100 / total).toFixed(2)
            }
        }
    }
</script>
