<template>
    <div>
        <table class="ui celled table">
            <thead>
            <tr>
                <th>Website</th>
                <th>Domain rating</th>
                <th>Total traffic</th>


                <th v-if="type != 'established'">Similarweb</th>
                <th v-else>Category</th>

                <th>Tags</th>
                <th v-if="type != 'established' && filter == 'all'">Fetched</th>

                <th v-if="type == 'established'">Email link</th>
                <th v-if="type == 'established'">Context</th>
                <th v-if="type == 'established'">Free</th>
                <th v-if="type == 'established'">Paid</th>

                <th v-if="type == 'list'">Contact</th>

                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(domain, index) in domains" v-bind:key="domain.id">
                <td><a :href="'http://' + domain.website" target="_blank">{{ domain.website }}</a></td>
                <td>
                    <div v-if="selectedDomainIndex == index && editMode == true" class="ui transparent input edit-field"><input v-model="domain.domain_rating" required type="number"></div>
                    <span v-else>{{ domain.domain_rating }}</span>
                </td>
                <td>
                    <div v-if="selectedDomainIndex == index && editMode == true" class="ui transparent input edit-field"><input v-model="domain.total_traffic" required type="number"></div>
                    <span v-else>{{ domain.total_traffic }}</span>
                </td>

                <td v-if="type != 'established'">
                    <div v-if="selectedDomainIndex == index && editMode == true" class="ui transparent input edit-field"><input v-model="domain.similarweb" required type="number"></div>
                    <span v-else>{{ domain.similarweb }}</span>
                </td>
                <td v-else>{{ domain.category_name }}</td>

                <td>
                    <div v-if="selectedDomainIndex == index && editMode == true" class="ui transparent fluid input"><input v-model="domain.tags"></div>
                    <span v-else>{{ domain.tags }}</span>
                </td>
                <td v-if="type != 'established' && filter == 'all'">{{ domain.checked_at }} {{ domain.checked_result }}</td>

                <td v-if="type == 'established'">{{ domain.email_link }}</td>
                <td v-if="type == 'established'">{{ domain.establish_context }}</td>
                <td v-if="type == 'established'">{{ domain.is_free }}</td>
                <td v-if="type == 'established'">{{ domain.is_paid }}</td>

                <td v-if="type == 'list'">{{ domain.main_contact }}</td>

                <td>
                    <div class="ui small basic icon buttons action-buttons" v-if="filter != 'trash'">
                        <!--<button v-if="type != 'list'" class="ui button" @click="addToList(index)" data-content="Add to domain list"><i class="list icon"></i></button>-->
                        <button v-if="type != 'established' && filter != 'queue'" class="ui button" @click="addToMailList(index)" data-content="Add to mail list"><i class="list icon"></i></button>

                        <router-link v-if="$user && ($user.isAdmin || $user.isEditor)" :to="'/domains/' + domain.id" class="ui button" data-content="Details"><i class="eye icon"></i></router-link>

                        <!--<button class="ui button" @click="showDetailsDomainModal(index)"><i class="eye icon"></i></button>-->

                        <button v-if="selectedDomainIndex == index && editMode == true" class="ui button" @click="saveData" data-content="Save"><i class="save icon"></i></button>

                        <button v-else class="ui button" @click="editRow(index)" data-content="Edit"><i class="edit icon"></i></button>

                        <button v-if="$user.isAdmin && type == 'search'" class="ui button" @click="showEstablishedModal(index)" data-content="Add to established"><i class="thumbs up icon"></i></button>

                        <button v-if="type == 'established'" class="ui button" @click="setEstablished(index)" data-content="Remove from established"><i class="thumbs down icon"></i></button>

                        <button v-if="type == 'search' && filter != 'queue'" class="ui button" @click="showChangeCategoryModal(index)" data-content="Change category and add to mail list"><i class="exchange icon"></i></button>

                        <button v-if="type == 'list'" class="ui button" @click="removeDomainFromList(index)" data-content="Remove from list"><i class="delete icon"></i></button>

                        <button v-if="type == 'search'" class="ui button" @click="sendToDead(index)" data-content="Mark is dead"><i class="trash icon"></i></button>
                    </div>
                    <div class="ui small basic icon buttons action-buttons" v-else>
                        <router-link :to="'/domains/' + domain.id" class="ui button" data-content="Details"><i class="eye icon"></i></router-link>
                        <button class="ui button" @click="removeDomainFromTrash(index)" data-content="Remove from trash"><i class="delete icon"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <DomainEditModal ref="domainEditModal" :domainId="domainObj.id" @saved="savedDomain"/>
        <DomainEstablishedModal ref="domainEstablishedModal" :domainId="domainId" @saved="saveEstablished"></DomainEstablishedModal>
        <ChangeCategoryModal
                ref="changeCategoryModal"
                @saved="changedCategory"
                :mailListId="selectedMailListId"
                @fetched="fetchedContactsForDomain">
        </ChangeCategoryModal>

        <AddToMailListModal
                ref="addToMailListModal"
                :domain.sync="domainObj"
                :mailListId="selectedMailListId"
                :filter="filter"
                @added="addedToMailList"
                @fetched="fetchedContactsForDomain"
                @send_to_trash="sendToTrash">
        </AddToMailListModal>
        <!--<DomainAddToListModal ref="domainAddToListModal" :domain="domainObj"/>-->
    </div>
</template>

<script>
    //import DomainAddToListModal from './DomainAddToListModal'
    import DomainEditModal from './DomainEditModal'
    import Editable from './Editable'
    import AddToMailListModal from './AddToMailListModal'
    import DomainEstablishedModal from './DomainEstablishedModal'
    import ChangeCategoryModal from './ChangeCategoryModal'

    export default {

        components: {
            //DomainAddToListModal,
            DomainEditModal,
            Editable,
            AddToMailListModal,
            DomainEstablishedModal,
            ChangeCategoryModal
        },

        props: ['data', 'type', 'listId', 'selectedMailList', 'filter'],

        data() {
            return {
                domains: this.data,
                selectedDomainIndex: null,
                selectedMailListId: null,
                editMode: false,
                domainId: null,
                domainObj: {
                    id: '',
                    website: '',
                    domain_rating: '',
                    ahrefs_rank: '',
                    domains: '',
                    total_keywords: '',
                    total_traffic: '',
                    website_location: '',
                    company: '',
                    category: '',
                    similarweb: '',
                    cat: '',
                    cat2: '',
                    robs: '',
                    quantcast: '',
                    alexa: '',
                    twitter: '',
                    facebook: '',
                    linkedin: '',
                    google: '',
                    pinterest: '',
                    github: '',
                    instagram: '',
                    vk: '',
                    youtube: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    avg_app_spend: '',
                }
            };
        },

        watch: {
            data: function (val) {
                this.domains = val
            },

            selectedMailList: function (val) {
                this.selectedMailListId = val
            },
        },

        updated() {
            // Semantic popup
            $('.button').popup();
        },

        methods: {

            showEstablishedModal(index) {
                this.selectedDomainIndex = index
                this.domainId = this.domains[index].id
                this.$refs.domainEstablishedModal.show()
            },

            showChangeCategoryModal(index) {
                this.selectedDomainIndex = index
                this.$refs.changeCategoryModal.domain = this.domains[index]
                this.$refs.changeCategoryModal.show()
            },

            showDetailsDomainModal(index) {
                this.selectedDomainIndex = index
                this.domainObj = this.domains[index]
                this.$refs.domainDetailsModal.show();
            },

            showEditDomainModal(index) {
                this.selectedDomainIndex = index
                this.domainObj = this.domains[index]
                this.$refs.domainEditModal.show();
            },

            addToList(index) {
                this.selectedDomainIndex = index
                this.domainObj = this.domains[index]

                if (this.selectedListId) {
                    let data = {'domain_id': this.domainObj.id}
                    this.$http.post('lists/' + this.selectedListId + '/add_domain/', data).then(response => {

                    });
                }
                else
                    this.$refs.domainAddToListModal.show();
            },

            addToMailList(index) {
                this.selectedDomainIndex = index
                this.domainObj = this.domains[index]
                this.$refs.addToMailListModal.show();
            },

            editRow(index)
            {
                this.selectedDomainIndex = index
                this.editMode = true
            },

            setEstablished(index) {
                this.$http.post('domains/' + this.domains[index].id + '/set_established/').then(response => {
                    this.domains.splice(index, 1)
                });
            },

            saveEstablished(domain)
            {
                this.domains[this.selectedDomainIndex].is_established = true
                this.domains.splice(this.selectedDomainIndex, 1)
            },

            changedCategory(categoryId)
            {
                if (this.domains[this.selectedDomainIndex].category != categoryId)
                    this.domains.splice(this.selectedDomainIndex, 1)
            },

            saveData()
            {
                this.isLoading = true

                let data = {}

                let domain = this.domains[this.selectedDomainIndex]

                if (domain.domain_rating === '')
                    data['domain_rating'] = null
                else
                    data['domain_rating'] = domain.domain_rating

                if (domain.total_traffic === '')
                    data['total_traffic'] = null
                else
                    data['total_traffic'] = domain.total_traffic

                if (domain.tags !== '')
                    data['tags'] = domain.tags

                data['similarweb'] = domain.similarweb

                this.$http.patch('domains/' + domain.id + '/', data).then(response => {
                    this.editMode = false
                    this.isLoading = true
                });
            },

            sendToDead(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.$http.post('domains/' + this.domains[index].id + '/set_dead/').then(response => {
                    this.domains.splice(index, 1)
                });
            },

            sendToRejected(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.$http.post('domains/' + this.domains[index].id + '/set_rejected/').then(response => {
                    this.domains.splice(index, 1)
                });
            },

            sendToTrash() {
                this.domains.splice(this.selectedDomainIndex, 1)
                this.selectedDomainIndex = null
            },

            savedDomain(domain) {
                this.$set(this.domains, this.selectedDomainIndex, domain)
            },

            removeDomainFromList(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                let data = {'domain_id': this.domains[index].id}

                this.$http.post('lists/' + this.listId + '/remove_domain/', data).then(response => {
                    this.domains.splice(index, 1)
                });
            },

            removeDomainFromTrash(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.$http.post('domains/' + this.domains[index].id + '/set_trash/').then(response => {
                    this.domains.splice(index, 1)
                });
            },

            fetchedContactsForDomain(domain)
            {
                let domainObj = this.domains[this.selectedDomainIndex]
                domainObj.checked_result = domain.checked_result
                domainObj.checked_at = domain.checked_at

                if (domain.checked_result === 'empty')
                    this.domains.splice(this.selectedDomainIndex, 1)
            },

            addedToMailList()
            {
                this.domains.splice(this.selectedDomainIndex, 1)
            }
        }
    }
</script>
