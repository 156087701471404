<template>
    <div>
        <table class="ui celled table">
            <thead>
            <tr>
                <th>Campaign</th>
                <th>Article</th>
                <th>Price</th>
                <th>Type</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="article in articles" v-bind:key="article.id">

                <td>{{ article.campaign_id  }}</td>
                <td>{{ article.link  }}</td>
                <td>{{ article.price  }}</td>
                <td>{{ article.article_type  }}</td>

                <td>
                    <div class="ui small basic icon buttons action-buttons">

                        <!--<button class="ui button" @click="showDetailsDomainModal(index)"><i class="eye icon"></i></button>-->
                    </div>
                    <div class="ui small basic icon buttons action-buttons">
                        <!-- <router-link :to="'/domains/' + domain.id" class="ui button" data-content="Details"><i class="eye icon"></i></router-link>
                        <button class="ui button" @click="removeDomainFromTrash(index)" data-content="Remove from trash"><i class="delete icon"></i></button> -->
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    export default {

        components: {
        },

        // props: ['articles'],

        data() {
            return {
                articles: [],
            };
        },

        watch: {
        },

        mounted() {
            this.loadArticles()
        },

        updated() {
            // Semantic popup
            // $('.button').popup();
        },

        methods: {

            loadArticles() {
                console.log(123)

                this.isLoading = true

                let url = 'v2-articles-meta/'

                this.$http.get(url).then(response => {
                    this.articles = response.data
                    for (article in this.articles) {
                    console.log(article)
                    }
                    this.isLoading = false
                })
            },
        }
    }
</script>
