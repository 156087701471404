<template>
    <div>
        <h1 class="ui header">AB statistic</h1>

        <table class="ui celled table" v-for="campaign in campaigns" :key="campaign.id">
            <thead>
            <tr>
                <th rowspan="2">Mail list name</th>
                <th></th>
                <th></th>
                <th>Recipients</th>
                <th>Opened</th>
                <th>Replied</th>
                <th>Conversions</th>
            </tr>
            <tr>
                <th>Template name</th>
                <th>Hypothesis</th>
                <th>{{ campaign.send_outreach_count }}</th>
                <th>{{ campaign.opened_count }} ({{ calculatePercent(campaign.opened_count, campaign.send_outreach_count) }}%)</th>
                <th>{{ campaign.replied_count }} ({{ calculatePercent(campaign.replied_count, campaign.send_outreach_count) }}%)</th>
                <th>{{ campaign.conversions_count }} ({{ calculatePercent(campaign.conversions_count, campaign.send_outreach_count) }}%)</th>
            </tr>
            </thead>
            <tbody v-for="(list, index) in campaign.mail_lists" :key="list.id">
                <tr>
                    <td>{{ list.name }}</td>
                    <td>{{ list.outreach_a_temp.name }}</td>
                    <td>{{ list.outreach_a_temp.description }}</td>
                    <td>{{ list.send_outreach_a_count }}</td>
                    <td>{{ list.opened_outreach_a_count }} ({{ calculatePercent(list.opened_outreach_a_count, list.send_outreach_a_count) }}%)</td>
                    <td>{{ list.replied_outreach_a_count }} ({{ calculatePercent(list.replied_outreach_a_count, list.send_outreach_a_count) }}%)</td>
                    <td>{{ list.conversions_outreach_a_count }} ({{ calculatePercent(list.conversions_outreach_a_count, list.send_outreach_a_count) }}%)</td>
                </tr>
                <tr>
                    <td>{{ list.name }}</td>
                    <td>{{ list.outreach_b_temp.name }}</td>
                    <td>{{ list.outreach_b_temp.description }}</td>
                    <td>{{ list.send_outreach_b_count }}</td>
                    <td>{{ list.opened_outreach_b_count }} {{ calculatePercent(list.opened_outreach_b_count, list.send_outreach_b_count) }}%</td>
                    <td>{{ list.replied_outreach_b_count }} ({{ calculatePercent(list.replied_outreach_b_count, list.send_outreach_b_count) }}%)</td>
                    <td>{{ list.conversions_outreach_b_count }} ({{ calculatePercent(list.conversions_outreach_b_count, list.send_outreach_b_count) }}%)</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    import MailListModal from '@/components/MailListModal'

    export default {

        components: {
            MailListModal
        },

        data() {
            return {
                campaigns: null,
                loader: false,
            };
        },

        mounted() {
            this.loader = this.$loading.show()


            this.$http.get('campaigns/ab_statistics/').then(response => {
                this.campaigns = response.data
                this.loader.hide()
            });

        },

        methods: {
            calculatePercent(val, total)
            {
                if (total == 0)
                    return (0).toFixed(2)

                val = parseFloat(val)
                total = parseFloat(total)

                return (val * 100 / total).toFixed(2)
            },
        }
    }
</script>
