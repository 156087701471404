<template>
    <div id="addToListModal" class="ui modal">
        <i class="close icon"></i>
        <div class="header">
            Add to list
        </div>
        <div class="content">
            <select class="ui fluid dropdown" v-model="selectedList">
                <option value="Select list" disabled selected>Select list</option>
                <option v-for="list in lists" v-bind:value="list.id">{{ list.name }}</option>
            </select>
        </div>

        <div class="actions">
            <div class="ui black deny button">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click.stop="addToList()">
                Save
                <i class="checkmark icon"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: ['domain'],

        data() {
            return {
                lists: [],
                selectedList: null,
            };
        },

        mounted() {
            this.$http.get('lists/').then(response => {
                this.lists = response.data.results
            });
        },

        methods: {
            show()
            {
                $('#addToListModal').modal('show');
            },

            addToList()
            {
                let data = {'domain_id': this.domain.id}

                this.$http.post('lists/' + this.selectedList + '/add_domain/', data).then(response => {
                    $('#addToListModal').modal('hide');
                });

            }
        }
    }
</script>
