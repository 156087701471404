<template>
    <div id="detailsModal" v-if="domain" class="ui modal">
        <i class="close icon"></i>
        <div class="header">
            {{ domain.website }} details
        </div>
        <div class="content">
            <table class="ui celled table">
                <tbody>
                    <tr>
                        <td>Website</td>
                        <td>{{ domain.website }}</td>
                    </tr>
                    <tr>
                        <td>Domain rating</td>
                        <td>{{ domain.domain_rating }}</td>
                    </tr>
                    <tr>
                        <td>Ahrefs Rank</td>
                        <td>{{ domain.ahrefs_rank }}</td>
                    </tr>
                    <tr>
                        <td>Domains</td>
                        <td>{{ domain.domains }}</td>
                    </tr>
                    <tr>
                        <td>Total Keywords</td>
                        <td>{{ domain.total_keywords }}</td>
                    </tr>
                    <tr>
                        <td>Total Traffic</td>
                        <td>{{ domain.total_traffic }}</td>
                    </tr>
                    <tr>
                        <td>Website Location</td>
                        <td>{{ domain.website_location }}</td>
                    </tr>
                    <tr>
                        <td>Company</td>
                        <td>{{ domain.company }}</td>
                    </tr>
                    <tr>
                        <td>Contacts</td>
                        <td>
                            <div class="ui two column grid">
                                 <div class="column">
                                    <button for="file" @click="fetchContactsData" class="ui basic button"><i class="icon download"></i>Fetch data</button>
                                 </div>
                                <div style="padding-top: 20px">Last time checked: {{ domain.checked_at}}</div>
                            </div>

                            <div v-for="person in domain.persons">
                                {{ person.first_name }} {{ person.last_name }} - {{ person.position }} - {{ person.email }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Phones</td>
                        <td>
                            <div v-for="phone in domain.phones">
                                {{ phone.number }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Emails</td>
                        <td>
                            <div v-for="email in domain.emails">
                                {{ email.email }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Category</td>
                        <td>{{ domain.category }}</td>
                    </tr>
                    <tr>
                        <td>similarweb</td>
                        <td>{{ domain.similarweb }}</td>
                    </tr>
                    <tr>
                        <td>cat</td>
                        <td>{{ domain.cat }}</td>
                    </tr>
                    <tr>
                        <td>cat2</td>
                        <td>{{ domain.cat2 }}</td>
                    </tr>
                    <tr>
                        <td>rob's</td>
                        <td>{{ domain.robs }}</td>
                    </tr>
                    <tr>
                        <td>Quantcast</td>
                        <td>{{ domain.quantcast }}</td>
                    </tr>
                    <tr>
                        <td>Alexa</td>
                        <td>{{ domain.alexa }}</td>
                    </tr>
                    <tr>
                        <td>Twitter</td>
                        <td>{{ domain.twitter }}</td>
                    </tr>
                    <tr>
                        <td>Facebook</td>
                        <td>{{ domain.facebook }}</td>
                    </tr>
                    <tr>
                        <td>LinkedIn</td>
                        <td>{{ domain.linkedin }}</td>
                    </tr>
                    <tr>
                        <td>Google</td>
                        <td>{{ domain.google }}</td>
                    </tr>
                    <tr>
                        <td>Pinterest</td>
                        <td>{{ domain.pinterest }}</td>
                    </tr>
                    <tr>
                        <td>GitHub</td>
                        <td>{{ domain.github }}</td>
                    </tr>
                    <tr>
                        <td>Instagram</td>
                        <td>{{ domain.instagram }}</td>
                    </tr>
                    <tr>
                        <td>VK</td>
                        <td>{{ domain.vk }}</td>
                    </tr>
                    <tr>
                        <td>Youtube</td>
                        <td>{{ domain.youtube }}</td>
                    </tr>
                    <tr>
                        <td>City</td>
                        <td>{{ domain.city }}</td>
                    </tr>
                    <tr>
                        <td>State</td>
                        <td>{{ domain.state }}</td>
                    </tr>
                    <tr>
                        <td>Zip</td>
                        <td>{{ domain.zip }}</td>
                    </tr>
                    <tr>
                        <td>Country</td>
                        <td>{{ domain.country }}</td>
                    </tr>
                    <tr>
                        <td>Avg. App Spend</td>
                        <td>{{ domain.avg_app_spend }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <loading :is-full-page="false" :active.sync="isLoading"></loading>
    </div>
</template>

<script>
    export default {

        props: ['domainId'],

        data() {
            return {
                isLoading: false,
                domain: null,
            };
        },

        watch: {
            domainId: function (val) {
                this.loadDomain()
            },
        },

        methods: {
            show()
            {
                $('#detailsModal').modal('show');
            },

            loadDomain() {
                this.$http.get('domains/' + this.domainId + '/').then(response => {
                    this.domain = response.data
                });
            },

            fetchContactsData()
            {
                this.isLoading = true
                this.$http.get('domains/' + this.domainId + '/fetch_contacts_data/').then(response => {
                    this.domain = response.data
                    this.isLoading = false
                });
            }
        }
    }
</script>
