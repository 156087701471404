<template>
            <div class="mailbox-meta">

              <div class="ui grid form">

                  <div class="sixteen wide column">
                    <h2>Mailbox #{{ selectedMailbox.id }} — {{ selectedMailbox.mailbox }}</h2>
                  </div>

                  <div class="eight wide column">
                      <div class="field">
                        <label>Outreach subjectline</label>
                        <select class="ui fluid dropdown" v-model="selectedMailbox.subjectline_out.id">
                            <option v-for="(subjectline, index) in outreachSubjectLines" :key="subjectline.id" :value="subjectline.id">
                              {{ subjectline.name }}
                            </option>
                        </select>
                      </div>
                  </div>

                  <div class="eight wide column">
                      <div class="field">
                        <label>Follow up subjectline</label>
                        <select class="ui fluid dropdown" v-model="selectedMailbox.subjectline_fu.id">
                            <option v-for="(subjectline, index) in followupSubjectLines" :key="subjectline.id" :value="subjectline.id">
                              {{ subjectline.name }}
                            </option>
                        </select>
                      </div>
                  </div>
                  <div class="eight wide column">
                      <div class="field">
                        <label>Outreach template</label>
                        <select class="ui fluid dropdown" v-model="selectedMailbox.out_template.id">
                            <option v-for="(template, index) in outreachTemplates" :key="template.id" :value="template.id">
                              {{ template.name }}
                            </option>
                        </select>
                      </div>
                  </div>

                  <div class="eight wide column">
                      <div class="field">
                        <label>Follow up template</label>
                        <select class="ui fluid dropdown" v-model="selectedMailbox.fu_template.id">
                            <option v-for="(template, index) in followupTemplates" :key="template.id" :value="template.id">
                              {{ template.name }}
                            </option>
                        </select>
                      </div>
                  </div>
                  <div class="eight wide column">
                      <div class="field">
                          <label>Mailbox</label>
                          <select class="ui fluid dropdown" v-model="selectedMailbox.mailbox">
                              <option v-for="mailbox in mailboxesList" v-bind:value="mailbox.id" v-bind:key="mailbox.id">
                                  {{ mailbox.name }} ({{ mailbox.email }})
                              </option>
                          </select>
                      </div>
                  </div>

                  <div class="sixteen wide column">
                      <div class="field">
                      </div>
                  </div>

                  <div class="eight wide column">
                      <div class="field">
                      <button class="ui button" @click="removeMailbox(selectedMailbox.id)">Remove mailbox</button>
                      </div>
                  </div>

              </div>
            </div>
</template>











<script>
    export default {
        name: 'add-mailbox-form',
        props: ['mailboxKey', 'subjectLines', 'selectedMailbox', 'mailboxes', 'templates'],
        data() {
            return {
                open: false,
                subject_fu: null,
                subject_out: null,
                campaign: null,
                defaultParams: {
                    name: '',
                    trello_link: '',
                    brief_link: '',
                    target_goal: 0,
                    end_at: null,
                    is_active: true,
                    email_from: 'dmitrii@the.gt',
                    out_template: null,
                    follow_up_templates: []
                },

                emails: [],
                loader: null,
                error: false,
            };
        },
        
        computed: {
          outreachTemplates: {
            get: function() {
              return JSON.parse(JSON.stringify(this.templates.outreach))
            }
          },
          followupTemplates: {
            get: function() {
              return JSON.parse(JSON.stringify(this.templates.follow_up))
            }
          },
          outreachSubjectLines: {
            get: function() {
              return JSON.parse(JSON.stringify(this.subjectLines))
            },
            set: function(newVal) {
              return
            }
          },
          followupSubjectLines: {
            get: function() {
              return JSON.parse(JSON.stringify(this.subjectLines))
            },
            set: function(newVal) {
              return
            }
          },
          mailboxesList: {
            get: function() {
              return JSON.parse(JSON.stringify(this.mailboxes))
            },
            set: function(newVal) {
              return
            }
          }
        },

        mounted() {
        },
        methods: {
            removeMailbox(mailboxId) {
              this.$delete(this.$parent.$parent.$parent.campaign.mailboxes, mailboxId)
            },
        }
    }
</script>
<style scoped>
  .mailbox-meta {
    margin-top: 2em;
  }
</style>
