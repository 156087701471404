<template>
    <div class="ui container">

        <h1 class="ui header">Statistic</h1>

        <CategoriesForStatistic @changed="selectedCategory"/>

        <br/>

        <table class="ui celled table">
            <thead>
            <tr>
                <th>Total domains</th>
                <th>Live</th>
                <th>20+</th>
                <th>Total domains established</th>
                <th>Average domain rating</th>
                <th>Has category</th>
                <th>Has phone</th>
                <th>Has address</th>
                <th>Has domains</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{{ totalDomains }}</td>
                <td>{{ liveDomains }}</td>
                <td>{{ domainsGte20 }}</td>
                <td>{{ totalDomainsEstablished }}</td>
                <td>{{ averageDomainRating }}</td>
                <td>{{ hasCategory }} ({{ hasCategoryPercent }}%)</td>
                <td>{{ hasPhone }} ({{ hasPhonePercent }}%)</td>
                <td>{{ hasAddress }} ({{ hasAddressPercent }}%)</td>
                <td>{{ hasDomains }} ({{ hasDomainsPercent }}%)</td>
            </tr>
            </tbody>
        </table>

        <div class="ui grid" v-if="selectedCategoryId === null">
            <div class="eight wide column">
                <h2>Volume distribution graph</h2>
                <DomainsByRatingChart :chart-data="domainsByRatingData"></DomainsByRatingChart>
            </div>
            <div class="eight wide column">
                <h2>Conversion distribution graph</h2>
                <ConversionChart :chart-data="conversionData" ></ConversionChart>
            </div>
            <div class="sixteen wide column">
                <h2>Category distribution graph</h2>
                <DomainsByCategoryChart :chart-data="domainsByCategoryData" style="height: 1800px"></DomainsByCategoryChart>
            </div>

            <div class="sixteen wide column">
                <h2>Category distribution</h2>
                <table class="ui celled table">
                    <thead>
                    <tr>
                        <th>Category</th>
                        <th>Domains</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(domainsCount, category) in domains_by_category" :key="category">
                        <td>{{ category }}</td>
                        <td>{{ domainsCount }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <loading :active.sync="isLoading"></loading>
    </div>
</template>

<script>
    import CategoriesForStatistic from '@/components/CategoriesForStatistic'
    import DomainsByRatingChart from '@/components/DomainsByRatingChart'
    import DomainsByCategoryChart from '@/components/DomainsByCategoryChart'
    import ConversionChart from '@/components/ConversionChart'

    export default {

        components: {
            CategoriesForStatistic,
            DomainsByRatingChart,
            DomainsByCategoryChart,
            ConversionChart
        },

        data() {
            return {
                isLoading: false,
                selectedCategoryId: null,
                totalDomains: 0,
                liveDomains: 0,
                domainsGte20: 0,
                totalDomainsEstablished: 0,
                averageDomainRating: 0,

                hasDomains: 0,
                hasDomainsPercent: 0,

                hasCategory: 0,
                hasCategoryPercent:0,

                hasPhone: 0,
                hasPhonePercent:0,

                hasAddress: 0,
                hasAddressPercent:0,

                domainsByRatingData: null,
                domains_by_domain_rating: null,

                domainsByCategoryData: null,
                domains_by_category: null,

                conversionData: null,

                opened_by_domain_rating: null,
                replied_by_domain_rating: null,
                negative_by_domain_rating: null,
                established_by_domain_rating: null,
            };
        },

        mounted() {
            this.loadStatistic()

        },

        watch: {
            totalDomains: function (val) {
                if (val && this.selectedCategoryId === null) {
                    this.fillDomainsByRatingData()
                    this.fillDomainsByCategoryData()
                    this.fillConversionData()
                }
            },
        },

        methods: {
            loadStatistic() {
                this.isLoading = true

                let url = 'statistic/'
                if (this.selectedCategoryId !== null)
                    url = url + '?category_id=' + this.selectedCategoryId

                this.$http.get(url).then(response => {
                    this.totalDomainsEstablished = response.data.total_domains_established
                    this.averageDomainRating = response.data.average_domain_rating
                    this.hasDomains = response.data.has_domains
                    this.hasDomainsPercent = response.data.has_domains_percent

                    this.domains_by_domain_rating = response.data.domains_by_domain_rating
                    this.domains_by_category = response.data.domains_by_category

                    this.opened_by_domain_rating = response.data.opened_by_domain_rating
                    this.replied_by_domain_rating = response.data.replied_by_domain_rating
                    this.negative_by_domain_rating = response.data.negative_by_domain_rating
                    this.established_by_domain_rating = response.data.established_by_domain_rating

                    this.totalDomains = response.data.total_domains
                    this.liveDomains = response.data.live_domains
                    this.domainsGte20 = response.data.domains_gte_20

                    this.hasCategory = response.data.domains_with_category
                    this.hasCategoryPercent = response.data.domains_with_category_percent

                    this.hasPhone = response.data.domains_with_phones
                    this.hasPhonePercent = response.data.domains_with_phones_percent

                    this.hasAddress = response.data.domains_with_address
                    this.hasAddressPercent = response.data.domains_with_address_percent

                    this.isLoading = false
                });
            },

            selectedCategory(categoryId) {
                this.selectedCategoryId = categoryId
                if (this.selectedCategoryId == 'all')
                    this.selectedCategoryId = null

                this.loadStatistic()
            },


            fillDomainsByRatingData() {
                this.domainsByRatingData = {
                    labels: Object.keys(this.domains_by_domain_rating),

                    datasets: [
                        {
                            label: 'OUR',
                            backgroundColor: '#f2711c',
                            data: []
                        },

                        {
                            label: 'TAM',
                            backgroundColor: '#b5cc18',
                            data: [
                                1000000,
                                2499437,
                                2493952,
                                1377630,
                                861493,
                                494599,
                                313324,
                                268988,
                                152985,
                                93021,
                                64713,
                                64898,
                                21209,
                                7368,
                                3175,
                                967,
                                22
                            ]
                        }
                    ]

                }

                for (let prop in this.domains_by_domain_rating)
                {
                    let domainsCount = this.domains_by_domain_rating[prop]
                    this.domainsByRatingData.datasets[0].data.push(domainsCount)
                }
            },

            fillDomainsByCategoryData() {
                this.domainsByCategoryData = {
                    labels: Object.keys(this.domains_by_category),

                    datasets: [
                        {
                            label: 'DOMAINS',
                            backgroundColor: '#b5cc18',
                            data: []
                        },


                    ]

                }

                for (let prop in this.domains_by_category)
                {
                    if (prop === 'Computer Software')
                        continue

                    let domainsCount = this.domains_by_category[prop]
                    this.domainsByCategoryData.datasets[0].data.push(domainsCount)
                }
            },


            fillConversionData() {
                this.conversionData = {
                    labels: Object.keys(this.opened_by_domain_rating),

                    datasets: [
                        {
                            label: 'OPENED',
                            backgroundColor: '#ffe21f',
                            data: []
                        },

                        {
                            label: 'POSITIVE',
                            backgroundColor: '#b5cc18',
                            data: []
                        },

                        {
                            label: 'NEGATIVE',
                            backgroundColor: '#ff695e',
                            data: []
                        },

                        {
                            label: 'ESTABLISHED',
                            backgroundColor: '#2ecc40',
                            data: []
                        },
                    ]

                }

                for (let prop in this.opened_by_domain_rating)
                {
                    let opened = this.opened_by_domain_rating[prop]
                    this.conversionData.datasets[0].data.push(opened)

                    let replied = this.replied_by_domain_rating[prop]
                    this.conversionData.datasets[1].data.push(replied)

                    let negative = this.negative_by_domain_rating[prop]
                    this.conversionData.datasets[2].data.push(negative)

                    let established = this.established_by_domain_rating[prop]
                    this.conversionData.datasets[3].data.push(established)
                }
            },
        }
    }
</script>
