<template>
    <div id="addToListModal" class="ui modal">
        <i class="close icon"></i>
        <div class="header">
            Add autoapproved data from {{ listObj['name'] }} to list
        </div>
        <div class="content">
            <select class="ui fluid dropdown" v-model="selectedList">
                <option value="Select list" disabled selected>Select list</option>
                <option v-for="list in lists" v-bind:value="list.id">{{ list.name }}</option>
            </select>
        </div>

        <div class="actions">
            <div class="ui black deny button">
                Cancel
            </div>

            <div v-if="selectedList !== null" class="ui right labeled icon positive button" @click.stop="startAutoApproval()">
                Start autoapproval
                <i class="checkmark icon"></i>
            </div>
            <div v-else class="ui right labeled icon positive button disabled">
                Start autoapproval
                <i class="checkmark icon"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: ['listObj'],

        data() {
            return {
                lists: [],
                selectedList: null,
            };
        },

        mounted() {
            this.$http.get('mail-lists/').then(response => {
                this.lists = response.data.results
            });
        },

        methods: {
            show()
            {
                $('#addToListModal').modal('show');
            },

            startAutoApproval() {
                let list_id = this.listObj['id']
                let index = this.listObj['index']
                this.$http.get('lists/' + list_id + '/autoapprove/?mail_list_id=' + this.selectedList ).then(response => {
                    this.$parent.lists[index].autoapprove_status = 'in progress'
                    this.$forceUpdate() // otherwise the array doesn't get updated
                });
                $('#addToListModal').modal('hide');
            },

        }
    }
</script>
