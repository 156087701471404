<template>
    <div>
        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">V2 Campaigns</h1>
            </div>
            <div class="column right aligned">
                <button class="ui button" @click="showAddModal"><i class="plus icon"></i>Add campaign</button>
            </div>
        </div>

        <table class="ui celled table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Trello link</th>
                <th>Brief link</th>
                <th>Target goal</th>
                <th>Sender</th>
                <th>Recipients&nbsp;total</th>
                <th>Safe&nbsp;to&nbsp;send</th>
                <th>Risky</th>
                <th>Campaign progress</th>
                <th>Conversions progress</th>
                <th>Status</th>
                <th>End at</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(campaign, index) in campaigns" :key="campaign.id">
                <td :class="{'nearing': (campaign.recipients_count < 100), 'empty': (campaign.recipients_count == 0)}">
                  <router-link :to="'/campaigns/' + campaign.id" class="item">{{ campaign.settings.name }}</router-link>
                </td>
                <td :class="{'nearing': (campaign.recipients_count < 100), 'empty': (campaign.recipients_count == 0)}">
                  <a :href="campaign.settings.trello_link" target="_blank">trello link</a>
                </td>
                <td :class="{'nearing': (campaign.recipients_count < 100), 'empty': (campaign.recipients_count == 0)}">
                  <a :href="campaign.settings.brief_link" target="_blank">brief link</a>
                </td>
                <td :class="{'nearing': (campaign.recipients_count < 100), 'empty': (campaign.recipients_count == 0)}">
                  {{ campaign.settings.target_goal }}
                </td>
                <td :class="{'nearing': (campaign.recipients_count < 100), 'empty': (campaign.recipients_count == 0)}">
                  {{ campaign.settings.email_from }}
                </td>
                <td :class="{'nearing': (campaign.recipients_count < 100), 'empty': (campaign.recipients_count == 0)}">
                  {{ campaign.recipients_count }}
                </td>
                <td :class="{'nearing': (campaign.recipients_count < 100), 'empty': (campaign.recipients_count == 0)}">
                  {{ campaign.safe_to_send_count }}
                </td>
                <td :class="{'nearing': (campaign.recipients_count < 100), 'empty': (campaign.recipients_count == 0)}">
                  {{ campaign.risky_count }}
                </td>
                <td :class="{'nearing': (campaign.recipients_count < 100), 'empty': (campaign.recipients_count == 0)}">
                  {{ campaign.settings.send_outreach_count}}
                </td> 
                <td :class="{'nearing': (campaign.recipients_count < 100), 'empty': (campaign.recipients_count == 0)}">
                  {{ campaign.settings.conversions_count}} <span v-if="campaign.settings.conversions_count"> ({{ calculatePercent(campaign.conversions_count, campaign.recipients_count) }}%)</span>
                </td>
                <td :class="{'nearing': (campaign.recipients_count < 100), 'empty': (campaign.recipients_count == 0)}">
                    <div class="ui toggle checkbox" @click="setActive(index)">
                        <input type="checkbox" tabindex="0" :checked="campaign.settings.is_active" class="hidden">
                        <label></label>
                    </div>
                </td>
                <td :class="{'nearing': (campaign.recipients_count < 100), 'empty': (campaign.recipients_count == 0)}">
                  {{ campaign.settings.end_at | moment("DD.MM.YYYY") }}
                </td>
                <td :class="{'nearing': (campaign.recipients_count < 100), 'empty': (campaign.recipients_count == 0)}">
                    <div class="ui small basic icon buttons">
                        <button class="ui button" @click="showEditModal(index)" data-content="Edit"><i class="edit icon"></i></button>
                        <button class="ui button" @click="deleteCampaign(index)" data-content="Delete"><i class="trash icon"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <V2CampaignModal
          ref="campaignModal"
          :mailboxes="mailboxes"
          :subject-lines="subjectLines"
          @created="createdCampaign"
          @updated="updatedCampaign"
        />
    </div>
</template>

<script>

    import V2CampaignModal from '@/components/V2CampaignModal'

    export default {

        components: {
            V2CampaignModal,
        },

        data() {
            return {
                loader: null,
                campaigns: [],
                selectedMailboxes: [],
                selectedIndex: null,
                subjectLines: [],
                templates: null,
                mailboxes: null,
            };
        },

        mounted() {
            this.loader = this.$loading.show()
        },

        updated() {
           $('.button').popup();
        },

        beforeMount() {
            this.getCampaigns()
            this.getSubjectLines()
            this.getMailboxes()
            this.getTemplates()
        },

        methods: {
          
            getCampaigns() {
              var loader = this.$loading.show()
              this.$http.get('v2-campaigns/').then(response => {

		console.log(response.data)
                for (var i = 0; i < response.data.length; i++) {
			console.log(response.data[i].settings)
                    if (response.data[i].settings !== null) {
			    this.campaigns.push(response.data[i])
			    // response.data.splice(i - 1, i)
                    }
                }
		console.log(response.data)
                // this.campaigns = response.data
                loader.hide()
              });
            },

            getSubjectLines() {
              this.$http.get('v2-subjectlines').then(response => {
                this.subjectLines = response.data
                this.$forceUpdate() // otherwise the array doesn't get updated and UI table stays empty
                this.loader.hide()
              });
            },

            getTemplates()
            {
                this.$http.get('templates/group_by_type/').then(response => {
                    this.templates = response.data
                })
            },

            getMailboxes()
            {
                this.$http.get('mailboxes').then(response => {
                    this.mailboxes = response.data
                })
            },

            showAddModal(index)
            {
                this.$refs.campaignModal.show();
            },

            showEditModal(index)
            {
                this.selectedIndex = index
                this.$refs.campaignModal.show();
                this.$refs.campaignModal.campaign = Object.assign({}, this.campaigns[index])
            },

            createdCampaign(campaign)
            {
                this.campaigns.unshift(campaign)
            },

            updatedCampaign(campaign_)
            {
                let campaign = this.campaigns[this.selectedIndex]

                campaign.name = campaign_.name
                campaign.trello_link = campaign_.trello_link
                campaign.brief_link = campaign_.brief_link
                campaign.target_goal = campaign_.target_goal
                campaign.is_active = campaign_.is_active
                campaign.end_at = campaign_.end_at
                campaign.email_from = campaign_.email_from
                campaign.outreach_templates = campaign_.outreach_templates
                campaign.follow_up_templates = campaign_.follow_up_templates

                this.selectedIndex = null
            },

            deleteCampaign(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                this.$http.delete('campaigns/' + this.campaigns[index].id + '/').then(response => {
                    this.campaigns.splice(index, 1)
                    this.loader.hide()
                });
            },

            setActive(index) {
                this.loader = this.$loading.show()

                let data = {
                    is_active: !this.campaigns[index].is_active
                }

                this.$http.patch('campaigns/' + this.campaigns[index].id + '/', data).then(response => {
                    this.campaigns[index].is_active = !this.campaigns[index].is_active
                    this.loader.hide()
                });
            },

            calculatePercent(val, total)
            {
                if (total == 0)
                    return (0).toFixed(2)

                val = parseFloat(val)
                total = parseFloat(total)

                return (val * 100 / total).toFixed(2)
            }
        }
    }
</script>
<style>
  table td.default {
    background-color: #fff !important;
    color: #333;
  }
  table td.nearing {
    background-color: #FFF9C4 !important;
    color: #333;
  }
  table td.empty {
    background-color: #FFCCBC !important;
    color: #333;
  }
</style>
