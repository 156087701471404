<template>
    <div>
        <div id="editArticleModal" class="ui modal">
            <i class="close icon"></i>
            <div class="header">
                Edit Article
            </div>
            <div class="content">
                <div class="ui form" v-if="article">
                    <div :class="(article.title === '' && error) ? 'field error' : 'field'">
                        <label>Article Title</label>
                        <input type="text" v-model="article.title">
                    </div>

                    <div :class="(article.url === '' && error) ? 'field error' : 'field'">
                        <label>Url</label>
                        <input type="text" v-model="article.url">
                    </div>

                    <div class="field">
                        <label>Select Category</label>
                        <select class="ui fluid dropdown" v-model="categoryId">
                            <option value="" selected>Something else</option>
                            <option v-for="category in categories" v-bind:value="category.id" v-bind:key="category.id">{{ category.name }}</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Html title</label>
                        <input type="text" v-model="article.html_title">
                    </div>

                    <div class="field">
                        <label>Keywords</label>
                        <input type="text" v-model="article.html_keywords">
                    </div>

                    <div class="field">
                        <label>Text</label>
                        <ckeditor :editor="editor" v-model="article.text" :config="editorConfig"></ckeditor>
                    </div>

                    <div class="field">
                        <label>Translated text</label>
                        <ckeditor :editor="editor" v-model="article.text_translated" :config="editorConfig"></ckeditor>
                    </div>
                </div>
            </div>
            <div class="actions">
                <div class="ui black deny button">
                    Cancel
                </div>
                <div class="ui right labeled icon positive button" @click.stop="saveArticle()">
                    Save
                    <i class="checkmark icon"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    export default {
        data() {
            return {
                editor: ClassicEditor,
                editorConfig: {
                    toolbar: {
                        items: [
                            'heading',
                            'bold',
                            'italic',
                            'undo',
                            'redo',
                        ]
                    }
                },

                article: {
                    title: '',
                    url: '',
                    category_id: '',
                    category: '',
                    html_title: '',
                    html_keywords: '',
                    text: '',
                    text_translated: '',
                },

                loader: null,
                error: false,
                categoryId: '',
                categories: null
            };
        },

        mounted() {
            this.$http.get('article-categories/').then(response => {
                this.categories = response.data
            });
        },

        watch: {
            article: function (val) {
                if (val && val.category)
                    this.categoryId = val.category_id
                else
                    this.categoryId = ''
            },
        },

        methods: {
            show()
            {
                $('#editArticleModal').modal('show');
            },

            saveArticle()
            {
                if (this.name === '' || this.url === '')
                {
                    this.error = true
                    return
                }

                this.error = false

                this.loader = this.$loading.show()

                this.article.category_id = (this.categoryId !== '') ? this.categoryId : null

                this.$http.patch('articles/' + this.article.id + '/', this.article).then(response => {
                    $('#editArticleModal').modal('hide')
                    this.$emit('saved', response.data)

                }).catch((error) => {
                    console.log(error)

                }).then(response => {

                    this.article = {
                        title: '',
                        url: '',
                        category_id: '',
                        category: '',
                        html_title: '',
                        html_keywords: '',
                        text: '',
                        text_translated: '',
                    },

                    this.categoryId = ''

                    this.loader.hide()
                })
            },
        }
    }
</script>
