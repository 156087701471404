<template>
    <select class="ui fluid dropdown" @change="changed" v-model="selected">
        <option value="Select Category" disabled selected>Select Category</option>
        <option value="">Without category</option>
        <option v-for="category in categories" v-bind:value="category.id" v-bind:key="category.id">{{ category.name }}</option>
    </select>
</template>

<script>
    export default {
        data() {
            return {
                categories: [],
                selected: null,
            };
        },

        mounted() {
            this.$http.get('categories/').then(response => {
                this.categories = response.data
            });
        },

        updated()
        {
          //$('.dropdown').dropdown()
        },

        methods: {
            changed() {
                this.$emit('selected', this.selected)
            },
        }
    }
</script>
