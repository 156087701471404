<template>
    <div>
        <div @click.prevent="show" class="import-button"><i class="icon bookmark"></i>Pitch Article</div>

        <div id="pitchArticleModal" class="ui modal">
            <i class="close icon"></i>
            <div class="header">
                Pitch Article
            </div>
            <div class="content">
                <div class="ui form">
                    <div :class="(title === '' && error) ? 'field error' : 'field'">
                        <label>Title</label>
                        <input type="text" v-model="title">
                    </div>

                    <div :class="(url === '' && error) ? 'field error' : 'field'">
                        <label>Url</label>
                        <input type="text" v-model="url">
                    </div>

                    <div class="field">
                        <label>Select Category</label>
                        <select class="ui fluid dropdown" v-model="categoryId">
                            <option value="" selected>Something else</option>
                            <option v-for="category in categories" v-bind:value="category.id" v-bind:key="category.id">{{ category.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="actions">
                <div class="ui black deny button">
                    Cancel
                </div>
                <div class="ui right labeled icon positive button" @click.stop="saveArticle()">
                    Save
                    <i class="checkmark icon"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loader: null,
                error: false,
                title: '',
                url: '',
                categoryId: '',
                categories: null
            };
        },

        mounted() {
            this.$http.get('article-categories/').then(response => {
                this.categories = response.data
            });
        },

        methods: {
            show()
            {
                $('#pitchArticleModal').modal('show');
            },

            saveArticle()
            {
                if (this.name === '' || this.url === '')
                {
                    this.error = true
                    return
                }

                this.error = false

                this.loader = this.$loading.show()

                let category = (this.categoryId !== '') ? this.categoryId : null

                let data = {
                    title: this.title,
                    url: this.url,
                    category_id: category
                }

                this.$http.post('articles/', data).then(response => {
                    $('#pitchArticleModal').modal('hide')

                }).catch((error) => {
                    console.log(error)
                }).then(response => {

                    this.title = ''
                    this.url = ''
                    this.categoryId = ''

                    this.loader.hide()
                })
            },
        }
    }
</script>
