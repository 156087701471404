<template>
    <div class="ui container">
        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">
                    <router-link :to="'/finance/'" class="item">Finance</router-link> / <span v-if="project"><router-link :to="'/finance/main-projects/' + project.parent_id" class="item">{{ project.parent_name}}</router-link> / {{ project.name}}</span>
                </h1>
            </div>
            <div class="column right aligned">
                <button class="ui button" @click="showAddExpenseModal"><i class="plus icon"></i>Add Expense</button>
                <button class="ui button" @click="showAddProfitModal"><i class="plus icon"></i>Add Profit</button>
            </div>
        </div>

        <div class="ui secondary pointing menu">
            <a :class="period === 'month' ? 'item active' : 'item'" @click="period='month'">
                This month
            </a>
            <a :class="period === 'all' ? 'item active' : 'item'" @click="period='all'">
                All time
            </a>
        </div>


        <div class="ui three column grid">
            <div class="column">
                <h3>Due expenses</h3>
                <table class="ui celled table" v-if="dueExpenses.length">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Amount</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(expense, index) in dueExpenses" :key="expense.id">
                        <td><a href="#" @click.prevent="showEditExpenseModal(index, false)">{{ expense.name }}</a></td>
                        <td>{{ expense.amount }}</td>
                        <td>
                            <div class="ui small basic icon buttons">
                                <button class="ui button" @click="deleteDue(index)" data-content="Delete"><i class="trash icon"></i></button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th><b>Total:</b></th>
                        <th colspan="2"><b>{{ dueTotalAmount }}</b></th>
                    </tr>
                    </tfoot>
                </table>
            </div>
            <div class="column">
                <h3>Paid expenses</h3>
                <table class="ui celled table" v-if="paidExpenses.length">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Amount</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(expense, index) in paidExpenses" :key="expense.id">
                        <td><a href="#" @click.prevent="showEditExpenseModal(index, true)">{{ expense.name }}</a></td>
                        <td>{{ expense.amount }}</td>
                        <td>
                            <div class="ui small basic icon buttons">
                                <button class="ui button" @click="deletePaid(index)" data-content="Delete"><i class="trash icon"></i></button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th><b>Total:</b></th>
                        <th colspan="2"><b>{{ paidTotalAmount }}</b></th>
                    </tr>
                    </tfoot>
                </table>
            </div>
            <div class="column">
                <h3>Profits</h3>
                <table class="ui celled table" v-if="profits.length">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Amount</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(profit, index) in profits" :key="profit.id">
                        <td><a href="#" @click.prevent="showEditProfitModal(index, true)">{{ profit.name }}</a></td>
                        <td>{{ profit.amount }}</td>
                        <td>
                            <div class="ui small basic icon buttons">
                                <button class="ui button" @click="deleteProfit(index)" data-content="Delete"><i class="trash icon"></i></button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th><b>Total:</b></th>
                        <th colspan="2"><b>{{ profitTotalAmount }}</b></th>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <ExpenseModal ref="expenseModal" :project_id="projectId" @created="createdExpense" @updated="updatedExpense"></ExpenseModal>
        <ProfitModal ref="profitModal" :project_id="projectId" @created="createdProfit" @updated="updatedProfit"></ProfitModal>
    </div>
</template>

<script>

    import ProjectModal from '@/components/finance/ProjectModal'
    import ExpenseModal from '@/components/finance/ExpenseModal'
    import ProfitModal from '@/components/finance/ProfitModal'

    export default {

        components: {
            ProjectModal,
            ExpenseModal,
            ProfitModal
        },

        data() {
            return {
                selectedExpenseIndex: null,
                selectedExpense: null,
                selectedProfitIndex: null,
                projectId: null,
                project: null,
                dueExpenses: [],
                paidExpenses: [],
                profits: [],
                period: 'month'
            };
        },

        mounted() {
            this.projectId = this.$route.params.id
            this.loadProject()
            this.loadDueExpenses()
            this.loadPaidExpenses()
            this.loadProfits()
        },

        computed: {
            dueTotalAmount: function () {

                let totalAmount = 0

                for (let key in this.dueExpenses)
                    totalAmount += this.dueExpenses[key].amount

                return totalAmount
            },

            paidTotalAmount: function () {

                let totalAmount = 0

                for (let key in this.paidExpenses)
                    totalAmount += this.paidExpenses[key].amount

                return totalAmount
            },

            profitTotalAmount: function () {

                let totalAmount = 0

                for (let key in this.profits)
                    totalAmount += this.profits[key].amount

                return totalAmount
            }
        },

        watch: {
            period: function (val) {
                this.loadDueExpenses()
                this.loadPaidExpenses()
                this.loadProfits()
            },
        },

        updated() {
            $('.button').popup();
        },

        methods: {

            loadProject()
            {
                this.$http.get('finance/projects/' + this.projectId + '/').then(response => {
                    this.project = response.data
                });
            },

            loadDueExpenses() {
                //this.loader = this.$loading.show()
                this.$http.get('finance/expenses/?project_id=' + this.projectId + '&is_paid=0&period=' + this.period).then(response => {
                    this.dueExpenses = response.data
                    //this.loader.hide()
                });
            },

            loadPaidExpenses() {
                //this.loader = this.$loading.show()
                this.$http.get('finance/expenses/?project_id=' + this.projectId + '&is_paid=1&period=' + this.period).then(response => {
                    this.paidExpenses = response.data
                    //this.loader.hide()
                });
            },

            loadProfits() {
                //this.loader = this.$loading.show()
                this.$http.get('finance/profits/?project_id=' + this.projectId + '&period=' + this.period).then(response => {
                    this.profits = response.data
                    //this.loader.hide()
                });
            },

            showAddExpenseModal() {
                this.$refs.expenseModal.show();
            },

            showEditExpenseModal(index, is_paid) {
                if (is_paid === true)
                    this.selectedExpense = this.paidExpenses[index]
                else
                    this.selectedExpense = this.dueExpenses[index]

                this.selectedExpenseIndex = index
                this.$refs.expenseModal.show()
                this.$refs.expenseModal.expense = Object.assign({}, this.selectedExpense)
            },

            showAddProfitModal() {
                this.$refs.profitModal.show();
            },

            showEditProfitModal(index) {
                this.selectedProfitIndex = index
                this.$refs.profitModal.show()
                this.$refs.profitModal.profit = Object.assign({}, this.profits[index])
            },

            createdExpense(expense) {
                if (expense.is_paid === true)
                    this.paidExpenses.push(expense)
                else
                    this.dueExpenses.push(expense)
            },

            updatedExpense(expense) {
                if (this.selectedExpense.is_paid !== expense.is_paid) {
                    if (expense.is_paid === true) {
                        this.dueExpenses.splice(this.selectedExpenseIndex, 1)
                        this.paidExpenses.push(expense)
                    } else {
                        this.paidExpenses.splice(this.selectedExpenseIndex, 1)
                        this.dueExpenses.push(expense)
                    }
                } else
                {
                    if (expense.is_paid === true)
                        this.$set(this.paidExpenses, this.selectedExpenseIndex, expense)
                    else
                        this.$set(this.dueExpenses, this.selectedExpenseIndex, expense)
                }

                this.selectedExpenseIndex = null
            },

            createdProfit(profit) {
                this.profits.push(profit)
            },

            updatedProfit(profit) {
                this.$set(this.profits, this.selectedProfitIndex, profit)
                this.selectedProfitIndex = null
            },


            deleteDue(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                this.$http.delete('finance/expenses/' + this.dueExpenses[index].id + '/').then(response => {
                    this.dueExpenses.splice(index, 1)
                    this.loader.hide()
                });
            },

            deletePaid(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                this.$http.delete('finance/expenses/' + this.paidExpenses[index].id + '/').then(response => {
                    this.paidExpenses.splice(index, 1)
                    this.loader.hide()
                });
            },

            deleteProfit(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                this.$http.delete('finance/expenses/' + this.profits[index].id + '/').then(response => {
                    this.profits.splice(index, 1)
                    this.loader.hide()
                });
            },
        }
    }
</script>
