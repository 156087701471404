<template>
    <sui-modal v-model="open" :closeIcon="true">

        <sui-modal-header>Add contact</sui-modal-header>

        <sui-modal-content >
            <div class="ui grid form">

                <div class="eight wide column">
                    <div :class="error !== false ? 'field error' : 'field'">
                        <label>Email <span v-if="error !== false && error !== ''">({{ error }})</span></label>
                        <input type="email" v-model="contact.email">
                    </div>
                </div>

                <div class="eight wide column">
                    <div class="field">
                        <label>Phone</label>
                        <input type="text" v-model="contact.phone">
                    </div>
                </div>

                <div class="eight wide column">
                    <div class="field">
                        <label>Name</label>
                        <input type="text" v-model="contact.first_name">
                    </div>
                </div>

                <div class="eight wide column">
                    <div class="field">
                        <label>Last name</label>
                        <input type="text" v-model="contact.last_name">
                    </div>
                </div>

                <div class="eight wide column">
                    <div class="field">
                        <label>Position</label>
                        <input type="text" v-model="contact.position">
                    </div>
                </div>

            </div>
        </sui-modal-content>

        <sui-modal-actions>
            <div class="ui black deny button" @click="open=false">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click="saveContact">
                Save<i class="checkmark icon"></i>
            </div>
        </sui-modal-actions>
    </sui-modal>
</template>

<script>

    export default {

        props: ['domain_id'],

        data() {
            return {
                open: false,
                loader: null,
                error: false,

                contact:
                {
                    id: null,
                    domain: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    position: '',
                },
            };
        },

        mounted() {

        },


        methods: {
            show()
            {
                this.error = false
                this.open = true

                this.contact = {
                    id: null,
                    domain: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    position: '',
                }
            },

            saveContact()
            {
                if (!this.contact.email)
                {
                    this.error = ''
                    return
                }

                this.error = false


                if (this.contact.id)
                    this.updateContact()
                else
                    this.createContact()

            },

            createContact(){

                this.loader = this.$loading.show()

                this.contact['domain'] = this.domain_id

                console.log(this.contact)

                this.$http.post('contacts/', this.contact).then(response => {
                    this.$emit('created', response.data)
                    this.open = false

                }).catch(error => {
                    console.log(error.response.data)
                    if (error.response.data.email)
                        this.error = error.response.data.email[0]

                }).then(response => {
                    this.loader.hide()
                })
            },

            updateContact(){

                this.loader = this.$loading.show()

                this.$http.patch('contacts/' + this.contact.id + '/', this.contact).then(response => {
                    this.$emit('updated', response.data)
                    this.open = false

                }).catch(error => {
                    console.log(error.response.data)
                    if (error.response.data.email)
                        this.error = error.response.data.email[0]

                }).then(response => {
                    this.loader.hide()
                })
            }

        }
    }
</script>
