<template>
    <div class="ui container">

        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">Users</h1>
            </div>
            <div class="column right aligned">
                <button class="ui button" @click="showAddModal"><i class="plus icon"></i>Add user</button>
            </div>
        </div>

        <table class="ui celled table">
            <thead>
            <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Rate</th>
                <th>Worked hours</th>
                <th>Paid hours</th>
                <th>Unpaid hours</th>
                <th>Admin</th>
                <th>Editor</th>
                <th>Active</th>
                <th>Last login</th>
                <th>Created at</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(user, index) in users" :key="user.id">
                <td><router-link :to="'/users/' + user.id + '/statistic'" class="item">{{ user.username }}</router-link></td>
                <td>{{ user.email }}</td>
                <td>{{ user.rate }}</td>
                <td>{{ user.worked_time }}</td>
                <td>{{ user.paid_time }}</td>
                <td>{{ user.unpaid_time }}</td>
                <td>
                    <div class="ui toggle checkbox" @click="setAdmin(index)">
                        <input type="checkbox" tabindex="0" :checked="user.is_superuser" class="hidden">
                        <label></label>
                    </div>
                </td>
                <td>
                    <div class="ui toggle checkbox" @click="setEditor(index)">
                        <input type="checkbox" tabindex="0" :checked="user.is_editor" class="hidden">
                        <label></label>
                    </div>
                </td>
                <td>
                    <div class="ui toggle checkbox" @click="setActive(index)">
                        <input type="checkbox" tabindex="0" :checked="user.is_active" class="hidden">
                        <label></label>
                    </div>
                </td>
                <td>{{ user.last_login }}</td>
                <td>{{ user.date_joined }}</td>
                <td>
                    <div class="ui small basic icon buttons">
                        <button class="ui button" @click="showEditModal(index)" data-content="Edit"><i class="edit icon"></i></button>
                        <button class="ui button" @click="payWork(index)" data-content="Pay unpaid hours"><i class="dollar icon"></i></button>
                        <button class="ui button" @click="deleteUser(index)" data-content="Delete"><i class="trash icon"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <UserModal ref="userModal" @created="createdUser" @updated="updatedUser"></UserModal>
    </div>
</template>

<script>

    import UserModal from '@/components/UserModal'

    export default {

        components: {
            UserModal
        },

        data() {
            return {
                loader: null,
                users: [],
                userObj: {
                    'username': '',
                    'email': '',
                    'is_superuser': '',
                    'is_editor': false

                },
                selectedUserIndex: null
            };
        },

        mounted() {
            this.loader = this.$loading.show()

            this.$http.get('users/').then(response => {
                this.users = response.data
                this.loader.hide()
            });
        },

        updated() {
            // Semantic popup
            $('.button').popup();
        },

        methods: {

            showAddModal(index)
            {
                this.$refs.userModal.show();
            },

            showEditModal(index)
            {
                this.selectedUserIndex = index
                this.$refs.userModal.show();
                this.$refs.userModal.user = Object.assign({}, this.users[index])
            },

            createdUser(user)
            {
                this.users.unshift(user)
            },

            updatedUser(user)
            {
                this.$set(this.users, this.selectedUserIndex, user)
                this.selectedUserIndex = null
            },

            setAdmin(index) {
                this.loader = this.$loading.show()

                let data = {
                    is_superuser: !this.users[index].is_superuser
                }

                this.$http.patch('users/' + this.users[index].id + '/', data).then(response => {
                    this.users[index].is_superuser = !this.users[index].is_superuser
                    this.loader.hide()
                });
            },

            setEditor(index) {
                this.loader = this.$loading.show()

                let data = {
                    is_editor: !this.users[index].is_editor
                }

                this.$http.patch('users/' + this.users[index].id + '/', data).then(response => {
                    this.users[index].is_editor = !this.users[index].is_editor
                    this.loader.hide()
                });
            },

            setActive(index) {
                this.loader = this.$loading.show()

                let data = {
                    is_active: !this.users[index].is_active
                }

                this.$http.patch('users/' + this.users[index].id + '/', data).then(response => {
                    this.users[index].is_active = !this.users[index].is_active
                    this.loader.hide()
                });
            },

            payWork(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                this.$http.post('users/' + this.users[index].id + '/pay_work/').then(response => {
                    this.$set(this.users, index, response.data)
                    this.loader.hide()
                });
            },

            deleteUser(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                this.$http.delete('users/' + this.users[index].id).then(response => {
                    this.users.splice(index, 1)
                    this.loader.hide()
                });
            },

        }
    }
</script>
