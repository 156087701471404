<template>
    <div>
        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">Mail lists</h1>
            </div>
            <div class="column right aligned">
                <button class="ui button" @click="addList" type="submit">Add list</button>
            </div>
        </div>

         <div class="ui secondary pointing menu">
            <a :class="!archive ? 'item active' : 'item'" @click="archive=false">
                In work
            </a>
            <a :class="archive ? 'item active' : 'item'" @click="archive=true">
                Archive
            </a>
        </div>

        <table class="ui celled table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Campaign</th>
                <th>Recipients</th>
                <th>Campaign progress</th>
                <th>Follow up progress</th>
                <th>Opened</th>
                <th>Replied</th>
                <th>Outreach AB</th>
                <th>Follow up AB</th>
                <th>Conversions</th>
                <th>Opp created/send/dismissed</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(list, index) in lists" :key="list.id">
                <td>
                    <router-link :to="'/mail-lists/' + list.id" class="item">{{ list.name }}</router-link>
                </td>
                <td>{{ list.campaign_name }}</td>
                <td>{{ list.recipients_count }}</td>
                <td>{{ list.send_outreach_count }} ({{ calculatePercent(list.send_outreach_count, list.recipients_count) }}%)</td>
                <td>{{ list.send_follow_up_count }} ({{ calculatePercent(list.send_follow_up_count, list.recipients_count) }}%)</td>
                <td>{{ list.opened_count }} {{ calculatePercent(list.opened_count, list.recipients_count) }}%</td>
                <td>{{ list.replied_count }} \ {{ list.recipients_count }} ({{ calculatePercent(list.replied_count, list.recipients_count) }}%)</td>

                <td>{{ list.outreach_ab_is_enabled }}</td>
                <td>{{ list.follow_up_ab_is_enabled }}</td>

                <td>{{ list.conversions_count }} ({{ calculatePercent(list.conversions_count, list.recipients_count) }}%)</td>

                <td>{{ list.opportunities_count }}/{{ list.opportunities_send_count }}/{{ list.opportunities_dismissed }}</td>
                <td>
                    <div class="ui toggle checkbox" @click="setStatus(index)">
                        <input type="checkbox" tabindex="0" :checked="list.is_active" class="hidden">
                        <label></label>
                    </div>
                </td>

                <td>
                    <div class="ui small basic icon buttons">
                        <router-link :to="'/mail-lists/' + list.id" class="ui button" data-content="Show list"><i class="eye icon"></i>
                        </router-link>
                        <button class="ui button" @click="editList(index)" data-content="Edit"><i class="edit icon"></i></button>
                        <button  v-if="!archive" class="ui button" @click="showArchiveModal(list.id)" data-content="Archive">
                            <i class="file archive icon"></i>
                        </button>
                        <button v-else class="ui button" @click="restoreFromArchive(index)" data-content="Restore from archive">
                            <i class="file archive outline icon"></i>
                        </button>
                        <a v-if="($user && $user.name === 'alex') || ($user && $user.name === 'grintender')" :href="'/api/mail-lists/' + list.id + '/export_domains/'" class="ui button" data-content="Download domains"><i class="download icon"></i></a>
                        <a v-if="($user && $user.name === 'alex') || ($user && $user.name === 'grintender')" :href="'/api/mail-lists/' + list.id + '/export_contacts/'" class="ui button" data-content="Download contacts"><i class="download icon"></i></a>
                        <button class="ui button" @click="deleteList(index)" data-content="Delete list"><i class="trash icon"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <!--
        <br/>
        <div>
            <form class="ui form" @submit.prevent="addList">
                <div class="inline field">
                    <input type="text" placeholder="name" v-model="listObj.name">
                    <button class="ui button" type="submit">Add list</button>
                </div>
            </form>
        </div>
        -->
        <MailListModal ref="mailListModal" :list="selectedList" @saved="savedMailList"></MailListModal>
        <MailListAddToArchiveModal ref="mailListAddToArchiveModal" @archived="archivedMailList"></MailListAddToArchiveModal>
    </div>
</template>

<script>

    import MailListModal from '@/components/MailListModal'
    import MailListAddToArchiveModal from '@/components/MailListAddToArchiveModal'

    export default {

        components: {
            MailListModal,
            MailListAddToArchiveModal
        },

        data() {
            return {
                loader: false,
                lists: [],
                listObj: {
                    'name': ''
                },
                selectedListIndex: null,
                selectedList: null,
                archive: false
            };
        },

        watch: {
            archive: function (val) {
                this.loadData()
            },
        },

        mounted() {
            this.loadData()
        },

        updated() {
            // Semantic popup
            $('.button').popup();
        },

        methods: {

            loadData()
            {
                this.loader = this.$loading.show()

                let url = 'mail-lists/'
                if (this.archive)
                    url += '?archive=1'

                this.$http.get(url).then(response => {
                    this.lists = response.data.results
                    this.loader.hide()
                });
            },

            addList()
            {
                this.selectedListIndex = null
                this.selectedList = null
                this.$refs.mailListModal.show()
            },

            editList(index)
            {
                this.selectedListIndex = index
                this.$refs.mailListModal.show()
                this.selectedList = Object.assign({}, this.lists[index])
            },

            savedMailList(list)
            {
                if (!this.selectedList)
                    this.lists.push(list)
                else {
                    this.$set(this.lists, this.selectedListIndex, list)
                    this.selectedListIndex = null
                    this.selectedList = null
                }
            },

            showArchiveModal(id)
            {
                this.$refs.mailListAddToArchiveModal.show()
                this.$refs.mailListAddToArchiveModal.mailListId = id
            },

            archivedMailList(id)
            {
                let index = this.lists.findIndex(list => list.id === id);
                this.lists.splice(index, 1)
            },

            restoreFromArchive(index)
            {
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                let list = this.lists[index]

                let data = {
                    'is_archive': false
                }

                this.$http.patch('mail-lists/' + list.id + '/', data).then(response => {
                    this.lists.splice(index, 1)
                    this.loader.hide()
                });
            },

            deleteList(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()
                this.$http.delete('mail-lists/' + this.lists[index].id).then(response => {
                    this.lists.splice(index, 1)
                    this.loader.hide()
                });
            },

            setStatus(index) {
                this.loader = this.$loading.show()

                this.$http.post('mail-lists/' + this.lists[index].id + '/set_status/').then(response => {
                    this.lists[index].is_active = !this.lists[index].is_active

                    this.loader.hide()
                });
            },

            calculatePercent(val, total)
            {
                if (total == 0)
                    return (0).toFixed(2)

                val = parseFloat(val)
                total = parseFloat(total)

                return (val * 100 / total).toFixed(2)
            }
        }
    }
</script>
