<template>
    <div>
        <div id="userModal" class="ui modal" v-if="user">
            <i class="close icon"></i>
            <div v-if="!user.id" class="header">Add user</div>
            <div v-else class="header">Edit user {{ user.username }}</div>
            <div class="content">
                <div class="ui form">

                    <div v-if="!user.id" :class="(!user.username && error) || (errors && errors.username) ? 'field error' : 'field'">
                        <label>Username<span v-if="errors && errors.username"> ({{ errors.username[0] }})</span></label>
                        <input type="text" v-model="user.username">
                    </div>

                    <div class="field" :class="(!user.id && !user.password && error) ? 'field error' : 'field'">
                        <label v-if="!user.id">Password</label>
                        <label v-else>New password</label>
                        <input type="text" v-model="user.password">
                    </div>

                    <div :class="(errors && errors.email) ? 'field error' : 'field'">
                        <label>Email<span v-if="errors && errors.email"> ({{ errors.email[0] }})</span></label>
                        <input type="text" v-model="user.email">
                    </div>

                    <div class="field">
                        <label>Rate</label>
                        <input type="number" v-model="user.rate">
                    </div>

                    <div class="field">
                        <div class="ui toggle checkbox" @click="user.is_superuser = !user.is_superuser">
                            <input type="checkbox" :checked="user.is_superuser" class="hidden">
                            <label>is Admin</label>
                        </div>
                    </div>

                    <div class="field">
                        <div class="ui toggle checkbox" @click="user.is_editor = !user.is_editor">
                            <input type="checkbox" :checked="user.is_editor" class="hidden">
                            <label>is Editor</label>
                        </div>
                    </div>

                    <div class="field">
                        <div class="ui toggle checkbox" @click="user.is_active = !user.is_active">
                            <input type="checkbox" :checked="user.is_active" class="hidden">
                            <label>is Active</label>
                        </div>
                    </div>

                    <h3 class="ui header">Permissions</h3>

                    <div class="field">
                        <div class="ui toggle checkbox" @click="updateGroups('database_staff')">
                            <input type="checkbox" :checked="user.groups.indexOf('database_staff') !== -1" class="hidden">
                            <label>Access to Database</label>
                        </div>
                    </div>

                    <div class="field">
                        <div class="ui toggle checkbox" @click="updateGroups('campaigns_staff')">
                            <input type="checkbox" :checked="user.groups.indexOf('campaigns_staff') !== -1" class="hidden">
                            <label>Access to Campaigns</label>
                        </div>
                    </div>

                    <div class="field">
                        <div class="ui toggle checkbox" @click="updateGroups('opportunities_staff')">
                            <input type="checkbox" :checked="user.groups.indexOf('opportunities_staff') !== -1" class="hidden">
                            <label>Access to Opportunities</label>
                        </div>
                    </div>

                    <div class="field">
                        <div class="ui toggle checkbox" @click="updateGroups('reports_staff')">
                            <input type="checkbox" :checked="user.groups.indexOf('reports_staff') !== -1" class="hidden">
                            <label>Access to Reports</label>
                        </div>
                    </div>

                    <div class="field">
                        <div class="ui toggle checkbox" @click="updateGroups('users_staff')">
                            <input type="checkbox" :checked="user.groups.indexOf('users_staff') !== -1" class="hidden">
                            <label>Access to Users</label>
                        </div>
                    </div>

                </div>
            </div>
            <div class="actions">
                <div class="ui black deny button">
                    Cancel
                </div>
                <div class="ui right labeled icon positive button" @click.stop="saveUser()">
                    Save
                    <i class="checkmark icon"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {

                user: null,

                defaultParams: {
                    email: '',
                    is_superuser: false,
                    is_active: true,
                    is_editor: false,

                    groups: [],

                    is_database_staff: false,
                    is_campaigns_staff: false,
                    is_opportunities_staff: false,
                    is_reports_staff: false,
                    is_users_staff: false,
                },

                loader: null,
                error: false,
                errors: null
            };
        },

        mounted() {
            this.user = this.defaultParams
        },

        methods: {
            show()
            {
                this.user = Object.assign({}, this.defaultParams)
                this.error = false
                this.errors = null

                $('#userModal').modal('show')
            },

            saveUser()
            {
                if (this.user.id)
                    this.updateUser()
                else
                    this.createUser()
            },

            createUser()
            {

                if (!this.user.username || !this.user.password)
                {
                    this.error = true
                    return
                }

                this.error = false

                this.loader = this.$loading.show()

                this.$http.post('users/', this.user).then(response => {
                    this.$emit('created', response.data)
                    $('#userModal').modal('hide')

                }).catch(error => {
                    this.errors = error.response.data
                    console.log(this.errors)

                }).then(response => {
                    this.user = this.defaultParams
                    this.loader.hide()
                })
            },

            updateUser()
            {
                this.loader = this.$loading.show()

                this.$http.patch('users/' + this.user.id + '/', this.user).then(response => {
                    this.$emit('updated', response.data)

                }).catch((response) => {
                    console.log(response)

                }).then(response => {

                    this.user = this.defaultParams

                    $('#userModal').modal('hide')
                    this.loader.hide()
                })
            },

            updateGroups(group_name)
            {
                let groupIndex = this.user.groups.indexOf(group_name)

                if (groupIndex === -1)
                    this.user.groups.push(group_name)
                else
                    this.user.groups.splice(groupIndex, 1)
            }
        }
    }
</script>
