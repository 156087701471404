<template>
    <div>
        <h1 class="ui header">All recipients</h1>

        <br/>

        <div class="ui text container">
            <paginate v-if="pagesCount > 1"
                      v-model="currentPage"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>

        <table class="ui celled table">
            <thead>
            <tr>
                <th>Website</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Name</th>
                <th>Is send outreach</th>
                <th>Is send follow up</th>
                <th>Is opened</th>
                <th>Is reply</th>
                <th>Is negative</th>
                <th>Outreach send</th>
                <th>Follow up send</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(recipient, index) in recipients" v-bind:key="recipient.id">
                <td><a :href="'http://' + recipient.website" target="_blank">{{ recipient.website }}</a></td>
                <td>{{ recipient.email }}</td>
                <td>{{ recipient.phone }}</td>
                <td>{{ recipient.first_name + ' ' + recipient.last_name}}</td>
                <td>{{ recipient.is_send_outreach }}</td>
                <td>{{ recipient.is_send_follow_up }}</td>
                <td>{{ recipient.is_opened }}</td>
                <td>
                    <div class="ui toggle checkbox" @click="setReply(index)">
                        <input type="checkbox" tabindex="0" :checked="recipient.is_reply" class="hidden">
                        <label></label>
                    </div>
                </td>
                <td>
                    <div class="ui toggle checkbox" @click="setNegative(index)">
                        <input type="checkbox" tabindex="0" :checked="recipient.is_negative" class="hidden">
                        <label></label>
                    </div>
                </td>
                <td>{{ recipient.outreach_send_at }}</td>
                <td>{{ recipient.follow_up_send_at }}</td>
                <td>
                    <div class="ui small basic icon buttons">
                        <button class="ui button" @click="removeRecipient(index)" data-content="Remove from mail list"><i class="trash icon"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <div class="ui text container">
            <paginate v-if="pagesCount > 1"
                      v-model="currentPage"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>
    </div>
</template>

<script>

    export default {

        data() {
            return {
                loader: null,
                recipients: null,

                recipientsOnPage: 1000,
                pagesCount: 0,
                currentPage: 1,
            };
        },

        mounted() {
            this.loadRecipients()
        },

        updated() {
            // Semantic popup
            $('.button').popup();
        },

        methods: {
            loadRecipients(offset = 0)
            {
                 // paging
                let url = 'recipients/?limit=' + this.recipientsOnPage + '&offset=' + offset

                this.loader = this.$loading.show()

                this.$http.get(url).then(response => {
                    this.recipients = response.data.results
                    this.pagesCount = Math.ceil(response.data.count / this.recipientsOnPage)
                    this.loader.hide()
                });
            },

            removeRecipient(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                let data = {'contact_id': this.recipients[index].user_id}

                let url = 'mail-lists/' + this.listId + '/remove_recipient/'

                this.$http.post(url, data).then(response => {
                    this.recipients.splice(index, 1)
                    this.loader.hide()
                });
            },

            setReply(index) {
                this.loader = this.$loading.show()

                this.$http.post('recipients/' + this.recipients[index].id + '/set_reply/').then(response => {
                    this.recipients[index].is_reply = !this.recipients[index].is_reply
                    this.loader.hide()
                });
            },

            setNegative(index) {
                this.loader = this.$loading.show()

                this.$http.post('recipients/' + this.recipients[index].id + '/set_negative/').then(response => {
                    this.recipients[index].is_negative = !this.recipients[index].is_negative
                    this.loader.hide()
                });
            },

            selectPage(pageNum) {
                this.currentPage = pageNum
                let offset = (pageNum - 1) * this.recipientsOnPage
                this.loadRecipients(offset)
            },
        }
    }
</script>
