<template>

    <div>
        <div @click.prevent="show" class="import-button"><i class="icon upload"></i>Import mail list</div>

        <div id="importCsvWithMailList" class="ui modal">
            <i class="close icon"></i>
            <div class="header">
                Add to mail list
            </div>
            <div class="content">
                <div class="ui form">
                    <div class="field">
                        <b>Column names:</b> website, Domain Rating, Ahrefs Rank, Domains, Total Keywords, Total Traffic, description, email
                    </div>
                    <div class="field">
                        <label>Import mail list</label>
                        <input type="text" v-model="name">
                    </div>
                    <div class="field">
                        <label>File</label>
                        <input type="file" @change="selectFile" name="file" accept=".csv" id="file">
                    </div>

                    <div class="field">
                        <div class="ui toggle checkbox" @click="only20 = !only20">
                            <input type="checkbox" tabindex="0" :checked="only20" class="hidden">
                            <label>only DA 20+</label>
                        </div>
                    </div>

                    <div class="field" v-if="error && name === ''">Mail list name required</div>
                    <div class="field" v-if="error && file === null">File required</div>
                </div>
            </div>
            <div class="actions">
                <div class="ui black deny button">
                    Cancel
                </div>
                <div class="ui right labeled icon positive button" @click.stop="createMailList()">
                    Save
                    <i class="checkmark icon"></i>
                </div>
            </div>
            <loading :is-full-page="false" :active.sync="isLoading"></loading>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isLoading: false,
                error: false,
                name: '',
                only20: true,
                file: null
            };
        },

        methods: {
            show()
            {
                $('#importCsvWithMailList').modal('show');
            },

            selectFile(e) {
                this.file = e.target.files[0];
            },

            createMailList()
            {
                if (this.name === '' || this.file === '')
                {
                    this.error = true
                    return
                }

                this.error = false

                this.isLoading = true

                const config = {
                    headers: {'content-type': 'multipart/form-data'}
                }

                let formData = new FormData()
                formData.append('name', this.name)
                formData.append('file', this.file)

                if (!this.only20)
                    formData.append('rating', '0')

                this.$http.post('upload-with-mail-list/', formData, config).then(response => {
                    this.totalUnique = response.data.total_unique
                    this.totalImported = response.data.total_imported
                    this.totalDoubles = response.data.total_doubles

                    document.getElementById('file').value = ''
                    this.error = false

                    $('#importCsvWithMailList').modal('hide')
                }).catch((error) => {
                    console.log(error)
                }).then(response => {
                    this.isLoading = false
                })
            },
        }
    }
</script>
