<template>
    <div class="ui button">
        <input type="file" name="file" accept=".csv" id="file" style="display: none" @change="importFile">
        <label for="file" class="import-button"><i class="icon download"></i>
            Import dictionary data
        </label>
    </div>
</template>

<script>

    export default {

        data() {
            return {
                loader: false,
            };
        },

        methods: {
            importFile(e)
            {
                let file = e.target.files[0];

                const config = {
                    headers: {'content-type': 'multipart/form-data'}
                }

                let formData = new FormData();
                formData.append('file', file)

                this.loader = this.$loading.show()

                this.$http.post('dictionaries/', formData, config).then(response => {
                    this.loader.hide()
                    console.log(response)
                }).catch(error => {
                    console.log(error.response.data)
                }).then(response => {
                    this.loader.hide()

                    document.getElementById('file').value = ''
                    this.error = false
                })
            }
        }
    }
</script>
