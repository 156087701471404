<template>
    <select class="ui fluid dropdown" @change="changed" v-model="selected">
        <option value="0" disabled selected>Select email</option>
        <option v-for="contact in contacts" :disabled="!contact.is_valid" v-bind:value="contact.id" v-bind:key="contact.id">
            {{ contact.email }}
        </option>
    </select>
</template>

<script>
    export default {
        props: ['domain', 'populate'],

        data() {
            return {
                domainId: null,
                contacts: [],
                selected: null
            };
        },

        mounted()
        {
            this.domainId = this.domain
            this.loadContacts()
        },

        updated()
        {
          //$('.dropdown').dropdown()
        },

        watch: {
            domain: function (val) {
                this.domainId = val
            },

            populate: function (val) {
                if (val === true)
                    this.loadContacts()
            }
        },

        methods: {
            loadContacts()
            {
                if (!this.domainId)
                    return

                this.$http.get('domains/' + this.domainId + '/contacts/').then(response => {
                    this.contacts = response.data

                    for (let i = 0; i < this.contacts.length; i++)
                    {
                        let contact = this.contacts[i]

                        let str = this.contacts[i].email
                        let name = ''

                        if (contact.first_name_!= '')
                            name += contact.first_name

                        if (contact.last_name != '')
                            name += ' ' + contact.last_name

                        if (name != '')
                            str += ' | ' + name

                        if (contact.position != '')
                            str = str + ' | ' + contact.position

                        if (contact.phone != '')
                            str = str + ' | ' + contact.phone

                        if (contact.is_expected_email === true)
                            str += ' (CREATED email!)'
                        else {
                            if (contact.type === 'legacy')
                                str += ' (MATCHED email)'
                            else
                                str += ' (confidence: ' + contact.confidence + ')'
                        }

                        str += ' (' + contact.type + ')'

                        this.contacts[i].email = str
                    }

                    this.$emit('loaded')
                });
            },

            changed() {
                let contact = this.contacts.find(item => item.id === this.selected);
                this.$emit('selected', contact)
            },
        }
    }
</script>
