<template>
    <div class="ui container">

        <h1 class="ui header">Articles</h1>

        <table class="ui celled table">
            <thead>
            <tr>
                <th>Title</th>
                <th>Category</th>
                <th>Created At</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(article, index) in articles" :key="article.id">
                <td><a :href="article.url" target="_blank">{{ article.title }}</a></td>
                <td>{{ article.category }}</td>
                <td>{{ article.created_at }}</td>
                <td>
                    <div class="ui small basic icon buttons">
                        <button class="ui button" @click="showEditModal(index)" data-content="Edit"><i class="edit icon"></i></button>
                        <button class="ui button" @click="dismiss(index)" data-content="Dismiss"><i class="trash icon"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>


        <EditArticle ref="editArticleModal" @saved="savedArticle"></EditArticle>
    </div>
</template>

<script>

    import EditArticle from '@/components/EditArticle'

    export default {

        components: {
            EditArticle,
        },


        data() {
            return {
                loader: null,
                articles: [],
                selectedArticleIndex: null
            };
        },

        mounted() {

            this.loader = this.$loading.show()

            this.$http.get('articles/').then(response => {
                this.articles = response.data.results
                this.loader.hide()

            });
        },

        updated() {
           $('.button').popup();
        },

        methods: {

            showEditModal(index)
            {
                this.selectedArticleIndex = index
                this.$refs.editArticleModal.show();
                this.$refs.editArticleModal.article = Object.assign({}, this.articles[index])
            },

            dismiss(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                this.$http.post('articles/' + this.articles[index].id + '/dismiss/').then(response => {
                    this.articles.splice(index, 1)
                    this.loader.hide()
                });
            },

            savedArticle(article)
            {
                this.$set(this.articles, this.selectedArticleIndex, article)
                this.selectedArticleIndex = null
            },

        }
    }
</script>
