<template>
    <sui-modal v-model="open" :closeIcon="true">

        <sui-modal-header>Add Expense</sui-modal-header>

        <sui-modal-content >
            <div class="ui grid form">

                <div v-if="project_id == null" class="eight wide column">
                    <div :class="(parentProject === null && error) ? 'field error' : 'field'">
                        <label>Select main project</label>
                        <select v-if="projects" class="ui fluid dropdown" v-model="parentProject">
                            <option value="null" disabled></option>
                            <option v-for="project in projects"
                                    v-bind:value="project.id"
                                    v-bind:key="project.id">{{ project.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div v-if="project_id == null" class="eight wide column">
                    <div v-if="subProjects" :class="(expense.project_id === '' && error) ? 'field error' : 'field'">
                        <label>Select project</label>
                        <select class="ui fluid dropdown" v-model="expense.project_id">
                            <option value="null" disabled></option>
                            <option v-for="project in subProjects"
                                    v-bind:value="project.id"
                                    v-bind:key="project.id">{{ project.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="eight wide column">
                    <div :class="(expense.name === '' && error) ? 'field error' : 'field'">
                        <label>Name</label>
                        <input type="text" v-model="expense.name">
                    </div>
                </div>

                <div class="eight wide column">
                    <div :class="(expense.amount === '' && error) ? 'field error' : 'field'">
                        <label>Amount</label>
                        <input type="number" v-model="expense.amount">
                    </div>
                </div>

                <div class="eight wide column">
                    <div class="field">
                        <label>Select type</label>
                        <select class="ui fluid dropdown" v-model="expense.type">
                            <option v-for="type in types" v-bind:value="type" v-bind:key="type">{{ type }}</option>
                        </select>
                    </div>
                </div>

                <div class="eight wide column">
                    <div class="field">
                        <label>&nbsp;</label>
                        <div class="ui toggle checkbox" @click="expense.is_paid = !expense.is_paid">
                            <input type="checkbox" tabindex="0" :checked="expense.is_paid" class="hidden">
                            <label>Is paid &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>
                        <div class="ui toggle checkbox" @click="expense.is_recurring = !expense.is_recurring">
                            <input type="checkbox" tabindex="0" :checked="expense.is_recurring" class="hidden">
                            <label>Is recurring</label>
                        </div>
                    </div>
                </div>

                <div class="sixteen wide column">
                    <div class="field">
                        <label>Artifacts</label>
                        <textarea rows="3" v-model="expense.artifacts"></textarea>
                    </div>
                </div>
            </div>
        </sui-modal-content>

        <sui-modal-actions>
            <div class="ui black deny button" @click="open=false">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click="saveExpense">
                Save<i class="checkmark icon"></i>
            </div>
        </sui-modal-actions>
    </sui-modal>
</template>

<script>

    export default {


        props: ['project_id'],

        data() {
            return {
                open: false,
                campaign: null,
                name: '',
                parentProject: null,
                loader: null,
                error: false,

                expense:
                {
                    id: null,
                    name: '',
                    amount: '',
                    artifacts: '',
                    project_id: '',
                    type: 'code',
                    is_paid: false,
                    is_recurring: false,
                },

                projects: null,
                subProjects: null,

                types: [
                    'code',
                    'design',
                    'marketing',
                    'c-management',
                    'back office',
                    'other'
                ]
            };
        },

        mounted() {

            this.loader = this.$loading.show()

            this.$http.get('finance/projects/').then(response => {
                this.projects = response.data
                this.loader.hide()
            });
        },

        watch: {
            parentProject: function (val) {
                this.$http.get('finance/projects/?parent_id=' + val).then(response => {
                    this.subProjects = response.data
                    this.loader.hide()
                });
            },
        },

        methods: {
            show()
            {
                this.error = false
                this.open = true

                this.parentProject = null

                this.subProjects = null

                this.expense = {
                    id: null,
                    name: '',
                    amount: '',
                    artifacts: '',
                    project_id: this.project_id,
                    type: 'code',
                    is_paid: false,
                    is_recurring: false,
                }
            },

            saveExpense()
            {
                if (!this.expense.name || !this.expense.amount || !this.expense.project_id)
                {
                    this.error = true
                    return
                }

                if (this.project_id == null && this.parentProject === null)
                {
                    this.error = true
                    return
                }

                this.error = false


                if (this.expense.id)
                    this.updateExpense()
                else
                    this.createExpense()

            },

            createExpense(){

                this.loader = this.$loading.show()

                this.$http.post('finance/expenses/', this.expense).then(response => {
                    this.$emit('created', response.data)
                    this.open = false

                }).catch(error => {
                    console.log(this.errors)

                }).then(response => {
                    this.loader.hide()
                })
            },

            updateExpense(){

                this.loader = this.$loading.show()

                this.$http.patch('finance/expenses/' + this.expense.id + '/', this.expense).then(response => {
                    this.$emit('updated', response.data)
                    this.open = false

                }).catch(error => {
                    console.log(this.errors)

                }).then(response => {
                    this.loader.hide()
                })
            }

        }
    }
</script>
