<template>
    <sui-modal v-model="open" size="large" :closeIcon="true" v-if="subjectLine">

        <sui-modal-header v-if="!subjectLine.id">Add subject line</sui-modal-header>
        <sui-modal-header v-else>Edit subject [{{ subjectLine.name }}]</sui-modal-header>

        <sui-modal-content scrolling>
            <div class="ui form">
                <div :class="!subjectLine.name && error ? 'field error' : 'field'">
                    <label>Name (used only inside admin panel)</label>
                    <input type="text" v-model="subjectLine.name">
                </div>

                <div :class="!subjectLine.subject && error ? 'field error' : 'field'">
                    <label>Subject (subject line of the email)</label>
                    <input type="text" v-model="subjectLine.subject">
                </div>

                <div class="field">
                    <label>Outreach/followback</label>
                    <select class="ui fluid dropdown" v-model="subjectLine.type">
                        <option value="1">Outreach</option>
                        <option value="2">Follow up</option>
                    </select>
                </div>
                
                <div class="field">
                    <label>Include receipients' name in subject</label>
                    <div class="ui toggle checkbox" @click="togglePersonalName()">
                        <input 
                          type="checkbox"
                          tabindex="0"
                          class="hidden"
                          :checked="subjectLine.use_name_in_subject"
                          >
                        <label></label>
                    </div>
                </div>

                <div class="field">
                    <label>Include receipients' website URL in subject</label>
                    <div class="ui toggle checkbox" @click="toggleWebsiteUrl()">
                        <input 
                          type="checkbox"
                          tabindex="0"
                          class="hidden"
                          :checked="subjectLine.use_websiteurl_in_subject"
                          >
                        <label></label>
                    </div>
                </div>

            </div>
        </sui-modal-content>

        <sui-modal-actions>
            <div class="ui grid">
                <div class="twelve wide column">
                </div>
                <div class="four wide column right aligned">
                    <div class="ui black deny button" @click="open=false">
                        Cancel
                    </div>
                    <div class="ui right labeled icon positive button" @click="saveSubjectline">
                        Save<i class="checkmark icon"></i>
                    </div>
                </div>
            </div>
        </sui-modal-actions>
    </sui-modal>
</template>

<script>

    import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

    import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
    import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
    import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
    import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
    import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
    import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';

    export default {

        data() {
            return {
                open: false,

                subjectLine: null,

                defaultParams: {
                    name: '',
                    subject: '',
                    use_name_in_subject: 1,
                    use_websiteurl_in_subject: 1,
                    type: 1,
                },

                loader: null,
                error: false,
                textError: false,

            };
        },

        mounted() {
            this.subjectLine = this.defaultParams
            console.clear()
        },

        methods: {
            show()
            {
                this.subjectLine = Object.assign({}, this.defaultParams)
                this.error = false

                this.open = true
            },

            saveSubjectline()
            {
                if (this.subjectLine.id) {
                    this.updateSubjectline()
                } else {
                    this.createSubjectline()
                }
            },

            createSubjectline()
            {

                if (!this.subjectLine.name)
                {
                    this.error = true
                    return
                }

                this.error = false

                this.loader = this.$loading.show()

                this.$http.post('v2-subjectlines', this.subjectLine).then(response => {
                    this.$emit('created', response.data)
                    this.open = false
                }).catch(error => {
                  // 
                }).then(response => {
                   this.$parent.getSubjectLines()
                   this.loader.hide()
                })
            },

            updateSubjectline()
            {
                this.loader = this.$loading.show()

                this.textError = false

                this.$http.patch('v2-subjectlines/' + this.subjectLine.id, this.subjectLine).then(response => {
                    this.$emit('updated', response.data)
                    this.open = false

                }).catch((error) => {
                    // 
                }).then(response => {
                    this.$parent.getSubjectLines()
                    this.loader.hide()
                })
            },

            togglePersonalName() {
               this.subjectLine.use_name_in_subject = Number( ! this.subjectLine.use_name_in_subject) 
            },

            toggleWebsiteUrl() {
               this.subjectLine.use_websiteurl_in_subject = Number( ! this.subjectLine.use_websiteurl_in_subject) 
            },
        },
    }
</script>
