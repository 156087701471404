<template>
    <div class="ui container">

        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">Import Files</h1>
            </div>
            <div class="column right aligned">
                <ImportDAEnrichmentFile></ImportDAEnrichmentFile>
            </div>
        </div>

        <table class="ui celled table" v-if="files.length > 0">
            <thead>
            <tr>
                <th>File</th>
                <th>Total rows</th>
                <th>Parsed rows</th>
                <th>Inserted</th>
                <th>Updated</th>
                <th>Status</th>
                <th>Message</th>
                <th>Created at</th>
                <th>Finished at</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(file, index) in files" :key="file.id" :class="(file.status === 'in progress') ? 'positive' : ''">
                <td>{{ file.file_name }}</td>
                <td>{{ file.total_rows }}</td>
                <td>{{ file.parsed_rows }} ({{ calculatePercent(file.parsed_rows, file.total_rows) }}%)</td>
                <td>{{ file.inserted }}</td>
                <td>{{ file.updated }}</td>
                <td>
                    <span v-if="file.status === ''">in queue</span>
                    <span v-else>{{ file.status }}</span>
                </td>
                <td>{{ file.message }}</td>
                <td>{{ file.created_at }}</td>
                <td>{{ file.finished_at }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    import ImportDAEnrichmentFile from '@/components/ImportDAEnrichmentFile'

    export default {

        components: {
            ImportDAEnrichmentFile
        },


        data() {
            return {
                loader: null,
                files: [],
            };
        },

        mounted() {
            this.loadFiles()
            setInterval(this.reloadFiles, 1000);
        },

        methods: {

            loadFiles()
            {
                this.loader = this.$loading.show()

                this.$http.get('import-files/').then(response => {
                    this.files = response.data
                    this.loader.hide()
                });
            },

            reloadFiles()
            {
                this.$http.get('import-files/').then(response => {
                    this.files = response.data
                });
            },

            calculatePercent(val, total)
            {
                if (total == 0)
                    return (0).toFixed(2)

                val = parseFloat(val)
                total = parseFloat(total)

                return (val * 100 / total).toFixed(2)
            }
        },


    }
</script>
