<script>
    import {HorizontalBar, mixins} from 'vue-chartjs'

    const {reactiveProp} = mixins

    export default {
        extends: HorizontalBar,
        mixins: [reactiveProp],

        data() {
            return {
                options: {

                    maintainAspectRatio: false,

                    scales: {
						xAxes: [{
							stacked: true,
                            /*
                            type: 'logarithmic',
                            ticks: {
                                 min: 1, //minimum tick
                                 max: 3000000, //maximum tick
                            },
                            */
						}],
						yAxes: [{
							stacked: true
						}]
					},
                }
            };
        },


        mounted() {
            // this.chartData is created in the mixin.
            // If you want to pass options please create a local options object
            this.renderChart(this.chartData, 'horizontalBar', this.options)
        }
    }
</script>