<template>
    <div class="ui container">
      <div class="ui grid two column row">
          <div class="column left aligned">
              <h1 class="ui header">V2 Subject lines</h1>
          </div>
          <div class="column right aligned">
              <button class="ui button" @click="showAddModal"><i class="plus icon"></i>Add subject line</button>
          </div>
        </div>
          <!--  subjects table goes here  -->
          <table class="ui celled table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Subjectline</th>
                  <th>Use name in subject</th>
                  <th>Use website url in subject</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(subjectline, index) in subjectLines" :key="subjectline.id">
                  <td>{{ subjectline.name }}</td>
                  <td>{{ templateTypes[subjectline.type] }}</td>
                  <td>{{ subjectline.subject }}</td>
                  <td>{{ nameInSubject[subjectline.use_name_in_subject] }}</td>
                  <td>{{ websiteUrlInSubject[subjectline.use_websiteurl_in_subject] }}</td>
                  <td>
                    <div class="ui small basic icon buttons">
                      <button class="ui button" @click="showEditModal(index)" data-content="Edit"><i class="edit icon"></i></button>
                      <button class="ui button" @click="deleteSubjectline(index)" data-content="Delete"><i class="trash icon"></i></button>
                    </div>
                  </td>
                </tr>
              </tbody>
          </table>
          <SubjectModal ref="subjectModal" @created="createdSubjectline" @updated="updatedSubjectline"></SubjectModal >
    </div>
</template>

<script>

    import SubjectModal from '@/components/V2SubjectModal'

    export default {

        components: {
            SubjectModal,
        },

        data() {
            return {
                loader: null,
                subjectLines: [],
                selectedIndex: null,
                nameInSubject: {
                    0: 'Do not use',
                    1: 'Use',
                },
                websiteUrlInSubject: {
                    0: 'Do not use',
                    1: 'Use',
                },
                templateTypes: {
                    1: 'Outreach',
                    2: 'Follow up',
                }
            };
        },

        mounted() {
          this.loader = this.$loading.show()
          this.getSubjectLines()
        },

        updated() {
          // this.getSubjectLines()
        },

        methods: {
          getSubjectLines() {
            this.$http.get('v2-subjectlines').then(response => {
                this.subjectLines = response.data
                this.$forceUpdate() // otherwise the array doesn't get updated and UI table stays empty
                this.loader.hide()
            });
          },
          showAddModal() {
              this.$refs.subjectModal.show();
          },
          showEditModal(index) {
              this.selectedIndex = index
              this.$refs.subjectModal.show();
              this.$refs.subjectModal.subjectLine = Object.assign({}, this.subjectLines[index])
          },
          updatedSubjectline () {
            //
          },
          createdSubjectline () {
            //
          },
          deleteSubjectline (index) {
                // Confirmation of delete
                let confirmResult = confirm("Do you want to delete [" + this.subjectLines[index].name + "]");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                this.$http.delete('v2-subjectlines/' + this.subjectLines[index].id).then(response => {
                    this.subjectLines.splice(index, 1)
                    this.loader.hide()
                });
          },
        }
    }
</script>
