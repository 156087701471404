<script>
    import {HorizontalBar, mixins} from 'vue-chartjs'

    const {reactiveProp} = mixins

    export default {
        extends: HorizontalBar,
        mixins: [reactiveProp],

        data() {
            return {
                options: {
                    scales: {
						xAxes: [{
                            ticks: {
                                 min: 0, //minimum tick
                                 max: 100, //maximum tick
                            },
						}],
					},
                }
            };
        },


        mounted() {
            // this.chartData is created in the mixin.
            // If you want to pass options please create a local options object
            this.renderChart(this.chartData, 'horizontalBar', this.options)
        }
    }
</script>