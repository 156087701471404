<template>
    <div>
        <div class="ui three column grid">
            <div class="column">
                <div class="ui form">
                    <div class="inline field">
                        <label>Select campaign</label>
                        <select v-if="campaigns" class="ui fluid dropdown" v-model="selectedCampaignId">
                            <option v-for="campaign in campaigns"
                                    v-bind:value="campaign.id"
                                    v-bind:key="campaign.id">{{ campaign.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="column">
                <h1 class="ui header">Opportunities</h1>
            </div>
            <div class="column">
                <div class="ui form" v-if="mailLists">
                    <div class="inline field">
                        <label>Select mail list</label>
                        <select class="ui fluid dropdown" v-model="selectedMailListId">
                            <option value="" selected>All</option>
                            <option v-for="list in mailLists"
                                    v-bind:value="list.id"
                                    v-bind:key="list.id">{{ list.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="selectedCampaignId !== null" class="ui secondary pointing menu">
            <a :class="filter === 'todo' ? 'item active' : 'item'" @click="filter='todo'">
                TODO ({{ todoCount }})
            </a>
            <a :class="filter === 'rejected' ? 'item active' : 'item'" @click="filter='rejected'">
                Rejected ({{ rejectedCount }})
            </a>
            <a :class="filter === 'call_later' ? 'item active' : 'item'" @click="filter='call_later'">
                Call later ({{ callLaterCount }})
            </a>
            <a :class="filter === 'positive' ? 'item active' : 'item'" @click="filter='positive'">
                Positive ({{ positiveCount }})
            </a>
            <div class="right menu">
                <div class="item inline field">
                    <div class="ui toggle checkbox" @click="timeFilter = !timeFilter">
                        <input type="checkbox" tabindex="0" :checked="timeFilter" class="hidden">
                        <label>Working hours</label>
                    </div>
                </div>
                <div class="item inline field">
                    <div class="ui toggle checkbox" @click="isOpened = !isOpened">
                        <input type="checkbox" tabindex="0" :checked="isOpened" class="hidden">
                        <label>Is mail opened&nbsp;&nbsp;&nbsp;</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="ui text container">
            <paginate v-if="pagesCount > 1"
                      v-model="currentPage"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>

        <table v-if="opportunities" class="ui celled table">
            <thead>
            <tr>
                <th>Website</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Phone type</th>
                <th>Additional phones</th>
                <th>Name</th>
                <th>Follow up send at</th>
                <th>Timezone</th>
                <th>Send</th>
                <th v-if="filter === 'call_later'">Date</th>
                <th v-if="filter === 'call_later'">Comment</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(opportunity, index) in opportunities" v-if="showRow(opportunity)" v-bind:key="opportunity.id">
                <td><a :href="'http://' + opportunity.website" target="_blank">{{ opportunity.website }}</a></td>
                <td>{{ opportunity.email }}</td>
                <td>{{ opportunity.phone }}</td>
                <td>{{ opportunity.phone_type }}</td>
                <td>
                    <span v-for="(phone, phoneIndex) in opportunity.additional_phones" v-bind:key="phone.id">
                        {{ phone.number }}<span v-if="phoneIndex+1 != opportunity.additional_phones.length">, </span>
                    </span>
                </td>
                <td>{{ opportunity.first_name + ' ' + opportunity.last_name}}</td>
                <td>{{ opportunity.follow_up_send_at }}</td>
                <td>{{ opportunity.timezone_time }}</td>
                <td>
                    <span v-if="opportunity.mail_created && !opportunity.mail_send">Waiting</span>
                    <span v-else-if="opportunity.mail_created && opportunity.mail_send">True</span>
                    <span v-else>False</span>
                </td>
                <td v-if="filter === 'call_later'">{{ opportunity.call_later_date | moment("DD.MM.YYYY") }}</td>
                <td v-if="filter === 'call_later'">{{ opportunity.call_later_comment }}</td>
                <td>
                    <div class="ui small basic icon buttons action-buttons">
                        <!--<button class="ui button" @click="showMessageModal(index)" data-content="Send message"><i class="mail icon"></i></button>-->
                        <button class="ui button" @click="showCallLaterModal(index)" data-content="Call later"><i class="phone icon"></i></button>
                        <button class="ui button" @click="setPositive(index)" data-content="Positive"><i class="thumbs up icon"></i></button>
                        <button class="ui button" @click="showDismissModal(index)" data-content="Reject"><i class="thumbs down icon"></i></button>
                        <button v-if="filter !== 'todo'" class="ui button" @click="returnToTodo(index)" data-content="Return to TODO"><i class="delete icon"></i></button>
                        <!--<button v-if="$user.isAdmin" class="ui button" @click="showEstablishedModal(index)" data-content="Add to established"><i class="handshake icon"></i></button>-->
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <div class="ui text container">
            <paginate v-if="pagesCount > 1"
                      v-model="currentPage"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>

        <SendEmailToOpportunityModal @mailCreated="mailCreated" ref="sendEmailToOpportunityModal" />
        <DismissOpportunityModal @dismissed="dismissed" ref="dismissOpportunityModal" />
        <DomainEstablishedModal ref="domainEstablishedModal" :domainId="domainId" @saved="savedEstablished"></DomainEstablishedModal>
        <CallLaterModal ref="callLaterModal" @saved="savedCallLater"></CallLaterModal>
    </div>
</template>

<script>
    import MailListsSelect from '@/components/MailListsSelect'
    import SendEmailToOpportunityModal from '@/components/SendEmailToOpportunityModal'
    import DismissOpportunityModal from '@/components/DismissOpportunityModal'
    import DomainEstablishedModal from '@/components/DomainEstablishedModal'
    import CallLaterModal from '@/components/CallLaterModal'

    export default {

        components: {
            MailListsSelect,
            SendEmailToOpportunityModal,
            DismissOpportunityModal,
            DomainEstablishedModal,
            CallLaterModal
        },

        data() {
            return {
                domainId: null,
                loader: null,
                filter: 'todo',
                selectedIndex: null,
                selectedCampaignId: null,
                selectedMailListId: '',
                campaigns: null,
                mailLists: null,
                opportunities: null,
                isOpened: false,
                timeFilter: false,
                domainsOnPage: 100,
                pagesCount: 0,
                currentPage: 1,

                todoCount: 0,
                callLaterCount: 0,
                positiveCount: 0,
                rejectedCount: 0,


            };
        },

        mounted() {
            this.loadCampaigns()
        },

        updated() {
            // Semantic popup
            $('.button').popup();
        },

        watch: {
            selectedCampaignId: function (val) {
                this.loadMailLists()
                this.loadCounts()
                this.loadOpportunities()

            },

            selectedMailListId: function (val) {
                this.loadCounts()
                this.loadOpportunities()
            },

            filter: function (val) {
                this.loadOpportunities()
            },

            isOpened: function (val) {
                this.loadOpportunities()
            },
        },

        methods: {

            loadCampaigns()
            {
                this.$http.get('campaigns').then(response => {
                    this.campaigns = response.data
                })
            },

            loadMailLists()
            {
                this.$http.get('mail-lists/' + '?campaign_id=' + this.selectedCampaignId).then(response => {
                    this.mailLists = response.data.results
                });
            },

            loadCounts()
            {
                let url = 'opportunities/counts/'

                if (this.selectedCampaignId !== null)
                    url += '?campaign_id=' + this.selectedCampaignId

                if (this.selectedMailListId !== '')
                    url += '&mail_list_id=' + this.selectedMailListId

                this.$http.get(url).then(response => {

                    this.todoCount = response.data.todo_count
                    this.callLaterCount = response.data.call_later_count
                    this.positiveCount = response.data.positive_count
                    this.rejectedCount = response.data.rejected_count
                });
            },

            loadOpportunities(offset = 0)
            {
                this.loader = this.$loading.show()

                // paging
                let url = 'opportunities/?limit=' + this.domainsOnPage + '&offset=' + offset

                if (this.selectedCampaignId !== null)
                    url += '&campaign_id=' + this.selectedCampaignId

                if (this.selectedMailListId !== '')
                    url += '&mail_list_id=' + this.selectedMailListId

                if (this.filter !== 'todo')
                    url += '&filter=' + this.filter

                if (this.isOpened)
                    url += '&is_opened=1'

                this.$http.get(url).then(response => {
                    this.opportunities = response.data.results
                    this.pagesCount = Math.ceil(response.data.count / this.domainsOnPage)
                    this.loader.hide()
                });
            },

            selectPage(pageNum) {
                this.currentPage = pageNum
                let offset = (pageNum - 1) * 100
                this.loadOpportunities(offset)
            },

            showMessageModal(index)
            {
                this.$refs.sendEmailToOpportunityModal.domainId = this.opportunities[index].domain_id
                this.$refs.sendEmailToOpportunityModal.opportunityId = this.opportunities[index].recipient_id
                this.$refs.sendEmailToOpportunityModal.show()
            },

            mailCreated(opportunity)
            {
                let opportunityObj = this.opportunities.find(item => item.id === opportunity.id);
                opportunityObj.mail_created = opportunity.mail_created
                opportunityObj.mail_send = opportunity.mail_send
                this.selectedIndex = null
            },

            showDismissModal(index)
            {
                this.selectedIndex = index
                this.$refs.dismissOpportunityModal.domainId = this.opportunities[index].domain_id
                this.$refs.dismissOpportunityModal.opportunityId = this.opportunities[index].recipient_id
                this.$refs.dismissOpportunityModal.show()
            },

            dismissed(opportunityId)
            {
                this.opportunities.splice(this.selectedIndex, 1)
                this.selectedIndex = null

                this.rejectedCount += 1

                if (this.filter === 'todo')
                    this.todoCount -= 1
                else if (this.filter === 'positive')
                    this.positiveCount -= 1
                else if (this.filter === 'call_later')
                    this.callLaterCount -= 1
            },

            showEstablishedModal(index) {
                this.selectedIndex = index
                this.domainId = this.opportunities[index].domain_id
                this.$refs.domainEstablishedModal.show()
            },

            savedEstablished(domain)
            {
                this.opportunities.splice(this.selectedIndex, 1)
                this.selectedIndex = null
            },

            showCallLaterModal(index){
                this.selectedIndex = index
                this.$refs.callLaterModal.show()
                this.$refs.callLaterModal.opportunity = Object.assign({}, this.opportunities[index])
            },

            savedCallLater(opportunity)
            {
                this.opportunities.splice(this.selectedIndex, 1)
                this.selectedIndex = null

                this.callLaterCount += 1

                if (this.filter === 'todo')
                    this.todoCount -= 1
                else if (this.filter === 'rejected')
                    this.rejectedCount -= 1
                else if (this.filter === 'positive')
                    this.positiveCount -= 1

            },

            setPositive(index)
            {
                this.loader = this.$loading.show()

                let recipient_id = this.opportunities[index].recipient_id

                let data = {
                    'call_later': false,
                    'is_dismissed': false,
                    'is_positive': true,
                }

                this.$http.patch('opportunities/' + recipient_id + '/', data).then(response => {
                    this.opportunities.splice(this.selectedIndex, 1)
                    this.selectedIndex = null

                    this.positiveCount += 1

                    if (this.filter === 'todo')
                        this.todoCount -= 1
                    else if (this.filter === 'rejected')
                        this.rejectedCount -= 1
                    else if (this.filter === 'call_later')
                        this.callLaterCount -= 1

                }).catch((response) => {
                    console.log(response)

                }).then(response => {
                    this.loader.hide()
                })
            },

            returnToTodo(index)
            {
                this.loader = this.$loading.show()

                let recipient_id = this.opportunities[index].recipient_id

                let data = {
                    'call_later': false,
                    'is_dismissed': false,
                    'is_positive': false,
                }

                this.$http.patch('opportunities/' + recipient_id + '/', data).then(response => {
                    this.opportunities.splice(this.selectedIndex, 1)
                    this.selectedIndex = null

                    this.todoCount += 1

                    if (this.filter === 'positive')
                        this.positiveCount -= 1
                    else if (this.filter === 'rejected')
                        this.rejectedCount -= 1
                    else if (this.filter === 'call_later')
                        this.callLaterCount -= 1

                }).catch((response) => {
                    console.log(response)

                }).then(response => {
                    this.loader.hide()
                })
            },

            showRow(opportunity)
            {
                if (opportunity.timezone_time === '' || this.timeFilter === false)
                    return true
                else
                {
                    if (this.getHour(opportunity.timezone_time) >= 9 && this.getHour(opportunity.timezone_time) < 17)
                        return true
                    else
                        return false
                }

            },

            getHour(time)
            {
                let regexp = /([0-9]+):/gi
                let match = regexp.exec(time)

                return parseInt(match[1])
            }
        }
    }
</script>
