<template>
    <div id="editModal" class="ui modal">
        <i class="close icon"></i>
        <div class="header">
            Add domain
        </div>
        <div class="content">
            <form class="ui form">
                <div class="field">
                    <label>Domain</label>
                    <input type="text" v-model="domain.website_location">
                </div>
                <div class="field">
                    <label>Domain rating</label>
                    <input type="text" v-model="domain.domain_rating">
                </div>
                <div class="field">
                    <label>Context</label>
                    <textarea v-model="domain.establish_context" rows="2"></textarea>
                </div>
                <div class="field">
                    <label>Free</label>
                    <div class="ui toggle checkbox" @click="setSetIsFree()">
                        <input type="checkbox" tabindex="0" :checked="domain.is_free" class="hidden">
                        <label></label>
                    </div>
                </div>
                <div class="field">
                    <label>Paid</label>
                    <div class="ui toggle checkbox" @click="setSetIsPaid()">
                        <input type="checkbox" tabindex="0" :checked="domain.is_paid" class="hidden">
                        <label></label>
                    </div>
                </div>
            </form> 
        </div>
        <div class="actions">
            <div class="ui black deny button">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click.stop="saveData()">
                Save
                <i class="checkmark icon"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {


        data() {
            return {
                domain: {
                  domain_rating: null,
                  website:  '',
                  establish_context:  '',
                  is_paid: false,
                  is_free: false,
                  is_established: true,

                  ahrefs_rank: null,
                  domains: null,
                  total_keywords: null,
                  total_traffic: null,
                  company: '',
                  similarweb: '',
                  cat: '',
                  cat2: '',
                  robs: '',
                  quantcast: null,
                  alexa: null,
                  twitter: '',
                  facebook: '',
                  linkedin: '',
                  google: '',
                  pinterest: '',
                  github: '',
                  instagram: '',
                  vk: '',
                  youtube: '',
                  city: '',
                  state: '',
                  zip: '',
                  country: '',
                  avg_app_spend: null,
                  number_of_employees: null,
                  year_founded: null,
                  annual_revenue: null,
                  total_money_raised: null,
                  description: '',
                  address: '',
                  timezone: '',
                  is_public_company: '',
                },
            };
        },
        
        mounted() {
        },

        updated() {
          this.domain.website = this.domain.website_location
        },

        methods: {
            show()
            {
                $('#editModal').modal('show');
            },

            saveData() {
                this.$http.post('domains/', this.domain).then(response => {
                    this.$emit('created', this.domain)
                    $('#editModal').modal('hide')
                });
            },
            setSetIsFree(){
                this.domain.is_free = ! this.domain.is_free
            },
            setSetIsPaid(){
                this.domain.is_paid = ! this.domain.is_paid
            },

        }
    }
</script>
