<template>
    <div class="ui container">
        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">
                    <router-link :to="'/finance/'" class="item">Finance</router-link> / <span v-if="project">{{ project.name}}</span>
                </h1>
            </div>
            <div class="column right aligned">
                <button class="ui button" @click="showAddProjectModal"><i class="plus icon"></i>Add project</button>
            </div>
        </div>

        <div class="ui secondary pointing menu">
            <a :class="period === 'month' ? 'item active' : 'item'" @click="period='month'">
                This month
            </a>
            <a :class="period === 'all' ? 'item active' : 'item'" @click="period='all'">
                All time
            </a>
        </div>

        <table class="ui celled table" v-if="projects.length">
            <thead>
            <tr>
                <th>Name</th>
                <th>Due</th>
                <th>Paid</th>
                <th>Profit</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(project, index) in projects" :key="project.id">
                <td><router-link :to="'/finance/projects/' + project.id" class="item">{{ project.name }}</router-link></td>
                <td>{{ project.due_sum }}</td>
                <td>{{ project.paid_sum }}</td>
                <td>{{ project.profit_sum }}</td>
                <td>
                    <div class="ui small basic icon buttons">
                        <button class="ui button" @click="deleteProject(index)" data-content="Delete"><i class="trash icon"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <th><b>Total:</b></th>
                <th><b>{{ dueTotalAmount }}</b></th>
                <th><b>{{ paidTotalAmount }}</b></th>
                <th colspan="2"><b>{{ profitTotalAmount }}</b></th>
            </tr>
            </tfoot>
        </table>

        <ProjectModal ref="projectModal" :parent_id="parentId" @created="createdProject"></ProjectModal>
    </div>
</template>

<script>

    import ProjectModal from '@/components/finance/ProjectModal'
    import ExpenseModal from '@/components/finance/ExpenseModal'

    export default {

        components: {
            ProjectModal,
            ExpenseModal
        },

        data() {
            return {
                parentId: null,
                project: null,
                projects: [],
                period: 'month'
            };
        },

        mounted() {
            this.parentId = this.$route.params.id
            this.loadProject()
            this.loadProjects()
        },

        computed: {
            dueTotalAmount: function () {

                let totalAmount = 0

                for (let key in this.projects)
                    totalAmount += this.projects[key].due_sum

                return totalAmount
            },

            paidTotalAmount: function () {

                let totalAmount = 0

                for (let key in this.projects)
                    totalAmount += this.projects[key].paid_sum

                return totalAmount
            },

            profitTotalAmount: function () {

                let totalAmount = 0

                for (let key in this.projects)
                    totalAmount += this.projects[key].profit_sum

                return totalAmount
            }
        },

        watch: {
            period: function (val) {
                this.loadProjects()
            },
        },

        updated() {
           $('.button').popup();
        },

        methods: {

            loadProject()
            {
                this.$http.get('finance/projects/' + this.parentId + '/').then(response => {
                    this.project = response.data
                });
            },

            loadProjects() {
                this.loader = this.$loading.show()
                this.$http.get('finance/projects/?parent_id=' + this.parentId + '&period=' + this.period).then(response => {
                    this.projects = response.data
                    this.loader.hide()
                });
            },

            showAddProjectModal(index)
            {
                this.$refs.projectModal.show();
            },

            createdProject(project)
            {
                project.due_sum = ''
                project.paid_sum = ''
                project.profit_sum = ''

                this.projects.push(project)
            },

            deleteProject(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                this.$http.delete('finance/projects/' + this.projects[index].id + '/').then(response => {
                    this.projects.splice(index, 1)
                    this.loader.hide()
                });
            },
        }
    }
</script>
