<template>
    <div>
        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">Mailboxes</h1>
            </div>
            <div class="column right aligned">
                <button class="ui button" @click="showAddModal"><i class="plus icon"></i>Add mailbox</button>
            </div>
        </div>

        <table class="ui celled table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Day limit</th>
                <th>Assigned to campaigns</th>
                <th>Scheduled emails</th>
                <th>Days to send</th>
                <th>Send today</th>
                <th>Send in 7 days</th>
                <th>Send in 30 days</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(mailbox, index) in mailboxes" :key="mailbox.id">
                <td>{{ mailbox.name }}</td>
                <td>{{ mailbox.email }}</td>
                <td>{{ mailbox.day_limit }}</td>
                <td>
                  <div class="ui list">
                    <div class="item" v-for="campaign in mailbox.campaigns">
                        {{ campaign }}
                    </div>
                  </div>
                </td>
                <td>{{ mailbox.scheduled_emails }}</td>
                <td>{{ mailbox.days_to_send }}</td>
                <td>{{ mailbox.send_today_override }}</td>
                <td>{{ mailbox.send_in_7_days }}</td>
                <td>{{ mailbox.send_in_30_days }}</td>

                <td>
                    <div class="ui small basic icon buttons">
                        <button class="ui button" @click="showEditModal(index)" data-content="Edit"><i class="edit icon"></i></button>
                        <button class="ui button" @click="deleteMailbox(index)" data-content="Delete"><i class="trash icon"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <MailboxModal ref="mailboxModal" @created="createdMailbox" @updated="updatedMailbox"></MailboxModal>
    </div>
</template>

<script>

    import MailboxModal from '@/components/MailboxModal'

    export default {

        components: {
            MailboxModal,
        },

        data() {
            return {
                loader: null,
                mailboxes: [],
                selectedIndex: null,
            };
        },

        mounted() {

            this.loader = this.$loading.show()

            this.$http.get('mailboxes/').then(response => {
                this.mailboxes = response.data
                this.loader.hide()

            });
        },

        updated() {
           $('.button').popup();
        },

        methods: {

            showAddModal(index)
            {
                this.$refs.mailboxModal.show();
            },

            showEditModal(index)
            {
                this.selectedIndex = index
                this.$refs.mailboxModal.show();
                this.$refs.mailboxModal.mailbox = Object.assign({}, this.mailboxes[index])
            },

            createdMailbox(mailbox)
            {
                this.mailboxes.unshift(mailbox)
            },

            updatedMailbox(mailbox_)
            {
                let mailbox = this.mailboxes[this.selectedIndex]

                mailbox.name = mailbox_.name
                mailbox.email = mailbox_.email
                mailbox.day_limit = mailbox_.day_limit
                mailbox.signature = mailbox_.signature

                this.selectedIndex = null
            },

            deleteMailbox(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                this.$http.delete('mailboxes/' + this.mailboxes[index].id + '/').then(response => {
                    this.mailboxes.splice(index, 1)
                    this.loader.hide()
                });
            },
        }
    }
</script>
