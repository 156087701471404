<template>
    <div id="establishedModal" class="ui modal">
        <i class="close icon"></i>
        <div class="header" v-if="domain">
            Set established {{ domain.website }}
        </div>
        <div class="content" v-if="domain">
            <form class="ui form">
                <div class="field">
                    <label>Email link</label>
                    <input type="text" v-model="domain.email_link">
                </div>
                <div class="field">
                    <label>Context</label>
                    <textarea v-model="domain.establish_context"></textarea>
                </div>
                <div class="field">
                    <label>Free</label>
                    <div class="ui toggle checkbox" @click="setFree()">
                        <input type="checkbox" tabindex="0" :checked="domain.is_free" class="hidden">
                        <label></label>
                    </div>
                </div>
                <div class="field">
                    <label>Paid</label>
                    <div class="ui toggle checkbox" @click="setPaid()">
                        <input type="checkbox" tabindex="0" :checked="domain.is_paid" class="hidden">
                        <label></label>
                    </div>
                </div>
            </form>
        </div>
        <div class="actions">
            <div class="ui black deny button">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click.stop="saveData()">
                Save
                <i class="checkmark icon"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: ['domainId'],

        data() {
            return {
                domain: null,
            };
        },

        watch: {
            domainId: function (val) {
                this.loadDomain()
            },
        },
        
        methods: {
            show()
            {
                $('#establishedModal').modal('show');
            },

            loadDomain() {
                this.$http.get('domains/' + this.domainId + '/').then(response => {
                    this.domain = response.data
                });
            },

            saveData() {
                this.domain.is_established = true

                this.$http.patch('domains/' + this.domain.id + '/set_established/', this.domain).then(response => {
                    this.$emit('saved', this.domain)
                    $('#establishedModal').modal('hide')
                    this.domain = null
                });
            },

            setFree()
            {
                this.domain.is_free = !this.domain.is_free
            },

            setPaid()
            {
                this.domain.is_paid = !this.domain.is_paid
            }
        }
    }
</script>
