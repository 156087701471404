<template>
    <div>
        <div @click.prevent="copyToClipboard" class="import-button"><i class="icon clipboard"></i>Get 200</div>

        <div id="copyToClipboardModal" class="ui modal">
            <i class="close icon"></i>
            <div class="header">
                Domains list
            </div>
            <div class="content">
                <div class="ui form">
                    <div class="field">
                        <textarea id="domains200">{{ domains }}</textarea>
                    </div>
                </div>
            </div>
        </div>

        <loading :active.sync="isLoading"></loading>
    </div>
</template>

<script>
/* eslint-disable */
    export default {
        data() {
            return {
                isLoading: false,
                domains: ''
            };
        },

        methods: {
            copyToClipboard(e) {
                this.isLoading = true

                this.$http.get('domains/get200/').then(response => {
                    this.isLoading = false
                    $('#copyToClipboardModal').modal('show');

                    let domains = ''
                    response.data.forEach((domain) => {
                        domains += domain + '\r\n'
                    });
                    domains = domains.substring(0,(domains.length-2));

                    this.domains = domains

                    const el = document.getElementById('domains200');
                    el.value = this.domains;
                    el.select();
                    document.execCommand('copy');
                })
            },
        }
    }
</script>
