<template>
    <sui-modal v-model="open" size="large" :closeIcon="true" v-if="campaign">

        <sui-modal-header v-if="!campaign.id">Add campaign v. 1.2</sui-modal-header>
        <sui-modal-header v-else>Edit campaign "{{ campaign.settings.name }}" v. 1.2</sui-modal-header>
        <sui-modal-content scrolling>
          <div class="ui grid form">
            <div class="eight wide column">
                <div :class="(!campaign.settings.name && error) ? 'field error' : 'field'">
                    <label>Name</label>
                    <input type="text" v-model="campaign.settings.name">
                </div>
            </div>

            <div class="eight wide column">
                <div :class="(campaign.settings.target_goal === '' && error) ? 'field error' : 'field'">
                    <label>Target goal</label>
                    <input type="number" v-model="campaign.settings.target_goal">
                </div>
            </div>
            <div class="eight wide column">
                <div class="field">
                    <label>Trello link</label>
                    <input type="text" v-model="campaign.settings.trello_link">
                </div>
            </div>

            <div class="eight wide column">
                <div class="field">
                    <label>Brief link</label>
                    <input type="text" v-model="campaign.settings.brief_link">
                </div>
            </div>
            <div class="eight wide column margin-bttm">
                <div class="field">
                    <label>End at</label>
                    <div class="datepicker">
                        <datepicker v-model="campaign.settings.end_at" format="dd.MM.yyyy"></datepicker>
                    </div>
                </div>
            </div>
          </div>
          <div class="sixteen wide column">
              <div class="field">
                  <div class="ui toggle checkbox" @click="campaign.settings.is_active = !campaign.settings.is_active">
                      <input type="checkbox" tabindex="0" :checked="campaign.settings.is_active" class="hidden">
                      <label>is Active</label>
                  </div>
              </div>
          </div>
          <div class="sixteen wide column margin-bttm">&nbsp;</div>
          <V2CampaignModalForm
            v-for="mailbox in campaign.meta" :key="campaign.meta.id"
            :mailboxes="mailboxes"
            :templates="templates"
            :subject-lines="subjectLines"
            :selected-mailbox="mailbox"
          />
        </sui-modal-content>

        <div class="sixteen wide column">
              <button type="button" class="fluid ui button" @click="addMailbox">Add another mailbox</button>
        </div>
        
        <sui-modal-actions>
            <div class="ui black deny button" @click="open=false">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click="saveCampaign">
                Save<i class="checkmark icon"></i>
            </div>
        </sui-modal-actions>
    </sui-modal>
</template>

<script>

    import V2CampaignModalForm from '@/components/V2CampaignModalForm'
    import Datepicker from 'vuejs-datepicker';

    export default {
 
        name: 'add-mailbox-modal',

        components: {
            Datepicker,
            V2CampaignModalForm,
        },
        props: ['subjectLines', 'mailboxes'],
        data() {
            return {
                selectedMailboxes: [],
                open: false,
                subjectLine: null,
                campaign: {
                  settings: {
                    name: null
                  },
                },
                defaultParams: {
                  settings: {
                    name: '',
                    trello_link: '',
                    brief_link: '',
                    target_goal: 0,
                    end_at: null,
                    is_active: true,
                    email_from: 'dmitrii@the.gt',
                    outreach_templates: [],
                    follow_up_templates: [],
                  },
                },
                emptyMeta: {
                    id: null,
                    mailbox: null,
                    subjectline_out: {
                      id: null,
                    },
                    subjectline_fu: {
                      id: null,
                    },
                    out_template:  {
                      id: null,
                    },
                    fu_template:  {
                      id: null,
                    },
                },

                emails: [],

                templates: null,

                loader: null,
                error: false,
            };
        },

        mounted() {
            this.campaign = this.defaultParams
            this.loadTemplates()
        },
        updated() {
        },
        methods: {
            show()
            {
                this.campaign = Object.assign({}, this.defaultParams)
                this.error = false
                this.open = true
            },

             loadTemplates()
             {
                 this.$http.get('templates/group_by_type/').then(response => {
                     this.templates = response.data
                 })
             },

            addMailbox()
            {
              var mailbox = JSON.parse(JSON.stringify(this.emptyMeta))
              var mbKey = 1
              if('undefined' === typeof this.campaign.meta) {
                this.campaign['id'] = 0
                this.campaign['meta'] = []
                this.campaign.meta.push(mailbox)
                this.$forceUpdate() // otherwise the array doesn't get updated
              } else {
                mbKey = Object.keys(this.campaign.meta).length
                this.campaign.meta.push(mailbox)
              }
            },

            saveCampaign()
            {
                // @todo: add error processing before Save

                // if (!this.campaign.name || this.campaign.target_goal === ''
                //     || this.campaign.outreach_templates.length === 0
                //     || this.campaign.follow_up_templates.length === 0)
                // {
                //     this.error = true
                //     return
                // }

                this.error = false

                if (this.campaign.id) {
                    this.updateCampaign()
                } else {
                    console.log('save')
                    this.createCampaign()
                }
            },

            createCampaign()
            {
                this.loader = this.$loading.show()

                this.$http.post('v2-campaigns/', this.campaign).then(response => {
                    this.$emit('created', response.data)
                    this.open = false

                }).catch(error => {
                    console.log(this.errors)

                }).then(response => {
                    this.loader.hide()
                })
            },

            updateCampaign()
            {
                console.log(this.campaign)
                this.loader = this.$loading.show()

                this.$http.patch('v2-campaigns/' + this.campaign.id + '/', this.campaign).then(response => {
                    this.$emit('updated', response.data)
                    this.open = false

                }).catch((response) => {
                    console.log(response)

                }).then(response => {
                    this.loader.hide()
                })
            },
        }
    }
</script>

<style scoped>
    .margin-bttm {
      margin-bottom: 2em;
    }
    .datepicker .vdp-datepicker__calendar {
        position: absolute;
        top: -282px;
    }

    .multi_select
    {
        height: 100px !important;
    }
</style>
