<template>
    <div v-if="list">

        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">Recipients for "{{ list.name }}" list</h1>
            </div>
            <div class="column right aligned">
                <div v-if="$user && $user.isAdmin">
                    <ImportHubspotData></ImportHubspotData>
                    <button v-if="list.recipients.length > 0" class="ui button" @click="checkPhones"><i class="lightbulb icon"></i>Check all phones</button>
                    <button v-if="list.recipients.length > 0" class="ui button" @click="createOpportunities"><i class="hand pointer icon"></i>Create opportunities</button>
                </div>
            </div>
        </div>

        <table class="ui celled table">
            <thead>
            <tr>
                <th>Website</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Name</th>
                <th>Is send outreach</th>
                <th>Is send follow up</th>
                <th>Is opened</th>
                <th>Is reply</th>
                <th>Is negative</th>
                <th>Outreach send</th>
                <th>Follow up send</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(recipient, index) in list.recipients" v-bind:key="recipient.id">
                <td><a :href="'http://' + recipient.website" target="_blank">{{ recipient.website }}</a></td>
                <td>{{ recipient.email }}</td>
                <td>
                    {{ recipient.phone }}<span v-if="recipient.phone != '' && recipient.phone_type != ''"> ({{ recipient.phone_type }})</span>

                    <div v-if="recipient.additional_phones.length > 0">
                        <br>
                        Additional phones:<br>
                        <span v-for="(phone, phoneIndex) in recipient.additional_phones" v-bind:key="phone.id">
                            {{ phone.number }}<span v-if="recipient.phone == '' && phone.phone_checked && recipient.phone_type != ''"> ({{ recipient.phone_type }})</span>
                            <br v-if="phoneIndex+1 != recipient.additional_phones.length">
                        </span>
                    </div>
                </td>
                <td>{{ recipient.first_name + ' ' + recipient.last_name}}</td>
                <td>{{ recipient.is_send_outreach }}</td>
                <td>{{ recipient.is_send_follow_up }}</td>
                <td>{{ recipient.is_opened }}</td>
                <td>
                    <div class="ui toggle checkbox" @click="setReply(index)">
                        <input type="checkbox" tabindex="0" :checked="recipient.is_reply" class="hidden">
                        <label></label>
                    </div>
                </td>
                <td>
                    <div class="ui toggle checkbox" @click="setNegative(index)">
                        <input type="checkbox" tabindex="0" :checked="recipient.is_negative" class="hidden">
                        <label></label>
                    </div>
                </td>
                <td>{{ recipient.outreach_send_at }}</td>
                <td>{{ recipient.follow_up_send_at }}</td>
                <td>
                    <div class="ui small basic icon buttons">
                        <button class="ui button" @click="removeRecipient(index)" data-content="Remove from mail list"><i class="trash icon"></i></button>
                        <button v-if="recipient.phone != '' && !recipient.phone_checked && (($user && $user.name === 'alex') || ($user && $user.name === 'grintender'))" class="ui button" @click="checkPhone(recipient)" data-content="Check phone"><i class="lightbulb icon"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    import ImportHubspotData from '@/components/ImportHubspotData'

    export default {

        components: {
            ImportHubspotData,
        },

        data() {
            return {
                loader: false,
                listId: null,
                list: null,
            };
        },

        mounted() {
            this.listId = this.$route.params.id
            this.loadMailList()
        },

        updated() {
            // Semantic popup
            $('.button').popup();
        },

        methods: {
            loadMailList()
            {
                this.loader = this.$loading.show()
                this.$http.get('mail-lists/' + this.listId + '/').then(response => {

                    this.list = response.data
                    this.loader.hide()
                });
            },

            removeRecipient(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                let data = {'contact_id': this.list.recipients[index].user_id}

                this.$http.post('mail-lists/' + this.listId + '/remove_recipient/', data).then(response => {
                    this.list.recipients.splice(index, 1)
                    this.loader.hide()
                });
            },

            setReply(index) {
                this.loader = this.$loading.show()

                this.$http.post('recipients/' + this.list.recipients[index].id + '/set_reply/').then(response => {
                    this.list.recipients[index].is_reply = !this.list.recipients[index].is_reply

                    this.loader.hide()
                });
            },

            setNegative(index) {
                this.loader = this.$loading.show()

                this.$http.post('recipients/' + this.list.recipients[index].id + '/set_negative/').then(response => {
                    this.list.recipients[index].is_negative = !this.list.recipients[index].is_negative

                    this.loader.hide()
                });
            },

            checkPhones()
            {
                this.loader = this.$loading.show()

                this.$http.get('mail-lists/' + this.listId + '/check_phones/').then(response => {
                    this.loader.hide()
                    this.loadMailList()
                })
            },

            checkPhone(recipient)
            {
                this.loader = this.$loading.show()

                this.$http.get('recipients/' + recipient.id + '/check_phone/').then(response => {
                    recipient.phone_checked = true
                    this.loader.hide()
                });
            },

            importHubspotData(e)
            {
                let file = e.target.files[0];

                const config = {
                    headers: {'content-type': 'multipart/form-data'}
                }

                let formData = new FormData();
                formData.append('file', file)

                this.loader = this.$loading.show()

                this.$http.post('import-hubspot-data/', formData, config).then(response => {
                    this.loader.hide()
                }).catch(error => {
                    console.log(error.response.data)

                }).then(response => {
                    this.loader.hide()

                    document.getElementById('file').value = ''
                    this.error = false
                })
            },

            createOpportunities()
            {
                this.loader = this.$loading.show()

                this.$http.get('mail-lists/' + this.listId + '/create_opportunities/').then(response => {
                    this.loader.hide()
                })
            }
        }
    }
</script>
