<template>
    <div class="ui container" v-if="domain">
        <h1 class="ui header">Domain {{ domain.website }}</h1>

        <table class="ui celled table form">
            <tbody>
            <tr>
                <td><b>Campaign</b></td>
                <td>{{ domain.campaign_name }}</td>
            </tr>
            <tr>
                <td><b>Category</b></td>
                <td>
                    <select v-if="domain && categories" class="ui fluid dropdown" v-model="domain.main_category_id">
                        <option value="">Without category</option>
                        <option v-for="category in categories" v-bind:value="category.id" v-bind:key="category.id">{{
                            category.name }}
                        </option>
                    </select>
                </td>
            </tr>
            <tr>
                <td><b>Tags</b></td>
                <td><textarea v-model="domain.tags" rows="2"></textarea></td>
            </tr>
            <tr>
                <td>
                    <b>Contacts &nbsp;&nbsp;&nbsp;</b>
                    <span class="button" @click="showAddContactModal(index)" style="cursor: pointer" data-content="Add contact"><i class="plus icon"></i></span>
                </td>
                <td>
                    <div v-for="(contact, index) in domain.contacts" v-bind:key="contact.id">
                        <div class="ui grid">
                            <div class="ten wide column">
                                {{ contact.email }}
                                <span v-if="contact.first_name != '' || contact.last_name != ''"> - {{ contact.first_name }} {{ contact.last_name }}</span>
                                <span v-if="contact.first_name != '' || contact.last_name != ''"> - </span>
                                {{ contact.position }}
                                <span v-if="contact.position != ''"> | </span>
                                {{ contact.phone }}
                            </div>
                            <div class="six wide column right aligned">
                                <div class="ui small basic icon buttons action-buttons">
                                    <button class="ui button" @click="showEditContactModal(index)" data-content="Edit contact"><i
                                            class="edit icon"></i></button>
                                    <button class="ui button" @click="deleteContact(index)" data-content="Delete contact">
                                        <i class="trash icon"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <div class="ui right labeled icon positive button" @click="saveDomainData">
            Save<i class="checkmark icon"></i>
        </div>

        <ContactModal ref="contactModal" :domain_id="domain.id" @created="createdContact" @updated="updatedContact"></ContactModal>
    </div>
</template>

<script>

    import ContactModal from '@/components/ContactModal'

    export default {

        components: {
            ContactModal,
        },

        data() {
            return {
                loader: null,
                domainId: null,
                domain: null,
                categories: null,
                selectedContactIndex: null
            };
        },

        mounted() {
            this.domainId = this.$route.params.id
            this.loadDomain()
            this.loadCategories()
        },

        updated() {
            // Semantic popup
            $('.button').popup();
        },

        methods: {

            loadDomain() {
                this.$http.get('domains/' + this.domainId + '/').then(response => {
                    this.domain = response.data
                });
            },

            loadCategories() {
                this.$http.get('categories/').then(response => {
                    this.categories = response.data
                });
            },

            saveDomainData() {
                let data = {
                    'category': this.domain.main_category_id,
                    'tags': this.domain.tags
                }

                this.loader = this.$loading.show()

                this.$http.patch('domains/' + this.domain.id + '/', data).then(response => {

                }).catch((response) => {
                    console.log(response)

                }).then(response => {
                    this.loader.hide()
                })
            },

            showAddContactModal() {
                this.$refs.contactModal.show();
            },

            showEditContactModal(index) {
                this.selectedContactIndex = index
                this.$refs.contactModal.show()
                this.$refs.contactModal.contact = Object.assign({}, this.domain.contacts[index])
            },

            createdContact(contact) {
                this.domain.contacts.push(contact)
            },

            updatedContact(contact) {
                this.$set(this.domain.contacts, this.selectedContactIndex, contact)
                this.selectedContactIndex = null
            },

            deleteContact(index)
            {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                this.$http.delete('contacts/' + this.domain.contacts[index].id + '/').then(response => {
                    this.domain.contacts.splice(index, 1)
                    this.loader.hide()
                });
            }

        }
    }
</script>