<template>
    <div id="sendEmailToOpportunityModal" class="ui modal">
        <i class="close icon"></i>
        <div class="header">
            Send email
        </div>
        <div class="content">
            <div class="ui grid form">

                <div class="sixteen wide column">
                    <div :class="(mailSubject === '' && error) ? 'field error' : 'field'">
                        <label>Subject</label>
                        <input type="text" v-model="mailSubject">
                    </div>
                </div>

                <div class="sixteen wide column">
                    <div :class="(mailMessage === '' && error) ? 'field error' : 'field'">
                        <label>Message</label>
                        <textarea v-model="mailMessage"></textarea>
                    </div>
                </div>

                <div v-if="!createContact" class="sixteen wide column">
                    <div v-if="domainId" :class="(contact === null && error && (name === '' && email === '')) ? 'field error' : 'field'">
                        <label>Select email</label>
                        <EmailsSelect
                                :domain="domainId"
                                @selected="selectedContact"
                                :populate="loadContacts"></EmailsSelect>
                    </div>
                </div>

                <div class="sixteen wide column">
                    <div class="field">
                        <div class="ui toggle checkbox" @click="createContact = !createContact">
                            <input type="checkbox" tabindex="0" :checked="createContact" class="hidden">
                            <label>Or create new contact</label>
                        </div>
                    </div>
                </div>

                <div v-if="createContact" class="eight wide column">
                    <div :class="(name === '' && error) ? 'field error' : 'field'">
                        <label>Name</label>
                        <input type="text" v-model="name">
                    </div>
                </div>
                <div v-if="createContact" class="eight wide column">
                    <div :class="(email === '' && error) ? 'field error' : 'field'">
                        <label>Email</label>
                        <input type="email" v-model="email">
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="ui black deny button">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click.stop="createMailMessage()">
                Save
                <i class="checkmark icon"></i>
            </div>
        </div>
    </div>
</template>

<script>
    import EmailsSelect from '@/components/EmailsSelect'

    export default {
        components: {
            EmailsSelect
        },

        data() {
            return {
                loader: null,
                loadContacts: true,
                domainId: null,
                contact: null,
                opportunityId: null,
                mailSubject: '',
                mailMessage: '',
                name: '',
                email: '',
                createContact: false,
                error: false
            };
        },

        methods: {
            show()
            {
                $('#sendEmailToOpportunityModal').modal('show');
            },

            createMailMessage()
            {
                if (this.mailSubject === '' || this.mailMessage === '') {
                    this.error = true
                    return
                }

                if (this.contact === null)
                {
                    if (this.name === '' || this.email === '')
                    {
                        this.error = true
                        return
                    }
                }

                this.error = false

                let data = {
                    'mail_subject': this.mailSubject,
                    'mail_message': this.mailMessage,
                }

                if (this.contact !== null)
                    data['contact_id'] = this.contact.id
                else
                {
                    data['name'] = this.name
                    data['email'] = this.email
                }

                this.loader = this.$loading.show()

                this.$http.patch('opportunities/' + this.opportunityId + '/send_mail/', data).then(response => {
                    $('#sendEmailToOpportunityModal').modal('hide');
                    this.$emit('mailCreated', response.data)

                    this.mailSubject = ''
                    this.mailMessage = ''
                    this.name = ''
                    this.email = ''
                    this.contact = null

                    this.loader.hide()
                })
            },

            selectedContact(contact)
            {
                this.contact = contact
            },
        }
    }
</script>
