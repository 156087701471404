<template>
    <sui-modal v-model="open" :closeIcon="true">

        <sui-modal-header>Add project</sui-modal-header>

        <sui-modal-content >
            <div class="ui form">
                <!--
                <div class="field">
                    <label>Select parent project</label>
                    <select v-if="projects" class="ui fluid dropdown" v-model="parentProject">
                        <option value="null" disabled></option>
                        <option v-for="project in projects"
                                v-bind:value="project.id"
                                v-bind:key="project.id">{{ project.name }}
                        </option>
                    </select>
                </div>
                -->

                <div :class="(name === '' && error) ? 'field error' : 'field'">
                    <label>Name</label>
                    <input type="text" v-model="name">
                </div>
            </div>
        </sui-modal-content>

        <sui-modal-actions>
            <div class="ui black deny button" @click="open=false">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click="createProject">
                Save<i class="checkmark icon"></i>
            </div>
        </sui-modal-actions>
    </sui-modal>
</template>

<script>

    export default {

        props:['parent_id'],

        data() {
            return {
                open: false,
                campaign: null,
                name: '',
                parentProject: null,
                loader: null,
                error: false,

                projects: null
            };
        },

        mounted() {

            this.loader = this.$loading.show()

            this.$http.get('finance/projects/').then(response => {
                this.projects = response.data
                this.loader.hide()
            });
        },

        methods: {
            show()
            {
                this.name = ''
                this.error = false
                this.open = true
            },

            createProject()
            {
                if (!this.name)
                {
                    this.error = true
                    return
                }

                this.error = false

                this.loader = this.$loading.show()

                let data = {
                    'name': this.name,
                    'parent_id': this.parent_id
                }

                this.$http.post('finance/projects/', data).then(response => {
                    this.$emit('created', response.data)
                    this.open = false

                }).catch(error => {
                    console.log(this.errors)

                }).then(response => {
                    this.loader.hide()
                })
            },
        }
    }
</script>
