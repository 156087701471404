<template>
    <sui-modal v-model="open" :closeIcon="true" v-if="mailbox">

        <sui-modal-header v-if="!mailbox.id">Add mailbox</sui-modal-header>
        <sui-modal-header v-else>Edit mailbox {{ mailbox.name }}</sui-modal-header>

        <sui-modal-content scrolling>
            <div class="ui grid form">
                <div class="sixteen wide column">
                    <div :class="!mailbox.name && error ? 'field error' : 'field'">
                        <label>Name</label>
                        <input type="text" v-model="mailbox.name">
                    </div>
                </div>

                <div class="sixteen wide column">
                    <div :class="((mailbox.email === '' && error) || emailError) ? 'field error' : 'field'">
                        <label>Email</label>
                        <input type="email" v-model="mailbox.email">
                    </div>
                </div>

                <div class="sixteen wide column">
                    <div :class="(mailbox.day_limit === '' || mailbox.day_limit < 10 || mailbox.day_limit > 240 && error) ? 'field error' : 'field'">
                        <label>Day limit</label>
                        <input type="number" v-model="mailbox.day_limit">
                    </div>
                </div>

                <div class="sixteen wide column">
                    <div class="field">
                        <label>Signature</label>
                        <ckeditor :editor="editor" v-model="mailbox.signature" :config="editorConfig"></ckeditor>
                    </div>
                </div>

                <div class="sixteen wide column">
                    <div class="inline field">
                        <label>Select mail provider</label>
                        <select class="ui fluid dropdown" v-model="mailbox.email_provider">
                            <option v-if="mailbox.email_provider=='ses'" selected value="ses">Amazon SES (default)</option>
                            <option v-else value="ses">Amazon SES (default)</option>
                            <option v-if="mailbox.email_provider=='sg'" selected value="sg">SendGrid #1</option>
                            <option v-else value="sg">SendGrid #1</option>
                            <option v-if="mailbox.email_provider=='sg2'" selected value="sg2">SendGrid #2</option>
                            <option v-else value="sg2">SendGrid #2</option>
                            <option v-if="mailbox.email_provider=='sp'" selected value="sp">SparkPost</option>
                            <option v-else value="sp">SparkPost</option>
                            <option v-if="mailbox.email_provider=='gtr_random'" selected value="gtr_random">RANDOM Homebrew server</option>
                            <option v-else value="gtr_random">RANDOM Homebrew server</option>
                            <option v-if="mailbox.email_provider=='gtr1'" selected value="gtr1">Homebrew server #1 (storytelling.supplies)</option>
                            <option v-else value="gtr1">Homebrew server #1 (storytelling.supplies)</option>
                            <option v-if="mailbox.email_provider=='gtr2'" selected value="gtr2">Homebrew server #2 (angle.press)</option>
                            <option v-else value="gtr2">Homebrew server #2 (angle.press)</option>
                            <option v-if="mailbox.email_provider=='gtr3'" selected value="gtr3">Homebrew server #3 (cartel.press)</option>
                            <option v-else value="gtr3">Homebrew server #3 (cartel.press)</option>

                            <option v-if="mailbox.email_provider=='gtr4'" selected value="gtr4">Homebrew server #4 (zipzap.cash)</option>
                            <option v-else value="gtr4">Homebrew server #4 (zipzap.cash)</option>
                            <option v-if="mailbox.email_provider=='gtr5'" selected value="gtr5">Homebrew server #5 (chilling.coffee)</option>
                            <option v-else value="gtr5">Homebrew server #5 (chilling.coffee)</option>
                            <option v-if="mailbox.email_provider=='gtr6'" selected value="gtr6">Homebrew server #6 (consumed.ink)</option>
                            <option v-else value="gtr6">Homebrew server #6 (consumed.ink)</option>
                            <option v-if="mailbox.email_provider=='gtr7'" selected value="gtr7">Homebrew server #7 (entrepreneurs.bid)</option>
                            <option v-else value="gtr7">Homebrew server #7 (entrepreneurs.bid)</option>
                            <option v-if="mailbox.email_provider=='gtr8'" selected value="gtr8">Homebrew server #8 (entrepreneurs.express)</option>
                            <option v-else value="gtr8">Homebrew server #8 (entrepreneurs.express)</option>
                            <option v-if="mailbox.email_provider=='gtr9'" selected value="gtr9">Homebrew server #9 (fame.run)</option>
                            <option v-else value="gtr9">Homebrew server #9 (fame.run)</option>
                            <option v-if="mailbox.email_provider=='gtr10'" selected value="gtr10">Homebrew server #10 (rsociety.club)</option>
                            <option v-else value="gtr10">Homebrew server #10 (rsociety.club)</option>
                            <option v-if="mailbox.email_provider=='gtr11'" selected value="gtr11">Homebrew server #11 (fortune.supply)</option>
                            <option v-else value="gtr11">Homebrew server #11 (fortune.supply)</option>

                            <option v-if="mailbox.email_provider=='mjt'" selected value="mjt">MailJet</option>
                            <option v-else value="mjt">MailJet</option>
                            <option v-if="mailbox.email_provider=='elm'" selected value="elm">ElasticMail</option>
                            <option v-else value="elm">ElasticMail</option>
                            <option v-if="mailbox.email_provider=='pmk'" selected value="pmk">Postmark</option>
                            <option v-else value="pmk">Postmark</option>
                        </select>
                    </div>
                </div>
            </div>

        </sui-modal-content>

        <sui-modal-actions>
            <div class="ui black deny button" @click="open=false">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click="saveMailbox">
                Save<i class="checkmark icon"></i>
            </div>
        </sui-modal-actions>
    </sui-modal>
</template>

<script>


    import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

    import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
    import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
    import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
    import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
    import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
    import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';

    export default {

        data() {
            return {
                open: false,

                mailbox: null,
                defaultParams: {
                    name: '',
                    email: '',
                    day_limit: 10,
                    signature: '',
                    email_provider: '',
                },

                loader: null,
                error: false,
                emailError: false,

                editor: ClassicEditor,
                editorConfig: {
                    plugins: [
                        EssentialsPlugin,
                        BoldPlugin,
                        ItalicPlugin,
                        Strikethrough,
                        LinkPlugin,
                        ParagraphPlugin,
                    ],

                    toolbar: {
                        items: [
                            'bold',
                            'italic',
                            'link',
                            'undo',
                            'redo'
                        ]
                    }
                },
            };
        },

        mounted() {
            this.mailbox = this.defaultParams
        },

        methods: {
            show()
            {
                this.mailbox = Object.assign({}, this.defaultParams)
                this.error = false
                this.emailError = false

                this.open = true
            },

            saveMailbox()
            {
                if (!this.mailbox.name || this.mailbox.email === ''
                    || this.mailbox.day_limit < 10 || this.mailbox.day_limit > 240)
                {
                    this.error = true
                    return
                }

                this.error = false
                this.emailError = false

                if (this.mailbox.id)
                    this.updateMailbox()
                else
                    this.createMailbox()
            },

            createMailbox()
            {
                this.loader = this.$loading.show()

                this.$http.post('mailboxes/', this.mailbox).then(response => {
                    this.$emit('created', response.data)
                    this.open = false

                }).catch(error => {
                    console.log(this.errors)

                }).then(response => {
                    this.loader.hide()
                })
            },

            updateMailbox()
            {
                this.loader = this.$loading.show()

                this.$http.patch('mailboxes/' + this.mailbox.id + '/', this.mailbox).then(response => {
                    this.$emit('updated', response.data)
                    this.open = false

                }).catch((response) => {
                    if (response.response.data.email)
                        this.emailError = true

                }).then(response => {
                    this.loader.hide()
                })
            },
        }
    }
</script>

<style scoped>
    .datepicker .vdp-datepicker__calendar {
        position: absolute;
        top: -282px;
    }

    .multi_select
    {
        height: 100px !important;
    }
</style>
