<template>
    <div>
        <div class="ui three column grid">
            <div class="column ui form">
                <div class="fields">
                    <div class="twelve wide inline field">
                        <label>Category</label>
                        <CategorySelect @selected="selectedCategory"/>
                    </div>
                    <div class="four inline field">
                        <input class="input-field" type="number" v-model="rating" placeholder="Rating">
                    </div>
                </div>
            </div>
            <div class="column">
                <h1 class="ui header">Domains</h1>
            </div>
            <div class="column ui form">
                <div class="fields">
                    <div class="eight wide inline field">
                        <div class="ui icon input">
                          <input type="text" v-model="search" placeholder="Search...">
                          <i class="circular search link icon" @click="startSearch"></i>
                        </div>
                    </div>
                    <div class="eight wide inline field aligned right">
                        <label>List</label>
                        <MailListsSelect @selected="selectedMailList"/>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="selectedCategoryId !== null" class="ui secondary pointing menu">
            <a :class="filter === 'all' ? 'item active' : 'item'" @click="filter='all'">
                In progress
            </a>
            <a :class="filter === 'empty' ? 'item active' : 'item'" @click="filter='empty'">
                Empty
            </a>
            <a :class="filter === 'queue' ? 'item active' : 'item'" @click="filter='queue'">
                In queue
            </a>
            <a :class="filter === 'invalid' ? 'item active' : 'item'" @click="filter='invalid'">
                Invalid
            </a>
            <a :class="filter === 'trash' ? 'item active' : 'item'" @click="filter='trash'">
                Trash
            </a>
        </div>

        <div class="ui text container">
            <paginate v-if="pagesCount > 1"
                      v-model="currentPage"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>

        <br/>

        <DomainsTable :data="domains" type="search" :filter="filter" :selectedMailList="selectedMailListId"></DomainsTable>

        <br/>

        <div class="ui text container">
            <paginate v-if="pagesCount > 1"
                      v-model="currentPage"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>
    </div>
</template>

<script>
    import CategorySelect from '@/components/CategorySelect'
    import MailListsSelect from '@/components/MailListsSelect'
    import DomainsTable from '@/components/DomainsTable'

    export default {

        components: {
            CategorySelect,
            MailListsSelect,
            DomainsTable,
        },

        props: ['q'],

        data() {
            return {
                loader: null,
                search: '',
                selectedCategoryId: null,
                selectedMailListId: null,
                rating: '',
                filter: 'all',
                domains: [],
                domainsOnPage: 100,
                pagesCount: 0,
                currentPage: 1
            };
        },

        watch: {
            rating: function (val) {
                val = parseInt(val)
                if (val >= 0)
                    this.loadDomains()
            },

            q: function (val) {
                if (val)
                    this.search = val
                else
                    this.search = ''

                this.loadDomains()
            },
        },

        mounted() {

            if (this.$route.params.q)
                this.search = this.$route.params.q


            this.loadDomains()
        },

        watch: {
            filter: function (val) {
                this.loadDomains()
            },
        },

        methods: {

            startSearch() {
                this.loadDomains()
            },

            loadDomains(offset = 0) {
                // If category doesn't selected
                if (this.selectedCategoryId == null && this.search.length == 0) {
                    this.domains = []
                    return
                }

                this.loader = this.$loading.show()

                let url = 'domains/?category_id=' + this.selectedCategoryId

                if (this.filter === 'all' || this.filter === 'empty')
                    url += '&checked_result=' + this.filter
                else if (this.filter === 'invalid')
                    url += '&invalid_contact=1'
                else if (this.filter === 'queue')
                        url += '&queue=1'
                else if (this.filter === 'trash')
                        url += '&is_trash=1'

                if (this.search.length > 0)
                {
                    url = 'domains/second_search/?q=' + this.search

                    if (this.selectedCategoryId !== null)
                        url += '&category_id=' + this.selectedCategoryId
                }

                if (parseInt(this.rating) >= 0)
                    url += '&rating=' + this.rating

                // paging
                url += '&limit=' + this.domainsOnPage + '&offset=' + offset

                this.$http.get(url).then(response => {
                    this.domains = response.data.results
                    this.pagesCount = Math.ceil(response.data.count / this.domainsOnPage)

                    this.loader.hide()
                })
            },

            selectedCategory(categoryId) {
                this.selectedCategoryId = categoryId
                this.loadDomains()
            },

            selectedMailList(listId) {
                this.selectedMailListId = listId
            },

            selectPage(pageNum) {
                this.currentPage = pageNum
                let offset = (pageNum - 1) * 100
                this.loadDomains(offset)
            },
        }
    }
</script>
