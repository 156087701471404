<template>
    <div id="dismissOpportunityModal" class="ui modal">
        <i class="close icon"></i>
        <div class="header">
            What happened
        </div>
        <div class="content">
            <div class="ui form">
                <div :class="(dismissText === '' && error) ? 'field error' : 'field'">
                    <label>Message</label>
                    <textarea v-model="dismissText"></textarea>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="ui black deny button">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click.stop="dismissOpportunity()">
                Save
                <i class="checkmark icon"></i>
            </div>
        </div>
    </div>
</template>

<script>
    import EmailsSelect from '@/components/EmailsSelect'

    export default {
        components: {
            EmailsSelect
        },

        data() {
            return {
                loader: null,
                domainId: null,
                opportunityId: null,
                dismissText: '',
                error: false,
            };
        },

        methods: {
            show()
            {
                $('#dismissOpportunityModal').modal('show');
            },

            dismissOpportunity()
            {
                if (this.dismissText === '') {
                    this.error = true
                    return
                }

                this.error = false

                let data = {
                    'is_dismissed': true,
                    'is_positive': false,
                    'dismiss_text': this.dismissText
                }

                this.loader = this.$loading.show()

                this.$http.patch('opportunities/' + this.opportunityId + '/dismiss/', data).then(response => {
                    $('#dismissOpportunityModal').modal('hide');
                    this.$emit('dismissed', this.opportunityId)
                    this.loader.hide()
                })
            },

        }
    }
</script>
