<template>
    <div id="createMailListModal" class="ui modal">
        <i class="close icon"></i>
        <div v-if="listObj.id === null" class="header">
            Create mail list
        </div>
        <div v-else class="header">
            Edit mail list
        </div>
        <div class="content">
            <div class="ui grid form">
                <div class="eight wide column">
                    <div :class="(error && listObj.name === '') ? 'field error' : 'field'">
                        <label>List name</label>
                        <input type="text" v-model="listObj.name">
                    </div>
                </div>
                <div class="eight wide column">
                    <div :class="(error && listObj.campaign_id === null) ? 'field error' : 'field'">
                        <label>Select campaign</label>
                        <select v-if="campaigns" @change="campaignChanged" class="ui fluid dropdown" v-model="listObj.campaign_id">
                            <option value="" disabled></option>
                            <option v-for="campaign in campaigns"
                                    v-bind:value="campaign.id"
                                    v-bind:key="campaign.id">{{ campaign.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="eight wide column">
                    <div :class="listObj.outreach_a_subject === '' ? 'field error' : 'field'">
                        <label>Outreach subject</label>
                        <input type="text" v-model="listObj.outreach_a_subject">
                    </div>
                    <div class="field">
                        <label>Select template</label>
                        <select v-if="templates" class="ui fluid dropdown" v-model="listObj.outreach_a_temp_id">
                            <option v-for="template in templates.outreach"
                                    v-bind:value="template.id"
                                    v-bind:key="template.id">{{ template.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="eight wide column">
                    <div :class="listObj.follow_up_a_subject === '' ? 'field error' : 'field'">
                        <label>Follow up subject</label>
                        <input type="text" v-model="listObj.follow_up_a_subject">
                    </div>
                    <div class="field">
                        <label>Select template</label>
                        <select v-if="templates" class="ui fluid dropdown" v-model="listObj.follow_up_a_temp_id">
                            <option v-for="template in templates.follow_up"
                                    v-bind:value="template.id"
                                    v-bind:key="template.id">{{ template.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="eight wide column">
                    <div class="ui toggle checkbox"
                         @click="listObj.outreach_ab_is_enabled = !listObj.outreach_ab_is_enabled">
                        <input type="checkbox" tabindex="0" :checked="listObj.outreach_ab_is_enabled" class="hidden">
                        <label>Enable Outreach AB</label>
                    </div>
                </div>
                <div class="eight wide column">
                    <div class="ui toggle checkbox"
                         @click="listObj.follow_up_ab_is_enabled = !listObj.follow_up_ab_is_enabled">
                        <input type="checkbox" tabindex="0" :checked="listObj.follow_up_ab_is_enabled" class="hidden">
                        <label>Enable Follow up AB</label>
                    </div>
                </div>

                <div class="eight wide column">
                    <div v-if="listObj.outreach_ab_is_enabled">
                        <div :class="listObj.outreach_b_subject === '' ? 'field error' : 'field'">
                            <label>Outreach B subject</label>
                            <input type="text" v-model="listObj.outreach_b_subject">
                        </div>
                        <div class="field">
                            <label>Select template</label>
                            <select v-if="templates" class="ui fluid dropdown" v-model="listObj.outreach_b_temp_id">
                                <option v-for="template in templates.outreach"
                                        v-bind:value="template.id"
                                        v-bind:key="template.id">{{ template.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="eight wide column">
                    <div v-if="listObj.follow_up_ab_is_enabled">
                        <div :class="listObj.follow_up_b_subject === '' ? 'field error' : 'field'">
                            <label>Follow up B subject</label>
                            <input type="text" v-model="listObj.follow_up_b_subject">
                        </div>
                        <div class="field">
                            <label>Select template</label>
                            <select v-if="templates" class="ui fluid dropdown" v-model="listObj.follow_up_b_temp_id">
                                <option v-for="template in templates.follow_up"
                                        v-bind:value="template.id"
                                        v-bind:key="template.id">{{ template.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="ui black deny button">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click.stop="createMailList()">
                Save
                <i class="checkmark icon"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: ['list', 'campaignId'],

        data() {
            return {
                loader: null,
                error: false,

                listObj: {
                    id: null,
                    name: '',
                    campaign_id: null,
                    outreach_a_subject: "I'd love to collaborate with you",
                    follow_up_a_subject: "I'd love to contribute to your website",
                    outreach_b_subject: "I'd love to collaborate with you",
                    follow_up_b_subject: "I'd love to contribute to your website",
                    outreach_ab_is_enabled: false,
                    follow_up_ab_is_enabled: false,
                    outreach_a_temp_id: null,
                    outreach_b_temp_id: null,
                    follow_up_a_temp_id: null,
                    follow_up_b_temp_id: null,
                },

                templates: null,
                campaigns: null
            };
        },

        watch: {
            list: function (val) {
                this.setDefaultListValues()

                if (val != null) {

                    this.listObj = val

                    if (val.campaign_id !== null)
                        this.loadTemplates(val.campaign_id, false)

                }
            },
        },

        mounted() {
            this.$http.get('campaigns').then(response => {
                this.campaigns = response.data
            })
        },

        methods: {
            show() {
                this.error = false
                $('#createMailListModal').modal('show');
            },

            loadTemplates(campaign_id, defaults=true)
            {
                this.$http.get('templates/group_by_type/?campaign_id=' + campaign_id).then(response => {

                    if("undefined" !== typeof response.data.follow_up[0]) {
                      this.templates = response.data
                    } else {
                      this.templates = {
                        follow_up: [{
                          id: 45,
                          name: 'N/A'
                        }],
                        outreach: [{
                          id: 44,
                          name: 'N/A'
                        }]
                      }
                    }

                    if (defaults) {
                        this.listObj.outreach_a_temp_id = this.templates.outreach[0].id
                        this.listObj.outreach_b_temp_id = this.templates.outreach[0].id
                        this.listObj.follow_up_a_temp_id = this.templates.follow_up[0].id
                        this.listObj.follow_up_b_temp_id = this.templates.follow_up[0].id
                    }
/*{
                } */
                })
            },

            campaignChanged()
            {
                this.loadTemplates(this.listObj.campaign_id)
            },

            createMailList() {
                if (this.listObj.name === '' || this.listObj.campaign_id === null
                    || this.listObj.outreach_a_subject === '' || this.follow_up_a_subject === ''
                    || this.listObj.outreach_b_subject === '' || this.follow_up_b_subject === '') {
                    this.error = true
                    return
                }

                this.error = false

                this.loader = this.$loading.show()

                if (this.listObj.id == null) {
                    this.$http.post('mail-lists/', this.listObj).then(response => {
                        this.closeModalAndRefreshValues(response.data)
                    });
                }
                else {
                    this.$http.put('mail-lists/' + this.listObj.id + '/', this.listObj).then(response => {
                        this.closeModalAndRefreshValues(response.data)
                    });
                }
            },

            closeModalAndRefreshValues(list) {
                this.setDefaultListValues()

                this.loader.hide()

                $('#createMailListModal').modal('hide')

                this.$emit('saved', list)
            },

            setDefaultListValues() {
                this.listObj.id = null
                this.listObj.name = ''
                this.listObj.campaign_id = null
                this.listObj.outreach_ab_is_enabled = false
                this.listObj.follow_up_ab_is_enabled = false

                this.listObj.outreach_a_subject = "I'd love to contribute to your website"
                this.listObj.follow_up_a_subject = "I'd love to contribute to your website"
                this.listObj.outreach_b_subject = "I'd love to contribute to your website"
                this.listObj.follow_up_b_subject = "I'd love to contribute to your website"

                this.templates = null
                this.listObj.outreach_a_temp_id = null
                this.listObj.outreach_b_temp_id = null
                this.listObj.follow_up_a_temp_id = null
                this.listObj.follow_up_b_temp_id = null
            }
        }
    }
</script>
