<template>
    <select class="ui fluid dropdown" @change="changed" v-model="selected">
        <option value="0" selected>Select mail list</option>
        <option v-for="list in lists" v-bind:value="list.id" v-bind:key="list.id">{{ list.name }}</option>
    </select>
</template>

<script>
    export default {
        data() {
            return {
                lists: [],
                selected: null
            };
        },

        mounted() {
            this.$http.get('mail-lists/').then(response => {
                this.lists = response.data.results
            });
        },

        updated()
        {
          //$('.dropdown').dropdown()
        },

        methods: {
            changed() {
                let selected = this.selected

                if (selected == 0)
                    selected = null

                this.$emit('selected', selected)
            },
        }
    }
</script>
