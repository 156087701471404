<template>
    <div>

        <div class="ui five column grid">
            <div class="column"></div>
            <div class="column">
                <CategorySelect @selected="selectedCategory"/>
            </div>
            <div class="column">
                <h1 class="ui header">Rejected</h1>
            </div>
        </div>

        <br/>

        <div class="ui text container">
            <paginate v-if="pagesCount > 1"
                      v-model="currentPage"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>

        <br/>

        <DomainsTable :data="domains" type="rejected"></DomainsTable>

        <br/>

        <div class="ui text container">
            <paginate v-if="pagesCount > 1"
                      v-model="currentPage"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>

        <br/>
        <loading :active.sync="isLoading"></loading>
    </div>
</template>

<script>
    import CategorySelect from '@/components/CategorySelect'
    import DomainsTable from '@/components/DomainsTable'

    export default {

        components: {
            CategorySelect,
            DomainsTable,
        },

        data() {
            return {
                isLoading: false,
                selectedCategoryId: null,
                is_established: false,
                domains: [],
                domainsOnPage: 100,
                pagesCount: 0,
                currentPage: 1,
            };
        },

        mounted() {
            this.loadDomains()
        },

        methods: {

            loadDomains(offset = 0) {
                // If category doesn't selected
                if (this.selectedCategoryId == null)
                    return

                this.isLoading = true

                let url = 'domains/?is_rejected=1&category_id=' + this.selectedCategoryId

                // paging
                url += '&limit=' + this.domainsOnPage + '&offset=' + offset

                this.$http.get(url).then(response => {
                    this.domains = response.data.results
                    this.pagesCount = Math.ceil(response.data.count / this.domainsOnPage)
                    this.isLoading = false
                })
            },

            selectedCategory(categoryId) {
                this.selectedCategoryId = categoryId
                this.loadDomains()
            },

            showEstablished() {
                this.is_established = !this.is_established
                this.loadDomains()
            },

            selectPage(pageNum) {
                this.currentPage = pageNum
                let offset = (pageNum - 1) * 100
                this.loadDomains(offset)
            },
        }
    }
</script>
