<template>
    <div v-if="list">

        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">Domains for "{{ list.name }}" list</h1>
            </div>
            <div class="column right aligned">
                <ImportHubspotData v-if="($user && $user.name === 'alex') || ($user && $user.name === 'grintender')"></ImportHubspotData>
            </div>
        </div>

         <div class="ui secondary pointing menu">
            <a :class="filter === 'all' ? 'item active' : 'item'" @click="filter='all'">
                In progress
            </a>
            <a :class="filter === 'empty' ? 'item active' : 'item'" @click="filter='empty'">
                Empty
            </a>
            <a :class="filter === 'queue' ? 'item active' : 'item'" @click="filter='queue'">
                In queue
            </a>
            <a :class="filter === 'invalid' ? 'item active' : 'item'" @click="filter='invalid'">
                Invalid
            </a>
            <a :class="filter === 'trash' ? 'item active' : 'item'" @click="filter='trash'">
                Trash
            </a>
        </div>

        <!--
        <div class="ui text container">
            <paginate v-if="pagesCount > 1"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>

        <br/>
        -->

        <DomainsTable :data="domains" :listId="listId" type="list" :filter="filter"></DomainsTable>

        <infinite-loading :identifier="infiniteId" @infinite="loadDomainsWithInfiniteHandler"></infinite-loading>

        <!--
        <br/>

        <div class="ui text container">
            <paginate v-if="pagesCount > 1"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>
        -->
    </div>
</template>

<script>
    import DomainsTable from '@/components/DomainsTable'
    import ImportHubspotData from '@/components/ImportHubspotData'

    export default {

        components: {
            DomainsTable,
            ImportHubspotData
        },

        data() {
            return {
                loader: null,
                listId: null,
                list: null,
                domains: [],
                filter: 'all',
                domainsOnPage: 100,
                pagesCount: 0,
                page: 1,
                infiniteId: +new Date(),
            };
        },

        mounted() {
            this.listId = this.$route.params.id
            this.loadList()
            //this.loadDomainsWithPaging()
        },

        watch: {
            filter: function (val) {
                this.page = 1
                this.domains = []
                this.infiniteId += 1

                return val
            },
        },

        methods: {

            loadList()
            {
                this.$http.get('lists/' + this.listId + '/').then(response => {
                    this.list = response.data
                });
            },

            loadDomainsWithPaging(offset = 0)
            {
                let data = {
                    'list_id': this.listId
                }

                // paging
                let url = 'domains/by_list_id/?limit=' + this.domainsOnPage + '&offset=' + offset

                if (this.filter === 'all' || this.filter === 'empty')
                    url += '&checked_result=' + this.filter
                else if (this.filter === 'invalid')
                    url += '&invalid_contact=1'
                else if (this.filter === 'queue')
                    url += '&queue=1'
                else if (this.filter === 'trash')
                    url += '&is_trash=1'

                this.loader = this.$loading.show()

                this.$http.post(url, data).then(response => {
                    this.domains = response.data.results
                    this.pagesCount = Math.ceil(response.data.count / this.domainsOnPage)

                    this.loader.hide()

                });
            },

            selectPage(pageNum) {
                let offset = (pageNum - 1) * 100
                this.loadDomainsWithPaging(offset)
            },

            loadDomainsWithInfiniteHandler($state)
            {
                let data = {
                    'list_id': this.listId
                }

                let url = 'domains/by_list_id/?page=' + this.page

                if (this.filter === 'all' || this.filter === 'empty')
                    url += '&checked_result=' + this.filter
                else if (this.filter === 'invalid')
                    url += '&invalid_contact=1'
                else if (this.filter === 'queue')
                    url += '&queue=1'
                else if (this.filter === 'trash')
                    url += '&is_trash=1'

                this.$http.post(url, data).then(response => {

                    if (response.data.length) {
                        this.page += 1;

                        this.domains.push(...response.data)

                        $state.loaded()
                    }
                    else
                        $state.complete()

                });
            },
        }
    }
</script>
