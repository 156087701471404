<script>
    import {Bar, mixins} from 'vue-chartjs'

    const {reactiveProp} = mixins

    export default {
        extends: Bar,
        mixins: [reactiveProp],

        data() {
            return {
                options: {

                    maintainAspectRatio: false,

                    scales: {
                        yAxes: [
                            {
                                position: "left",
                                id: "A",
                            },
                            {
                                type: 'linear',
                                position: "right",
                                id: "B",
                                ticks: {
                                    max: 24,
                                    min: 0
                                }
                            }
                        ]
                    }
                }
            };
        },


        mounted() {
            // this.chartData is created in the mixin.
            // If you want to pass options please create a local options object
            this.renderChart(this.chartData, 'bar', this.options)
        }
    }
</script>