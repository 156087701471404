<template>
    <div class="ui container">
        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">Finance</h1>
            </div>
            <div class="column right aligned">
                <button class="ui button" @click="showAddProjectModal"><i class="plus icon"></i>Add project</button>
            </div>
        </div>

        <div class="ui three column grid">
            <div class="column">
                <h3>Main projects</h3>
                <table class="ui celled table" v-if="projects.length">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(project, index) in projects" :key="project.id">
                        <td><router-link :to="'/finance/main-projects/' + project.id" class="item">{{ project.name }}</router-link></td>
                        <td>
                            <div class="ui small basic icon buttons">
                                <button class="ui button" @click="deleteProject(index)" data-content="Delete"><i class="trash icon"></i></button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="column">

            </div>
            <div class="column">

            </div>
        </div>

        <ProjectModal ref="projectModal" @created="createdProject"></ProjectModal>
    </div>
</template>

<script>

    import ProjectModal from '@/components/finance/ProjectModal'
    import ExpenseModal from '@/components/finance/ExpenseModal'

    export default {

        components: {
            ProjectModal,
            ExpenseModal
        },

        data() {
            return {
                projects: []
            };
        },

        mounted() {
            this.loadMainProjects()
        },

        updated() {
           $('.button').popup();
        },

        methods: {

            loadMainProjects() {
                this.loader = this.$loading.show()
                this.$http.get('finance/projects/').then(response => {
                    this.projects = response.data
                    this.loader.hide()
                });
            },

            showAddProjectModal(index)
            {
                this.$refs.projectModal.show();
            },

            createdProject(project)
            {
                this.projects.push(project)
            },

            deleteProject(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                this.$http.delete('finance/projects/' + this.projects[index].id + '/').then(response => {
                    this.projects.splice(index, 1)
                    this.loader.hide()
                });
            },

        }
    }
</script>
