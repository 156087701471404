<template>
    <div :contenteditable="isEditable" @input="$emit('update:content', $event.target.innerText)"></div>
</template>

<script>
    export default {
        props: ['content', 'editable'],

        mounted: function () {
            this.$el.innerText = this.content;
        },

        data() {
            return {
                isEditable: false,
            };
        },

        watch: {
            content: function () {
                this.$el.innerText = this.content;
            },

            editable: function () {
                this.isEditable = this.editable;
                console.log(this.isEditable)
            }
        }
    }
</script>
