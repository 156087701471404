<template>
    <div>
        <table class="ui celled table">
            <thead>
            <tr>
                <th>Domain</th>
                <th>Domain rating</th>
                <th>Ahrefs rank</th>
                <th>Total keywords</th>
                <th>Total traffic</th>
                <th>Company</th>
                <th>Country</th>
                <th>Avg app spend</th>
                <th>Established</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(domain, index) in domains" v-bind:key="domain.id">
                <td>{{ domain.website }}</td>
                <td>{{ domain.domain_rating }}</td>
                <td>{{ domain.ahrefs_rank }}</td>
                <td>{{ domain.total_keywords }}</td>
                <td>{{ domain.total_traffic }}</td>
                <td>{{ domain.company }}</td>
                <td>{{ domain.country }}</td>
                <td>{{ domain.avg_app_spend }}</td>
                <td>
                    <div class="inline field">
                        <div class="ui toggle checkbox" @click="setEstablished(index)">
                            <input type="checkbox" tabindex="0" :checked="domain.is_established" class="hidden">
                            <label></label>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="ui small basic icon buttons">
                        <router-link :to="'/domains/' + domain.id" class="ui button"><i class="eye icon"></i></router-link>
                        <!--
                        <button class="ui button" @click="showDetailsDomainModal(index)"><i class="eye icon"></i>
                        </button>
                        -->
                        <button class="ui button" @click="showEditDomainModal(index)"><i class="edit icon"></i></button>
                        <button class="ui button" @click="removeDomain(index)"><i class="delete icon"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <DomainEditModal ref="domainEditModal" :domainId="domainObj.id" @saved="savedDomain"/>

        <DomainAddToListModal ref="domainAddToListModal" :domain="domainObj"/>
    </div>
</template>

<script>
    import DomainDetailsModal from './DomainDetailsModal'
    import DomainAddToListModal from './DomainAddToListModal'
    import DomainEditModal from './DomainEditModal'

    export default {

        components: {
            DomainAddToListModal,
            DomainEditModal
        },

        props: ['listId', 'data'],

        data() {
            return {
                domains: this.data,
                selectedDomainIndex: '',
                domainObj: {
                    id: '',
                    website: '',
                    domain_rating: '',
                    ahrefs_rank: '',
                    domains: '',
                    total_keywords: '',
                    total_traffic: '',
                    website_location: '',
                    company: '',
                    category: '',
                    similarweb: '',
                    cat: '',
                    cat2: '',
                    robs: '',
                    quantcast: '',
                    alexa: '',
                    twitter: '',
                    facebook: '',
                    linkedin: '',
                    google: '',
                    pinterest: '',
                    github: '',
                    instagram: '',
                    vk: '',
                    youtube: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    avg_app_spend: '',
                }
            };
        },

        watch: {
            data: function (val) {
                this.domains = val
            },
        },

        methods: {

            setEstablished(index) {
                this.$http.post('domains/' + this.domains[index].id + '/set_established/').then(response => {
                    this.domains[index].is_established = !this.domains[index].is_established
                });
            },

            showDetailsDomainModal(index) {
                this.selectedDomainIndex = index
                this.domainObj = this.domains[index]
                this.$refs.domainDetailsModal.show();
            },

            showEditDomainModal(index) {
                this.selectedDomainIndex = index
                this.domainObj = this.domains[index]
                this.$refs.domainEditModal.show();
            },

            showAddToListModal(index) {
                this.selectedDomainIndex = index
                this.domainObj = this.domains[index]
                this.$refs.domainAddToListModal.show();
            },

            removeDomain(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                let data = {'domain_id': this.domains[index].id}

                this.$http.post('lists/' + this.listId + '/remove_domain/', data).then(response => {
                    this.domains.splice(index, 1)
                });
            },

            savedDomain(domain) {
                this.$set(this.domains, this.selectedDomainIndex, domain)
            },

        }
    }
</script>
