<template>
    <div>

        <div class="ui grid two column row">
          <div class="column left aligned">
            <div class="ui text container">
              <h1 class="ui header left aligned" style="display: inline">Established ({{ establishedDomainsCount }})</h1>
              &nbsp;
              <div class="ui icon input">
                <input
                  type="number"
                  placeholder="rating"
                  class="ui input"
                  v-model="ratingFilter"
                  @change="loadDomains"
                >
                <i class="search icon"></i>
              </div>
              &nbsp;
              <div class="ui icon input">
                <input
                  type="number"
                  placeholder="traffic"
                  class="ui input"
                  v-model="trafficFilter"
                  @change="loadDomains"
                >
                <i class="search icon"></i>
              </div>
              &nbsp;
            </div>
          </div>
          <div class="column right aligned">
              <div class="ui toggle checkbox" @click="hasArticlesFilter = !hasArticlesFilter">
                  <input type="checkbox" tabindex="0" :checked="hasArticlesFilter" class="hidden">
                  <label>Has articles</label>
              </div>
              &nbsp;
              <button class="ui button" @click="showAddModal"><i class="plus icon"></i>Add website from the outside</button>
              <button class="ui button" @click="showAddArticleModal"><i class="plus icon"></i>Add article</button>
          </div>
        </div>

         <div class="ui secondary pointing menu">
            <a :class="!articlesActive ? 'item active' : 'item'" @click="articlesActive=false">
                Established domains
            </a>
            <a :class="articlesActive ? 'item active' : 'item'" @click="articlesActive=true">
                Published articles
            </a>
        </div>

        <br/>

        <div class="ui text container" v-if="!articlesActive">
            <paginate v-if="pagesCount > 1"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>

        <br/>

        <DomainsEstablishedTable v-if="!articlesActive" :data="domains" type="established"></DomainsEstablishedTable>
        <ArticlesPublishedTable v-if="articlesActive"></ArticlesPublishedTable>

        <br/>

        <div class="ui text container" v-if="!articlesActive">
            <paginate v-if="pagesCount > 1"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>


        <br/>
        <AddOutsideDomainModal ref="AddOutsideDomainModal" ></AddOutsideDomainModal>
        <AddArticleModal ref="addArticleModal" ></AddArticleModal>
        <loading :active.sync="isLoading"></loading>
    </div>
</template>

<script>
    import CategorySelect from '@/components/CategorySelect'
    import DomainsEstablishedTable from '@/components/DomainsEstablishedTable'
    import ArticlesPublishedTable from '@/components/ArticlesPublishedTable'
    import ImportResultsModal from '@/components/ImportResultsModal'
    import AddOutsideDomainModal from '@/components/AddOutsideDomainModal'
    import AddArticleModal from '@/components/AddArticleModal'

    export default {

        components: {
            CategorySelect,
            DomainsEstablishedTable,
            AddOutsideDomainModal,
            ArticlesPublishedTable,
            AddOutsideDomainModal,
            AddArticleModal,
        },

        data() {
            return {
                isLoading: false,
                isEstablished: false,
                articlesActive: true,
                domains: [],
                domainsOnPage: 100,
                pagesCount: 0,
                trafficFilter: null,
                ratingFilter: null,
                hasArticlesFilter: false,
                establishedDomainsCount: 0,
            };
        },

        mounted() {
            this.loadDomains()
        },

        methods: {

            loadDomains(offset = 0) {

                this.isLoading = true

                let url = 'domains/established/'

                // paging
                url += '?limit=' + this.domainsOnPage + '&offset=' + offset
                if(this.ratingFilter){
                  url += '&rating=' + this.ratingFilter
                }
                if(this.trafficFilter){
                  url += '&traffic=' + this.trafficFilter
                }

                this.$http.get(url).then(response => {
                    this.domains = response.data.results
                    this.pagesCount = Math.ceil(response.data.count / this.domainsOnPage)
                    this.establishedDomainsCount = response.data.count
                    this.isLoading = false
                })
            },


            showEstablished() {
                this.isEstablished = !this.isEstablished
                this.loadDomains()
            },

            selectPage(pageNum) {
                let offset = (pageNum - 1) * 100
                this.loadDomains(offset)
            },
            showAddArticleModal() {
              this.$refs.addArticleModal.show()
            },
            showAddModal() {
              this.$refs.AddOutsideDomainModal.show()
            },
        }
    }
</script>
