<template>
    <sui-modal v-model="open" :closeIcon="true">
        <sui-modal-header>Add to mail list</sui-modal-header>
        <sui-modal-content>
            <div class="ui form">
                <div :class="(error && !listId) ? 'field error' : 'field'" v-if="mailListId == null">
                    <label>Select mail list</label>
                    <MailListsSelect @selected="selectedMailList"></MailListsSelect>
                </div>
                <div :class="(error && !contact) ? 'field error' : 'field'">
                    <label>Select email</label>
                    <EmailsSelect ref="addToMailListEmailsSelect"
                            :domain="domain.id"
                            @selected="selectedContact"
                            @loaded="loadedContacts"
                            :populate="loadContacts"></EmailsSelect>
                </div>
                <div class="field" v-if="invalidEmail">
                    <h3>Invalid e-mail!</h3>
                </div>
            </div>
            <div class="ui message error" v-if="fetchError">
                <div class="ui header">Fetch error</div>
                <p>{{ fetchError }}</p>
            </div>
            <div class="ui message error" v-if="checkEmailError">
                <div class="ui header">Check email error</div>
                <p>{{ checkEmailError }}</p>
            </div>
            <div class="ui message error" v-if="postError">
                <div class="ui header">Add recipient error</div>
                <p>{{ postError }}</p>
            </div>
        </sui-modal-content>
        <sui-modal-actions>
            <div class="ui black deny button" @click="open=false">
                Cancel
            </div>
            <div v-if="filter == 'invalid' || filter == 'all'" class="ui yellow button" @click="sendToTrash">
                Trash
            </div>
            <div class="ui right labeled icon positive button" @click="addToMailList">
                Save<i class="checkmark icon"></i>
            </div>
        </sui-modal-actions>
    </sui-modal>
</template>

<script>
    import MailListsSelect from '@/components/MailListsSelect'
    import EmailsSelect from '@/components/EmailsSelect'

    export default {
        components: {
            MailListsSelect,
            EmailsSelect
        },

        props: ['domain', 'mailListId', 'filter'],

        data() {
            return {
                open: false,
                confirmed: true,


                loader: null,
                loader1: null,

                loadContacts: false,
                domainId: null,
                listId: null,
                contact: null,
                selectedList: null,
                invalidEmail: false,

                error: false,
                fetchError: false,
                checkEmailError: true,
                postError: false,
            };
        },

        watch: {
            domain: function (domain) {
                this.domainId = domain.id;

                if (!domain.checked_at || (domain.checked_at && domain.checked_result == ''))
                    this.fetchContactsData()
                else
                    this.loadContacts = true
            },

            mailListId: function (val) {
                this.listId = val;
            },

            open: function (val) {
                if (val == false)
                    this.$refs.addToMailListEmailsSelect.contacts = []
            }
        },

        methods: {

            confirm () {
                this.confirmed = true
                this.open = false
            },

            show()
            {
                this.error = false
                this.fetchError = false
                this.checkEmailError = false
                this.postError = false

                this.open = true
            },

            addToMailList()
            {
                if (!this.listId || !this.contact)
                {
                    this.error = true
                    return
                }

                this.error = false

                this.sendPostData()

                /*
                if (this.contact.is_checked && this.contact.is_valid) {
                    this.sendPostData()
                    return
                }

                this.loader = this.$loading.show()

                this.$http.get('contacts/' + this.contact.id + '/check_email/').then(response => {
                    this.loader.hide()

                    if (response.data.is_valid === true) {
                        this.sendPostData()
                    }
                    else
                    {
                        this.invalidEmail = true
                        this.loadContacts = true
                    }

                }).catch(error => {
                    this.checkEmailError = error.response.data
                    console.log(error.response.data)
                    this.loader.hide()

                }).then(response => {

                })
                */
            },

            sendPostData()
            {
                let data = {'contact_id': this.contact.id}

                this.loader = this.$loading.show()

                this.$http.post('mail-lists/' + this.listId + '/add_recipient/', data).then(response => {

                    this.loader.hide()

                    this.$emit('added')

                    this.open = false
                })
                .catch(error => {

                    if (error.response.data.error)
                        this.postError = error.response.data.error
                    else
                        this.postError = error.response.data

                    console.log(error.response.data)

                    this.loader.hide()
                })
            },

            selectedMailList(listId)
            {
                this.listId = listId
            },

            selectedContact(contact)
            {
                this.invalidEmail = false
                this.contact = contact
            },

            fetchContactsData()
            {
                this.loader = this.$loading.show()

                this.$http.get('domains/' + this.domainId + '/fetch_contacts_data/').then(response => {
                    this.$emit('fetched', response.data)

                }).catch(error => {
                    this.fetchError = error.response.data
                    console.log(error.response.data)

                }).then(response => {
                    this.loader.hide()
                    this.loadContacts = true
                })
            },

            loadedContacts()
            {
                this.loadContacts = false
            },

            sendToTrash()
            {
                this.loader = this.$loading.show()

                this.$http.post('domains/' + this.domainId + '/set_trash/').then(response => {
                    this.$emit('send_to_trash')
                    this.open = false
                }).catch(error => {
                    console.log(error.response.data)
                }).then(response => {
                    this.loader.hide()
                })
            }
        }
    }
</script>
