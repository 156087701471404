<template>
    <div v-if="campaign">
        <h1 class="ui header">Campaign "{{ campaign.name }}"</h1>

        <table class="ui celled table">
            <thead>
            <tr>
                <th rowspan="2">Mail list name</th>
                <th>Recipients</th>
                <th>Outreach progress</th>
                <th>Follow up progress</th>
                <th>Opened</th>
                <th>Replied</th>
                <th>Conversions</th>
                <th>Campaign target</th>
                <th rowspan="2">Status</th>
                <th rowspan="2">Outreach AB</th>
                <th rowspan="2">Follow up AB</th>
                <th rowspan="2">Archived</th>
                <th rowspan="2">Actions</th>
            </tr>
            <tr>
                <th>{{ campaign.recipients_count }}</th>
                <th>{{ campaign.send_outreach_count }} ({{ calculatePercent(campaign.send_outreach_count, campaign.recipients_count) }}%)</th>
                <th>{{ campaign.send_follow_up_count }} ({{ calculatePercent(campaign.send_follow_up_count, campaign.recipients_count) }}%)</th>
                <th>{{ campaign.opened_count }} ({{ calculatePercent(campaign.opened_count, campaign.recipients_count) }}%)</th>
                <th>{{ campaign.replied_count }} ({{ calculatePercent(campaign.replied_count, campaign.recipients_count) }}%)</th>
                <th>{{ campaign.conversions_count }} ({{ calculatePercent(campaign.conversions_count, campaign.recipients_count) }}%)</th>
                <th>{{ campaign.target_goal }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(list, index) in lists" :key="list.id">
                <td>
                    <router-link :to="'/mail-lists/' + list.id" class="item">{{ list.name }}</router-link>
                </td>
                <td>{{ list.recipients_count }}</td>
                <td>{{ list.send_outreach_count }} ({{ calculatePercent(list.send_outreach_count, list.recipients_count) }}%)</td>
                <td>{{ list.send_follow_up_count }} ({{ calculatePercent(list.send_follow_up_count, list.recipients_count) }}%)</td>
                <td>{{ list.opened_count }} {{ calculatePercent(list.opened_count, list.recipients_count) }}%</td>
                <td>{{ list.replied_count }} ({{ calculatePercent(list.replied_count, list.recipients_count) }}%)</td>

                <td>{{ list.conversions_count }} ({{ calculatePercent(list.conversions_count, list.recipients_count) }}%)</td>
                <td></td>
                <td>
                    <div class="ui toggle checkbox" @click="setStatus(index)">
                        <input type="checkbox" tabindex="0" :checked="list.is_active" class="hidden">
                        <label></label>
                    </div>
                </td>
                <td>{{ list.outreach_ab_is_enabled }}</td>
                <td>{{ list.follow_up_ab_is_enabled }}</td>
                <td>{{ list.is_archive}}</td>
                <td>
                    <div class="ui small basic icon buttons">
                        <router-link :to="'/mail-lists/' + list.id" class="ui button" data-content="Show list"><i class="eye icon"></i>
                        </router-link>
                        <button class="ui button" @click="showEditMailListModal(index)" data-content="Edit"><i class="edit icon"></i></button>
                        <a v-if="($user && $user.name === 'alex') || ($user && $user.name === 'grintender')" :href="'/api/mail-lists/' + list.id + '/export_domains/'" class="ui button" data-content="Download domains"><i class="download icon"></i></a>
                        <a v-if="($user && $user.name === 'alex') || ($user && $user.name === 'grintender')" :href="'/api/mail-lists/' + list.id + '/export_contacts/'" class="ui button" data-content="Download contacts"><i class="download icon"></i></a>
                        <button class="ui button" @click="deleteList(index)" data-content="Delete list"><i class="trash icon"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th><strong><a :href="campaign.trello_link" target="_blank">Trello board link</a></strong></th>
                    <th><strong><a :href="campaign.brief_link" target="_blank">Brief doc</a></strong></th>
                    <th></th>
                    <th><strong>Deadline: {{ campaign.end_at | moment("DD.MM.YYYY")  }}</strong></th>
                    <th colspan="10"><strong>days left: {{ campaign.days_left }}</strong></th>
                </tr>
            </tfoot>
        </table>
        <div class="inline field">
            <button class="ui button" @click="showAddMailListModal" type="submit">Add list</button>
        </div>
        <MailListModal ref="mailListModal" :list="selectedList" @saved="savedMailList"></MailListModal>
    </div>
</template>

<script>

    import MailListModal from '@/components/MailListModal'

    export default {

        components: {
            MailListModal
        },

        data() {
            return {
                campaign: null,
                campaignId: null,
                loader: false,
                lists: [],
                listObj: {
                    'name': ''
                },
                selectedListIndex: null,
                selectedList: null
            };
        },

        mounted() {

            this.campaignId = this.$route.params.id

            this.loader = this.$loading.show()

            this.$http.get('campaigns/' + this.campaignId).then(response => {
                this.campaign = response.data
                this.lists = response.data.mail_lists
                this.loader.hide()
            });
        },

        updated() {
            // Semantic popup
            $('.button').popup();
        },

        methods: {

            showAddMailListModal()
            {
                this.selectedListIndex = null
                this.selectedList = null
                this.$refs.mailListModal.show()
                this.$refs.mailListModal.listObj.campaign_id = this.campaignId
            },

            showEditMailListModal(index)
            {
                this.selectedListIndex = index
                this.$refs.mailListModal.show()
                this.selectedList = Object.assign({}, this.lists[index])
            },

            savedMailList(list)
            {
                if (!this.selectedList)
                    this.lists.push(list)
                else {
                    let updatedList = this.lists[this.selectedListIndex]

                    if (updatedList.campaign_id !== list.campaign_id)
                        this.lists.splice(this.selectedListIndex, 1)
                    else
                        this.$set(this.lists, this.selectedListIndex, list)

                    this.selectedListIndex = null
                    this.selectedList = null
                }
            },

            deleteList(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()
                this.$http.delete('mail-lists/' + this.lists[index].id).then(response => {
                    this.lists.splice(index, 1)
                    this.loader.hide()
                });
            },

            setStatus(index) {
                this.loader = this.$loading.show()

                this.$http.post('mail-lists/' + this.lists[index].id + '/set_status/').then(response => {
                    this.lists[index].is_active = !this.lists[index].is_active

                    this.loader.hide()
                });
            },

            calculatePercent(val, total)
            {
                if (total == 0)
                    return (0).toFixed(2)

                val = parseFloat(val)
                total = parseFloat(total)

                return (val * 100 / total).toFixed(2)
            },
        }
    }
</script>
