<template>
    <div id="editModal" class="ui modal" v-if="domain">
        <i class="close icon"></i>
        <div class="header">
            Edit domain {{ domain.website }}
        </div>
        <div class="content">
            <form class="ui form">
                <div class="field">
                    <label>Domain rating</label>
                    <input type="text" v-model="domain.domain_rating">
                </div>
                <div class="field">
                    <label>Ahrefs Rank</label>
                    <input type="text" v-model="domain.ahrefs_rank">
                </div>
                <div class="field">
                    <label>Domains</label>
                    <input type="text" v-model="domain.domains">
                </div>
                <div class="field">
                    <label>Total Keywords</label>
                    <input type="text" v-model="domain.total_keywords">
                </div>
                <div class="field">
                    <label>Total Traffic</label>
                    <input type="text" v-model="domain.total_traffic">
                </div>
                <div class="field">
                    <label>Website Location</label>
                    <input type="text" v-model="domain.website_location">
                </div>
                <div class="field">
                    <label>Company</label>
                    <input type="text" v-model="domain.company">
                </div>
                <div class="field">
                    <label>Category</label>
                    <input type="text" v-model="domain.category">
                </div>
                <div class="field">
                    <label>similarweb</label>
                    <input type="text" v-model="domain.similarweb">
                </div>
                <div class="field">
                    <label>cat</label>
                    <input type="text" v-model="domain.cat">
                </div>
                <div class="field">
                    <label>cat2</label>
                    <input type="text" v-model="domain.cat2">
                </div>
                <div class="field">
                    <label>rob's</label>
                    <input type="text" v-model="domain.robs">
                </div>
                <div class="field">
                    <label>Quantcast</label>
                    <input type="text" v-model="domain.quantcast">
                </div>
                <div class="field">
                    <label>Alexa</label>
                    <input type="text" v-model="domain.alexa">
                </div>
                <div class="field">
                    <label>Twitter</label>
                    <input type="text" v-model="domain.twitter">
                </div>
                <div class="field">
                    <label>Facebook</label>
                    <input type="text" v-model="domain.facebook">
                </div>
                <div class="field">
                    <label>LinkedIn</label>
                    <input type="text" v-model="domain.linkedin">
                </div>
                <div class="field">
                    <label>Google</label>
                    <input type="text" v-model="domain.google">
                </div>
                <div class="field">
                    <label>Pinterest</label>
                    <input type="text" v-model="domain.pinterest">
                </div>
                <div class="field">
                    <label>GitHub</label>
                    <input type="text" v-model="domain.github">
                </div>
                <div class="field">
                    <label>Instagram</label>
                    <input type="text" v-model="domain.instagram">
                </div>
                <div class="field">
                    <label>VK</label>
                    <input type="text" v-model="domain.vk">
                </div>
                <div class="field">
                    <label>Youtube</label>
                    <input type="text" v-model="domain.youtube">
                </div>
                <div class="field">
                    <label>City</label>
                    <input type="text" v-model="domain.city">
                </div>
                <div class="field">
                    <label>State</label>
                    <input type="text" v-model="domain.state">
                </div>
                <div class="field">
                    <label>Zip</label>
                    <input type="text" v-model="domain.zip">
                </div>
                <div class="field">
                    <label>Country</label>
                    <input type="text" v-model="domain.country">
                </div>
                <div class="field">
                    <label>Avg. App Spend</label>
                    <input type="text" v-model="domain.avg_app_spend">
                </div>
            </form>
        </div>
        <div class="actions">
            <div class="ui black deny button">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click.stop="saveData()">
                Save
                <i class="checkmark icon"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: ['domainId'],

        data() {
            return {
                domain: null,
            };
        },

        watch: {
            domainId: function (val) {
                this.loadDomain()
            },
        },
        
        methods: {
            show()
            {
                $('#editModal').modal('show');
            },

            loadDomain() {
                this.$http.get('domains/' + this.domainId + '/').then(response => {
                    this.domain = response.data
                });
            },

            saveData() {
                this.$http.put('domains/' + this.domain.id + '/', this.domain).then(response => {
                    this.$emit('saved', this.domain)
                    $('#editModal').modal('hide')
                });
            },
        }
    }
</script>
