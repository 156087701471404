<template>
    <div class="ui container">

       <div class="ui secondary pointing menu">
          <a :class="!showSavedSearches && !showRandomUnlinked ? 'item active' : 'item'" @click="showSavedSearches=false; showRandomUnlinked=false">
              Search
          </a>
          <a :class="showSavedSearches && !showRandomUnlinked ? 'item active' : 'item'" @click="showSavedSearches=true; showRandomUnlinked=false">
              Saved selects
          </a>
          <a :class="showRandomUnlinked ? 'item active' : 'item'" @click="showSavedSearches=true; showRandomUnlinked=true">
              Random unlinked domains
          </a>
      </div>
      <div class="ui grid direct-queries" v-if="!showSavedSearches && !showRandomUnlinked">
        <div class="ui grid padded two column row">
            <div class="column left aligned">
                <h1 class="ui header">Direct queries</h1>
            </div>
            <div class="column right aligned"> </div>
        </div>
        <div class="ui grid three column row">
            <div class="column"></div>
            <div class="column">
                <h4>SELECT * FROM app_domains WHERE description </h4>
            </div>
            <div class="column"></div>
        </div>
        <div class="ui grid three column row">
            <div class="column"></div>
            <div class="column">  
              <div class="ui input fluid huge labeled">
                <input type="text" v-model="code" placeholder="LIKE '%keyword%'" />
              </div>
            </div>
            <div class="column"></div>
        </div>
        <div class="ui row">
            <div class="column">  
              <div class="ui input">
                <button class="ui button" @click="sendData()" data-content="Search"><i class="search icon"></i>Search</button>
              </div>
            </div>
        </div>
        <div class="ui row">
            <div class="column">  
              <div class="ui basic large icon">
                <button class="ui button" @click="showModal()" data-content="Save"><i class="save icon"></i>Save query</button>
                <button class="ui button" @click="clearForm()" data-content="Reset"><i class="trash icon"></i>Reset</button>
              </div>
            </div>
        </div>
        <div
           class="ui grid padded row"
           v-if="querySample.length > 0"
        >
          <h3>Results count: {{ queryCount }}</h3>
          <table class="ui table celled striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Domain</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="domain in querySample">
                <td>{{ domain[0] }}</td>
                <td>{{ domain[1] }}</td>
                <td>{{ domain[2] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="ui grid saved-queries" v-if="showSavedSearches && !showRandomUnlinked">
        <div class="ui grid padded two column row">
            <div class="column left aligned">
                <h1 class="ui header">Saved queries</h1>
            </div>
        </div>
        <div class="ui grid padded row">
          <table class="ui table celled striped ">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Query</th>
                <th>Count</th>
                <th>Operations</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="query in savedQueries">
                <td>{{ query.fields.id }}</td>
                <td>{{ query.fields.name }}</td>
                <td>{{ query.fields.query }}</td>
                <td>{{ query.fields.result_count }}</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="ui grid saved-queries" v-if="showRandomUnlinked">
        <div class="ui grid padded two column row">
            <div class="column left aligned">
                <h1 class="ui header">Random unlinked</h1>
            </div>
        </div>
        <div class="ui grid padded row">
          <table class="ui table celled striped ">
            <thead>
              <tr>
                <th>#</th>
                <th>Domain</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="randomDomain in randomUnlinked">
                <td>{{ randomDomain[0] }}</td>
                <td>{{ randomDomain[1] }}</td>
                <td>{{ randomDomain[2] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <sui-modal v-model="open" :closeIcon="true">

          <sui-modal-header>Save current query</sui-modal-header>

          <sui-modal-content >
              <div class="ui grid form">

                  <div class="row">
                    <div class="eight wide column">
                        <div class="field">
                        <h4>{{ code }}</h4>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="eight wide column">
                        <div class="field">
                            <label>Name this query</label>
                            <input type="text" v-model="queryName">
                        </div>
                    </div>
                  </div>

              </div>
          </sui-modal-content>

          <sui-modal-actions>
              <div class="ui black deny button" @click="open=false">
                  Cancel
              </div>
              <div
                  class="ui right labeled icon positive button"
                  
                  @click="saveQuery"
              >
                  Save
                  <i class="checkmark icon"></i>
              </div>
          </sui-modal-actions>
      </sui-modal>
    </div>
</template>

<script>

    export default {
        data() {
            return {
              showSavedSearches: false,
              showRandomUnlinked: false,
              // showRandomUnlinked: true,
              loader: null,
              // code: "LIKE '%keyword%'",
              code: "LIKE '%volvo%'",
              randomUnlinked: [],
              savedQueries: [],
              querySample: [],
              queryCount: 0, 
              open: false,
              queryName: ''
            };
        },

        mounted() {
          this.getSavedQueries()
        },

        updated() {
        },

        methods: {
          showModal()
          {
              if(this.querySample != "LIKE '%keyword%'" && this.querySample != '' && this.queryCount != 0) { 
                this.error = false
                this.open = true
              }
          },
          saveQuery() {
            this.loader = this.$loading.show()
            var $this = this
            var data = new FormData()
            data.append('query-name', this.queryName);
            data.append('code', this.code);
            data.append('query-count', this.queryCount);
            this.$http.post('v2-run-query/', data).then(response => {
              $this.querySample = []
              $this.queryCount = []
            }).catch(error => {
                console.log(this.errors)
            }).then(response => {
                this.open = false
                this.loader.hide()
            })
          },
          getSavedQueries() {
            this.loader = this.$loading.show()
            var $this = this
            var data = new FormData()
            data.append('get-saved-queries', 'all');
            this.$http.post('v2-run-query/', data).then(response => {
              $this.savedQueries = response.data
            }).catch(error => {
                console.log(this.errors)
            }).then(response => {
                this.loader.hide()
                this.getRandomUnlinked()
            })
          },
          getRandomUnlinked() {
            this.loader = this.$loading.show()
            var data = new FormData()
            data.append('get-random-queries', 'all');
            this.$http.post('v2-run-query/', data).then(response => {
              this.randomUnlinked = response.data.domains_sample
              this.$forceUpdate()
            }).catch(error => {
                console.log(this.errors)
            }).then(response => {
                this.loader.hide()
            })
          },
          sendData() {
            this.loader = this.$loading.show()
            var $this = this
            var data = new FormData()
            data.append('code', this.code);
            this.$http.post('v2-run-query/', data).then(response => {
                $this.querySample = response.data.domains_sample
                $this.queryCount = response.data.count
            }).catch(error => {
                console.log(this.errors)
            }).then(response => {
                this.loader.hide()
            })
          },
          clearForm() {
              this.code = "LIKE '%keyword%'"
          }
        }
    }
</script>
