<template>
    <div class="ui container">
        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">DA Enrichment</h1>
            </div>
            <!--
            <div class="column right aligned">
                <div class="field">
                    <div class="ui toggle checkbox" @click="empty = !empty">
                        <input type="checkbox" tabindex="0" :checked="empty" class="hidden">
                        <label>Only empty</label>
                    </div>
                </div>
            </div>
            -->
        </div>

        <br>

        <div class="ui container">
            <paginate v-if="pagesCount > 1"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>

        <br>

        <table class="ui celled table">
            <thead>
            <tr>
                <th>Website</th>
                <th>Domains</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(domain, index) in domains" v-bind:key="domain.id">
                <td><a :href="'http://' + domain.website" target="_blank">{{ domain.website }}</a></td>
                <td>{{ domain.domains }}</td>

                <td>
                    <div class="ui small basic icon buttons action-buttons">
                        <a :href="'//ahrefs.com/site-explorer/referring/v2/domains/subdomains/live/all/all/1/domain_rating_desc?target=' + domain.website" class="ui button" data-content="Get data" target="_blank"><i class="share icon"></i></a>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <br>

        <div class="ui container">
            <paginate v-if="pagesCount > 1"
                      :page-count="pagesCount"
                      :page-range="7"
                      :click-handler="selectPage"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :no-li-surround="true"
                      :page-link-class="'ui button'"
                      :next-link-class="'ui button'"
                      :prev-link-class="'ui button'"
                      :container-class="'ui buttons'">
            </paginate>
        </div>
    </div>
</template>

<script>

    import ImportHubspotData from '@/components/ImportHubspotData'

    export default {

        components: {
            ImportHubspotData,
        },

        data() {
            return {
                loader: null,
                domains: [],
                domainsOnPage: 100,
                pagesCount: 0,
                empty: false
            };
        },

        mounted() {
            this.loadDomains()
        },

        updated() {
            // Semantic popup
            $('.button').popup();
        },

        watch: {
            empty: function (val) {
                this.loadDomains()
            },
        },

        methods: {

            loadDomains(offset = 0) {

                this.loader = this.$loading.show()

                // paging
                let url = 'domains/by_domains/?limit=' + this.domainsOnPage + '&offset=' + offset


                if (this.empty === true)
                    url += '&empty=1'

                this.$http.get(url).then(response => {
                    this.domains = response.data.results
                    this.pagesCount = Math.ceil(response.data.count / this.domainsOnPage)

                    this.loader.hide()
                })
            },

            selectPage(pageNum) {
                let offset = (pageNum - 1) * 100
                this.loadDomains(offset)
            }
        }
    }
</script>
