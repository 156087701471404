<template>
    <div class="ui container">
      <div class="ui grid two column row">
          <div class="column left aligned">
              <h1 class="ui header">Mail logs</h1>
          </div>
          <div class="column right aligned">
          </div>
        </div>
          <!--  subjects table goes here  -->
          <table class="ui celled table">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Type</th>
                  <th>Recipient domain</th>
                  <th>Sent from</th>
                  <th>Status</th>
                  <th>Sent at</th>
                  <th>Spam complaint</th>
                  <th>Delivired</th>
                  <th>Opened</th>
                  <th>Replised</th>
                  <th>Established</th>
                  <th>Published</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(logEntry, index) in mailLogs" :key="logEntry.id">
                  <td>{{ logEntry.email  }}</td>
                  <td>{{ logEntry.type }}</td>
                  <td>{{ logEntry.recipient_domain }}</td>
                  <td>{{ logEntry.sent_from }}</td>
                  <td>{{ logEntry.status }}</td>
                  <td>{{ logEntry.created_at }}</td>
                  <td>{{ logEntry.spam_complaint }}</td>
                  <td>{{ logEntry.delivered }}</td>
                  <td>{{ logEntry.opened }}</td>
                  <td>{{ logEntry.replied }}</td>
                  <td>{{ logEntry.established }}</td>
                  <td>{{ logEntry.published }}</td>
                </tr>
              </tbody>
          </table>
    </div>
</template>

<script>

    export default {

        data() {
            return {
                loader: null,
                mailLogs: [],
            };
        },

        mounted() {
          this.loader = this.$loading.show()
          this.getMailLogs()
        },

        updated() {
        },

        methods: {
          getMailLogs() {
            this.$http.get('v2-logs/?limit=10').then(response => {
                this.mailLogs = response.data
                this.$forceUpdate() // otherwise the array doesn't get updated and UI table stays empty
                this.loader.hide()
            });
          },
        }
    }
</script>
