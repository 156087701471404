<template>
    <sui-modal v-model="open" size="large" :closeIcon="true" v-if="template">

        <sui-modal-header v-if="!template.id">Add template</sui-modal-header>
        <sui-modal-header v-else>Edit template {{ template.name }}</sui-modal-header>

        <sui-modal-content scrolling>
            <div class="ui form">
                <div :class="!template.name && error ? 'field error' : 'field'">
                    <label>Name</label>
                    <input type="text" v-model="template.name">
                </div>

                <div class="field">
                    <select class="ui fluid dropdown" v-model="template.type">
                        <option value="1">Outreach</option>
                        <option value="2">Follow up</option>
                    </select>
                </div>

                <div :class="textError ? 'field error' : 'field'">
                    <label>Body</label>
                    <ckeditor :editor="editor" v-model="template.body" :config="editorConfig"></ckeditor>
                </div>

                <div class="field">
                    <label>Description</label>
                    <input type="text" v-model="template.description">
                </div>
            </div>
        </sui-modal-content>

        <sui-modal-actions>
            <div class="ui grid">
                <div class="twelve wide column">
                    <div v-if="textError" style="color: #9f3a38; padding-top: 10px;">This template won't work. Struck through keywords will lead this email to spam. Find synonyms, please.</div>
                </div>
                <div class="four wide column right aligned">
                    <div class="ui black deny button" @click="open=false">
                        Cancel
                    </div>
                    <div class="ui right labeled icon positive button" @click="saveTemplate">
                        Save<i class="checkmark icon"></i>
                    </div>
                </div>
            </div>
        </sui-modal-actions>
    </sui-modal>
</template>

<script>

    //import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

    import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
    import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
    import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
    import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
    import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
    import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';

    export default {

        data() {
            return {
                open: false,

                template: null,

                defaultParams: {
                    name: '',
                    body: '',
                    description: '',
                    type: 1,
                },

                loader: null,
                error: false,
                textError: false,

                editor: ClassicEditor,
                editorConfig: {


                    plugins: [
                        EssentialsPlugin,
                        BoldPlugin,
                        ItalicPlugin,
                        Strikethrough,
                        LinkPlugin,
                        ParagraphPlugin,
                    ],


                    toolbar: {
                        items: [
                            'bold',
                            'italic',
                            'link',
                            'undo',
                            'redo'
                        ]
                    }

                    /*
                    toolbar: {
                        items: [
                            'heading',
                            'bold',
                            'italic',
                            'link',
                            'undo',
                            'redo',
                        ]
                    }
                    */
                },
            };
        },

        mounted() {
            this.template = this.defaultParams
        },

        methods: {
            show()
            {
                this.template = Object.assign({}, this.defaultParams)
                this.error = false

                this.open = true
            },

            saveTemplate()
            {
                if (this.template.id)
                    this.updateTemplate()
                else
                    this.createTemplate()
            },

            createTemplate()
            {

                if (!this.template.name)
                {
                    this.error = true
                    return
                }

                this.error = false
                this.textError = false

                this.loader = this.$loading.show()

                this.$http.post('templates/', this.template).then(response => {
                    this.$emit('created', response.data)
                    this.open = false

                }).catch(error => {
                    if (error.response.data.checked_text)
                    {
                        console.log(error.response.data.checked_text)
                        this.template.body = error.response.data.checked_text
                        this.textError = true
                    }

                }).then(response => {
                    this.loader.hide()
                })
            },

            updateTemplate()
            {
                this.loader = this.$loading.show()

                this.textError = false

                this.$http.patch('templates/' + this.template.id + '/', this.template).then(response => {
                    this.$emit('updated', response.data)
                    this.open = false

                }).catch((error) => {
                    if (error.response.data.checked_text)
                    {
                        console.log(error.response.data.checked_text)
                        this.template.body = error.response.data.checked_text
                        this.textError = true
                    }

                }).then(response => {
                    this.loader.hide()
                })
            },

            editorInit: function () {
                require('brace/ext/language_tools') //language extension prerequsite...
                require('brace/mode/html')
                require('brace/mode/javascript')    //language
                require('brace/mode/less')
                require('brace/theme/chrome')
                require('brace/snippets/javascript') //snippet
            }
        }
    }
</script>
